import axios from "axios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import actionType from "../actionTypes";
import { toast } from "react-toastify";

export const GetProfile = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/PSCM/profile`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PROFILE,
          payload: response.data,
        });
        if (response.data.msg) {
        } else {
        }
      } else {
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};
export const PostResetQrcode = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/POPM/reset_can`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_REST_QRCODE,
          payload: response.data,
        });
      } else {
      }
    } catch (error) {
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};
export const GetAppmeta = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/PSCM/appmeta`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_APP_META_DATA,
          payload: response.data,
        });
        if (response.data.msg) {
        } else {
        }
      } else {
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};

//Get File From the server
export const getDownloadProfilePic = (filename) => {
  console.log("------- (getFiles) getFiles Action--------");

  console.log(filename);
  const url = process.env.REACT_APP_BASE_URL;
  let fileType;
  if (filename !== "") {
    fileType = filename.split(".")[1];
  } else {
    fileType = "png";
  }
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/PSCM/file/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          //   "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download

          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `file.${fileType}`);
          // document.body.appendChild(link);
          // link.click();
          // console.log("getFilesAction" + link.href);
          dispatch({
            type: actionType.GET_PROFILE_PIC,
            payload: link.href,
          });
          // toast.success("Successfully Downloaded", {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        })
        .catch((error) => {
          console.log("getFiles Action Error : " + error);
        });
    };
  } else {
    //  toast.dark(toastMessage.TOKEN_INVALID, {
    //    position: "bottom-right",
    //    autoClose: 5000,
    //    type: "error",
    //  });
  }
};
export const getDownloadProfileLogo = (filename) => {
  console.log("------- getFiles Action--------");

  console.log(filename);
  const url = process.env.REACT_APP_BASE_URL;
  let fileType;
  if (filename !== "") {
    fileType = filename.split(".")[1];
  } else {
    fileType = "png";
  }
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/PSCM/file/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          //   "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download

          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `file.${fileType}`);
          // document.body.appendChild(link);
          // link.click();
          // console.log("getFilesAction" + link.href);
          dispatch({
            type: actionType.GET_PROFILE_LOGO,
            payload: link.href,
          });
          // toast.success("Successfully Downloaded", {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        })
        .catch((error) => {
          console.log("getFiles Action Error : " + error);
        });
    };
  } else {
    //  toast.dark(toastMessage.TOKEN_INVALID, {
    //    position: "bottom-right",
    //    autoClose: 5000,
    //    type: "error",
    //  });
  }
};

const profileActions = {
  upload_profile_pic: {
    api_name: "/PSCM/file/upload",
    https_method: "POST",
    description: "Upload profile picture",
    action_type: actionType.UPLOAD_PROFILE_PIC,
  },
  // get_profile_pic: {
  //   api_name: "/PSCM/file",
  //   https_method: "GET",
  //   description: "Download profile picture",
  //   action_type: actionType.GET_PROFILE_PIC,
  // },
  get_profile_details: {
    api_name: "/PSCM/profile",
    https_method: "GET",
    description: "Get profile details",
    action_type: actionType.GET_PROFILE,
  },
  update_profile_details: {
    api_name: "/PSCM/users/profile",
    https_method: "PUT",
    description: "Update profile details",
    action_type: actionType.GET_PROFILE,
  },
  get_coutry_regions: {
    api_name: "/PSCM/user/countries",
    https_method: "GET",
    description: "Get countries and details",
    action_type: actionType.GET_COUNTRIES_PHONECODE,
  },
};
export default profileActions;

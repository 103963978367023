import { FormatAlignCenter, FormatAlignLeft } from "@mui/icons-material";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import ReportsWfs from "./ReportsWfs";
import ReportsConsumers from "./ReportsConsumers";
import ReportsLostCan from "./ReportsLostCan";

function ReportsMain() {
  const [alignmentUsers, setAlignmentUsers] = React.useState("wfs");
  console.log("----alignmentUsers-----", alignmentUsers);

  const handleAlignmentUsers = (event, newAlignment) => {
    setAlignmentUsers(newAlignment);
  };
  const capitalizeText = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };
  return (
    <Box>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mr: "15px",
          mt: "10px",
          height: "50px",
        }}
      >
        <ToggleButtonGroup
          value={alignmentUsers}
          exclusive
          onChange={handleAlignmentUsers}
        >
          <ToggleButton
            value="wfs"
            style={{ textTransform: "none", fontWeight: 800, fontSize: "16px" }}
          >
            Filling Station
          </ToggleButton>
          <ToggleButton
            value="consumers"
            style={{ textTransform: "none", fontWeight: 800, fontSize: "16px" }}
          >
            Consumer
          </ToggleButton>
          <ToggleButton
            value="lost"
            style={{ textTransform: "none", fontWeight: 800, fontSize: "16px" }}
          >
            Lost cans
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {alignmentUsers === "wfs" ? (
        <ReportsWfs />
      ) : alignmentUsers === "consumers" ? (
        <ReportsConsumers />
      ) : (
        <ReportsLostCan />
      )}
    </Box>
  );
}

export default ReportsMain;

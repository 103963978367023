import React from "react";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";

function GaugeChart({ value, fillColor, valueMax, displayValue, background }) {
  console.log("---GaugeChart---", value);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const settings = {
    width: isMobile ? 150 : 200,
    height: 200,
    value: value,
  };
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Gauge
        {...settings}
        cornerRadius="50%"
        valueMax={valueMax}
        sx={(theme) => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 30,
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: fillColor,
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: theme.palette.text.disabled,
          },
        })}
      />
      {displayValue !== undefined && (
        <Box
          sx={{
            background: "red",
            height: "100px",
            width: "100px",
            position: "absolute",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...background,
          }}
        >
          <Typography
            sx={{
              fontSize: 30,
            }}
          >
            {displayValue}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default GaugeChart;

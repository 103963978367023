import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { GetGeneratedQR } from "../../Redux/Actions/GenerateQrAction";
import CustomButton from "../../Components/CustomComp/CustomButton";
import QRPdfGenerator from "../../Components/CustomComp/QRPdfGenerator";

function GetQrCodesDialog(props) {
  const generatedCodesDetails = useSelector((state) => state.generateQrData);
  const dispatch = useDispatch();
  const [generatedCodesList, setGeneratedCodesList] = React.useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  React.useEffect(() => {
    dispatch(
      GetGeneratedQR(
        // pageNumber,
        // pageSize,
        // createdAtSort,
        // isQr,
        // qrBarcodeId,
        // orderId
        {
          page_number: pageNumber,
          page_size: pageSize,
          created_at_sort: "DESC",
          is_qr: true,
          product_id:
            props?.singleProductQR?.id !== undefined &&
            props?.singleProductQR?.id !== null &&
            props?.singleProductQR?.id,
        }
      )
    );
  }, [pageNumber, pageSize]);

  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };

  React.useEffect(() => {
    console.log(`generatedCodesDetails`);
    if (generatedCodesDetails.error === false) {
      setGeneratedCodesList(generatedCodesDetails?.generatedCodesList?.data);
    }
  }, [generatedCodesDetails]);
  return (
    <Dialog open={props.openGetQrCodeDialog}>
      <DialogContent>
        {/* <Paper elevation={10} sx={{ borderRadius: "15px", m: "20px 20px" }}> */}
        <CustomTable
          tableHead={[
            "Start Number",
            "End Number",
            "Created At",
            "Product Name",
            // "Facility Name",
            "Download QR",
          ]}
          tableData={generatedCodesList}
          tableDataKey={[
            "start_number",
            // "org_name",5

            "end_number",
            "created_at",
            "name",
            // "org_name",
            {
              component: (props) => {
                try {
                  console.log("---PDF generation started---");

                  const prefix = props.row.start_number.match(/[A-Z]+/)[0]; // Extract prefix (e.g., "BWQ")
                  const startNum = parseInt(
                    props.row.start_number.match(/\d+/)[0],
                    10
                  ); // Extract numeric part
                  const endNum = parseInt(
                    props.row.end_number.match(/\d+/)[0],
                    10
                  ); // Extract numeric part

                  const MAX_QR_LIMIT = 100; // Set a reasonable limit

                  if (startNum > endNum) {
                    console.error(
                      "Start number is greater than end number. Check input."
                    );
                    return <p>Error: Invalid range.</p>;
                  }

                  const totalCodes = endNum - startNum + 1;

                  if (totalCodes > MAX_QR_LIMIT) {
                    console.warn(
                      `Too many QR codes requested (${totalCodes}). Limiting to ${MAX_QR_LIMIT}.`
                    );
                  }

                  const serialNumbers = [];

                  for (
                    let i = startNum;
                    i <= Math.min(endNum, startNum + MAX_QR_LIMIT - 1);
                    i++
                  ) {
                    serialNumbers.push(
                      `https://paasstage.bookwater.com/qr-scan?qr_code=${prefix}00${String(
                        i
                      )}`
                    );
                  }

                  console.log("---Generated QR URLs---", serialNumbers);

                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <QRPdfGenerator
                        input={serialNumbers}
                        label="Download"
                        width={40}
                      />
                    </Box>
                  );
                } catch (error) {
                  console.error("Error generating QR codes:", error);
                  return <p>Error generating QR codes.</p>;
                }
              },
            },

            // {
            //   rowClickEnable: true,
            //   component: (props) => {
            //     console.log("propss", props);
            //     return moment(props?.row?.created_at).format("DD-MM-YYYY");
            //   },
            // },
            // {
            //   type: "button",
            //   enableKey: "is_facility",
            //   component: (props) => {
            //     console.log("props", props);
            //     return !props.disabled ? (
            //       <CustomButton
            //         btnName="ADD ORGANIZATION"
            //         size="small"
            //         endIcon={<CorporateFareRoundedIcon />}
            //         style={{ borderRadius: "0px" }}
            //         handleClick={() => {
            //           console.log(
            //             "ADD ORGANIZATION",
            //             props.row,
            //             props.row?.facility_id,
            //             props.row?.org_name
            //           );

            //           setFacilityId(props.row?.id);
            //           setFacilityName(props.row?.org_name);
            //           setAddOrganizationDialog(true);
            //           setIsAddFacility(false);
            //         }}
            //         disabled={props.disabled}
            //       />
            //     ) : (
            //       "Organization"
            //     );
            //   },
            // },
            // {
            //   type: "button",
            //   enableKey: "is_facility",
            //   component: (props) => {
            //     return !props.disabled ? (
            //       <CustomButton
            //         btnName="ADD FACILITY INCHARGE"
            //         size="small"
            //         endIcon={<CorporateFareRoundedIcon />}
            //         style={{ borderRadius: "0px" }}
            //         handleClick={() => {
            //           // console.log("ADD FACILITY INCHARGE", index, row);
            //           setAddFacilityInchargeDialog(true);
            //           setFacility(props.row);
            //           setIsAddFacility(false);
            //         }}
            //         disabled={props.disabled}
            //       />
            //     ) : (
            //       "Organization"
            //     );
            //   },
            // },
          ]}
          // tableRowClickFunction={facilityRowClick}
          // filter={true}
          filterSelectOption={[
            // { value: "start_number", label: "start_number" },
            // { value: "end_number", label: "end_number" },
            {
              type: "select",
              value: "is_qr",
              label: "QR/Barcode",
              input: [
                { value: true, menuItem: "QR" },
                { value: false, menuItem: "Barcode" },
              ],
            },
            {
              type: "select",
              value: "series_type",
              label: "Series Type",
              input: [
                { value: true, menuItem: "Generated" },
                { value: false, menuItem: "Regenerated" },
              ],
            },
          ]}
          // handleFilterClose={handleQRFilterClose}
          // sort={true}
          sortSelectOption={[{ value: "created_at_sort", label: "Created at" }]}
          // handleSortClose={handleQRSortClose}
          addButton={false}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          // handleOnClickAddButton={handleAddFacilityButton}
        />
        {/* </Paper> */}
      </DialogContent>
      <DialogActions>
        <CustomButton
          label="close"
          color="error"
          handleClick={handleCloseDialog}
        />
      </DialogActions>
    </Dialog>
  );
}

export default GetQrCodesDialog;

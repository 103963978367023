import actionTypes from "../actionTypes";

export const reportsApi = {
  getConsumerStock: {
    api_name: "/PSCM/consumer_stock",
    https_method: "GET",
    action_type: actionTypes.GET_CONSUMER_STOCK,
    description: "",
  },
  getAllConsumertotalStock: {
    api_name: "/PSCM/all_consumer_total_stock_product",
    https_method: "GET",
    action_type: actionTypes.GET_ALL_CONSUMER_TOTAL_STOCK,
    description: "",
  },
  getAllLostWfsConsumertotalStock: {
    api_name: "/PSCM/all_lost_total_stock_product",
    https_method: "GET",
    action_type: actionTypes.GET_ALL_LOST_WFS_CONSUMER_TOTAL_STOCK,
    description: "",
  },
  getLostCan: {
    api_name: "/PSCM/get_lost_can",
    https_method: "GET",
    action_type: actionTypes.UNAUTH_SCAN_BATCH_QR,
    description: "",
  },
};

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@mui/material";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import { useDispatch } from "react-redux";
import {
  CancelCreateBatch,
  GetCreateBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import appStyle from "../../AppMeta/appStyle";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import appMeta from "../../AppMeta/appMeta";
import CustomButton from "../../Components/CustomComp/CustomButton";
import CustomForm from "../../Components/CustomComp/CustomForm";

export function CancelDialogBatch({
  open,
  onClose,
  item,
  setCancelDialogOpen,
}) {
  const dispatch = useDispatch();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [reason, setReason] = React.useState("");
  // React.useEffect(() => {
  //   if (isConfirmed) {
  //     const reqObj = {
  //       batch_id: item.batch_id,
  //       reason: reason?.Reason,
  //     };

  //     console.log("ReqObj");
  //     console.log(reqObj);
  //     dispatch(CancelCreateBatch(reqObj));
  //     setTimeout(() => {
  //       dispatch(GetCreateBatch());
  //     }, 1000);
  //     setOpenConfirmationDialog(false);
  //     setIsConfirmed(false);
  //     setCancelDialogOpen(false);
  //   }
  // }, [isConfirmed]);

  const handleAddIotDevice = (data) => {
    console.log("-----data------", data);
    setReason(data);
    setOpenConfirmationDialog(true);
  };

  const handleCancle = async () => {
    // setIsConfirmed(true);

    const reqObj = {
      batch_id: item.batch_id,
      reason: reason?.Reason,
    };

    console.log("ReqObj");
    console.log(reqObj);
    let response = await dispatch(CancelCreateBatch(reqObj));
    setOpenConfirmationDialog(false);
    setIsConfirmed(false);
    if (response.status === 200) {
      setCancelDialogOpen(false);
      dispatch(
        GetCreateBatch({ sort_created_at: "DESC", is_batch_completed: false })
      );
    }
    // setTimeout(() => {
    //   dispatch(
    //     GetCreateBatch({ sort_created_at: "DESC", is_batch_completed: false })
    //   );
    // }, 1000);
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
  };
  const handleCloseConformationDialog = () => {
    setCancelDialogOpen(false);
  };
  return (
    <Box>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle
          id="form-dialog-title"
          textAlign={"center"}
          fontWeight={800}
        >
          Cancel Order
        </DialogTitle>
        <DialogContent>
          <CustomForm
            handleSubmit={(formData) => handleAddIotDevice(formData)}
            handleClose={handleCloseCancelDialog}
            data={[
              {
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Enter Reason For Cancel",
                    defaultValue: "",
                    name: "Reason",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                ],
              },
            ]}
          />
        </DialogContent>
        {/* <CustomInputTextField
            placeholder="Reason"
            value={reason}
            type={appMeta.inputFieldTypes.INPUT}
            inputFieldTypes={appMeta.textFieldTypes.TEXT}
            isRequired={true}
            setValue={setReason}
          />
         
       
        <DialogActions>
        <CustomButton
        handleClick={handleClick}
        variant="contained"
        startIcon={startIcon}
        label={""}
        // sx={appStyle.general.button.blueButton}
      />
          <Button
            color="primary"
            onClick={() => setOpenConfirmationDialog(true)}
            // sx={appStyle.general.button.redButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onClose}
            sx={appStyle.general.button.redButton}
          >
            Close
          </Button> */}
        {/* </DialogActions> */}
      </Dialog>
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          open={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          dialogContent={"Are you sure that you want to cancel?"}
          setIsConfirmed={setIsConfirmed}
          onConfirm={handleCancle}
          onClose={handleCloseConformationDialog}
        />
      )}
    </Box>
  );
}

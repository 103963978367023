import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import HomeDataCard from "../Home/HomeDataCard";
import {
  faBottleWater,
  faRecycle,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";

import HomeTwoLayerCards from "../Home/HomeTwoLayerCards";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import appMeta from "../../AppMeta/appMeta";
import CustomDatePicker from "../Home/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { GetCBatchDetailsDashboard } from "../../Redux/Actions/adddCreateBatchAction";
import { useTheme } from "@emotion/react";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import SelectWfs from "../AccessManagement/AccessConfiguration/SelectWfs";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
function BatchDashboard() {
  const theme = useTheme();
  const batchDetails = useSelector((state) => state.batchData);
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  console.log("-------batchDetails--------", batchDetails);
  const [selectedWfs, setSelectedWfs] = React.useState("");
  const [selectedWfsName, setSelectedWfsName] = React.useState("");
  const [autoScrollActive, setAutoScrollActive] = React.useState(true);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(fromDate);
  const dispatch = useDispatch();

  const [activeProductIndex, setActiveProductIndex] = React.useState(0);
  React.useEffect(() => {
    if (wfsList !== undefined && wfsList !== null) {
      console.log("----wfsList------", wfsList[0]?.wfs_id);
      setSelectedWfs(wfsList[0]?.wfs_id);
      setSelectedWfsName(wfsList[0]?.wfs_name);
    }
  }, [wfsList]);

  React.useEffect(() => {
    let interval;
    if (autoScrollActive) {
      interval = setInterval(() => {
        setActiveProductIndex(
          (prevIndex) =>
            prevIndex <
            batchDetails?.batchDashboardData[0]?.products?.length - 1
              ? prevIndex + 1
              : 0 // Reset to the first product
        );
      }, 4000); // Auto-scroll interval
    }

    return () => clearInterval(interval); // Clean up interval
  }, [autoScrollActive, batchDetails.batchDashboardData]);

  const handleNextProduct = () => {
    if (
      batchDetails?.batchDashboardData &&
      batchDetails?.batchDashboardData?.length > 0
    ) {
      const currentBatch = batchDetails?.batchDashboardData[0]; // Assuming we're viewing the first batch

      console.log(
        "----------",
        currentBatch,
        currentBatch?.products?.length - 1
      );
      if (activeProductIndex < currentBatch?.products?.length - 1) {
        setActiveProductIndex(activeProductIndex + 1);
      }
    }
  };

  const handleBackProduct = () => {
    if (activeProductIndex > 0) {
      setActiveProductIndex(activeProductIndex - 1);
    }
  };

  React.useEffect(() => {
    const formattedFromDate = fromDate?.format("YYYY-MM-DD");
    const formattedToDate = toDate?.format("YYYY-MM-DD");
    dispatch(
      GetCBatchDetailsDashboard({
        wfs_id: selectedWfs,
        from_date: formattedFromDate,
        to_date: formattedToDate,
      })
    );
  }, [selectedWfs, toDate]);

  // const searchAndUpdateWfs = async () => {
  //   await dispatch(
  //     HandleApiActions({
  //       ...wfsApis.get_wfs_list,
  //       params: {
  //         call: 1,
  //         ignore_app_loading_spinner: true,
  //       },
  //       show_toast: false,
  //     })
  //   );
  // };

  let getWfsDetails = (_, wfsID, wfsObject) => {
    console.log("---getWfsDetails---", wfsID);
    setSelectedWfs(wfsID);

    // setDeviceId("");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid md={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "flex-end", xs: "center" },
              gap: "10px",
              flexWrap: "wrap",
              m: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                mt: "15px",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {/* <CustomAutoComplete
                field={{
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "role_name",
                  optionKey2: "department",
                  outputKey: "role_name",
                  // searchAndUpdateAPI: (searchText) => {
                  //   setFacilitySearch(searchText);
                  // },
                  // input: rolesList,
                  name: "role_1",
                  label: "Enter Facility",
                }}
                // handleInputChange={((name, selectedOption), {})}
              /> */}
              {/* <CustomAutoComplete
                field={{
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "role_name",
                  optionKey2: "department",
                  outputKey: "role_name",
                  // searchAndUpdateAPI: (searchText) => {
                  //   setWfsSearch(searchText);
                  // },
                  // input: rolesList,
                  name: "role_1",
                  label: "Enter Wfs",
                }}
                // handleInputChange={((name, selectedOption), {})}
              /> */}
              <SelectWfs
                // profileRole={profileRole}
                // optionKey={selectedWfsName}
                defaultWfs={selectedWfsName}
                firstValueDefault={true}
                variant="outlined"
                getWfsDetails={getWfsDetails}
              />
            </Box>

            <CustomDatePicker
              handleChange={(date) => {
                console.log("---date---", date);
              }}
              setToDate={setToDate}
              toDate={toDate}
              fromDate={fromDate}
              setFromDate={setFromDate}
            />
          </Box>{" "}
        </Grid>
        <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
          {/* Iterate through batches and show their products */}
          {batchDetails.batchDashboardData &&
          batchDetails.batchDashboardData.length > 0 ? (
            batchDetails.batchDashboardData.map((data, index) => (
              <Grid
                key={index}
                md={12}
                xs={12}
                component={Paper}
                elevation={10}
                sx={{
                  bgcolor: theme.palette.mode === "dark" ? "#1f2940" : "white", // Modify based on theme or use theme.palette.mode
                  m: "10px",
                  borderRadius: "20px",
                  position: "relative", // Position for the arrows
                }}
              >
                {/* Filling Station Name */}
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: "18px", m: "20px", fontWeight: 800 }}
                  >
                    Filling Station Name:
                  </Typography>
                  <Typography sx={{ fontSize: "18px", m: "20px" }}>
                    {data.wfs_name}
                  </Typography>
                </Box>

                {/* Swipeable view of products */}
                <Box
                  sx={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "1rem",
                    m: { md: "10px", xs: "40px" },
                  }}
                  onMouseEnter={() => setAutoScrollActive(false)} // Pause on hover
                  onMouseLeave={() => setAutoScrollActive(true)} // Resume on leave
                >
                  {data.products && data.products.length > 0 ? (
                    <>
                      <SwipeableViews
                        index={activeProductIndex}
                        onChangeIndex={setActiveProductIndex}
                        enableMouseEvents
                      >
                        {data.products.map((product, productIndex) => (
                          <Box
                            key={productIndex}
                            sx={{
                              display: "flex",
                              gap: "1rem",
                              flexWrap: "wrap",
                              ml: { md: "130px", xs: "0px" },
                              justifyContent: "flex-start",
                              mb: "20px",
                            }}
                          >
                            <HomeDataCard
                              bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                              icon={faBottleWater}
                              // value={product.filled_containers}
                              // subTitle="Filled"
                              title={product.product_name}
                              iconSize="40px"
                              titleSize="25px"
                            />
                            <HomeDataCard
                              bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                              // icon={faBottleWater}
                              value={product.filled_containers}
                              subTitle="Filled"
                              valueSize="40px"
                              subtitleSize="25px"
                              // title={product.product_name}
                            />
                            <HomeTwoLayerCards
                              value={product.containers_to_be_filled}
                              // title={product.product_name}
                              subTitle="Need to fill"
                              valueSize="40px"
                              subtitleSize="22px"
                              icon={faStopwatch}
                            />
                          </Box>
                        ))}
                      </SwipeableViews>

                      {/* Navigation Arrows for Products */}
                      <Box
                        sx={{
                          position: "absolute",
                          top: "60%",
                          left: { md: "5%", xs: "0px" },
                          right: { md: "20%", xs: "0px" },
                          display: "flex",
                          justifyContent: "space-between",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <IconButton
                          onClick={handleBackProduct}
                          sx={{
                            position: "relative",
                            bgcolor: "#6f99e8",
                          }}
                          disabled={activeProductIndex === 0}
                        >
                          <ChevronLeft />
                        </IconButton>
                        <IconButton
                          onClick={handleNextProduct}
                          sx={{
                            position: "relative",
                            bgcolor: "#6f99e8",
                          }}
                          disabled={
                            activeProductIndex === data.products.length - 1
                          }
                        >
                          <ChevronRight />
                        </IconButton>
                      </Box>
                    </>
                  ) : (
                    <Typography>No products found</Typography>
                  )}
                </Box>
              </Grid>
            ))
          ) : (
            <Box>
              <Typography>No Data found</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
}

export default BatchDashboard;

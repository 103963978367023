import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { reportsApi } from "../../Redux/Reducers/reportsApi";
import appMeta from "../../AppMeta/appMeta";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import HomeDataCard from "../Home/HomeDataCard";
import { faBottleWater, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";
function ReportsLostCan() {
  ///Hooks
  let dispatch = useDispatch();
  const theme = useTheme();
  ///state
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [lostCanList, setLostCanList] = React.useState([]);
  const [lostCanListDropdown, setLostCanListDropdown] = React.useState([]);
  const [lostBeforeDays, setLostBeforeDays] = React.useState(0);
  const [userSearch, setuserSearch] = React.useState("");
  const [activeConsumerIndex, setActiveConsumerIndex] = React.useState(0);
  const [allLostWFSTotalCan, setAllLostWFSTotalCan] = React.useState([]);
  const [allLostConsumerTotalCan, setAllLostConsumerTotalCan] = React.useState(
    []
  );
  const [activeFillingStationIndex, setActiveFillingStationIndex] =
    React.useState(0);
  //function
  let getLostCanStock = async (props) => {
    props = { ...props, lost_before_days: lostBeforeDays };
    let response = await dispatch(
      HandleApiActions({
        ...reportsApi.getLostCan,
        params: props,
        show_toast: false,
      })
    );
    console.log("---getConsumerStock---", response);
    if (response.status === 200) {
      setLostCanList(response.data);
      // setLostCanListDropdown(response.data);
    }
  };

  const getAllLostcanWfsConsumers = async (props) => {
    props = { ...props, lost_before_days: lostBeforeDays };
    let response = await dispatch(
      HandleApiActions({
        ...reportsApi.getAllLostWfsConsumertotalStock,
        params: props,
        show_toast: false,
      })
    );
    console.log("---getLostefsConsumerStock---", response);
    if (response.status === 200) {
      setAllLostConsumerTotalCan(response.data?.consumer_stocks);
      setAllLostWFSTotalCan(response.data?.wfs_stock);
      // setLostCanListDropdown(response.data);
    }
  };
  const getLostCanListDropdown = async (props) => {
    props = { ...props, lost_before_days: lostBeforeDays };
    let response = await dispatch(
      HandleApiActions({
        ...reportsApi.getLostCan,
        params: props,
        show_toast: false,
      })
    );
    console.log("---getConsumerStock---", response);
    if (response.status === 200) {
      setLostCanListDropdown(response.data);
    }
  };

  let handleLostCanFilter = ({ value, searchText }) => {
    getLostCanStock({ [value]: searchText });
  };

  const handleChangeUser = (obj) => {
    console.log("----handleChangeUser----", obj);
    getLostCanStock({
      name: obj?.name !== undefined && obj?.name !== null && obj?.name,
    });
  };

  useEffect(() => {
    getLostCanStock();
    getLostCanListDropdown();
    getAllLostcanWfsConsumers();
  }, []);

  const products = [
    {
      containers_to_be_filled: 1,
      filled_containers: 30,
      product_name: "20 Liter",
    },
    {
      containers_to_be_filled: 1,
      filled_containers: 20,
      product_name: "25 Liter",
    },
  ];
  const productss = [
    {
      containers_to_be_filled: 1,
      filled_containers: 23,
      product_name: "20 Liter",
    },
    {
      containers_to_be_filled: 1,
      filled_containers: 23,
      product_name: "25 Liter",
    },
  ];

  const handleBackConsumer = () => {
    if (activeConsumerIndex > 0) {
      setActiveConsumerIndex(activeConsumerIndex - 1);
    }
  };

  const handleNextConsumer = () => {
    if (activeConsumerIndex < allLostConsumerTotalCan?.length - 1) {
      setActiveConsumerIndex(activeConsumerIndex + 1);
    }
  };

  const handleBackFillingStation = () => {
    if (activeFillingStationIndex > 0) {
      setActiveFillingStationIndex(activeFillingStationIndex - 1);
    }
  };

  const handleNextFillingStation = () => {
    if (activeFillingStationIndex < allLostWFSTotalCan.length - 1) {
      setActiveFillingStationIndex(activeFillingStationIndex + 1);
    }
  };
  return (
    <Box>
      <Paper
        sx={{
          padding: "10px",
          m: "10px",
          bgcolor: theme.palette.mode === "dark" ? "#333333" : "white",
          borderRadius: "20px",
        }}
        elevation={18}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { md: "flex-start", xs: "flex-end" },
            m: "0px 0px 20px 20px",
          }}
        >
          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "name",
              // optionKey2: "department",
              outputKey: "name",
              searchAndUpdateAPI: (searchText) => {
                setuserSearch(searchText);
              },
              input: lostCanListDropdown,
              name: "name",
              label: "Search Name",
            }}
            handleInputChange={(name, selectedOption, obj) => {
              handleChangeUser(obj);
            }}
          />
        </Box>

        {/* Consumers Section */}
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "1rem",
            m: { md: "10px", xs: "40px" },
            padding: "20px 10px 0px 10px",
            borderRadius: "20px",
            bgcolor: theme.palette.mode === "dark" ? "#3c4559" : "white",
            position: "relative", // Added to position arrows correctly
          }}
          component={Paper}
          elevation={18}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
              User Type :{" "}
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
              Consumers{" "}
            </Typography>
          </Box>
          {allLostConsumerTotalCan && allLostConsumerTotalCan.length > 0 ? (
            <>
              <SwipeableViews
                index={activeConsumerIndex}
                onChangeIndex={setActiveConsumerIndex}
                enableMouseEvents
              >
                {allLostConsumerTotalCan.map((product, productIndex) => (
                  <Box
                    key={productIndex}
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      ml: { md: "110px", xs: "0px" },
                      justifyContent: "flex-start",
                      mb: "20px",
                    }}
                  >
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      icon={faBottleWater}
                      title={product.capacity + "-" + product.material}
                      iconSize="40px"
                      titleSize="25px"
                    />
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      value={product.count}
                      subTitle="Quantity"
                      valueSize="40px"
                      subtitleSize="25px"
                      title={"Total"}
                    />
                  </Box>
                ))}
              </SwipeableViews>

              {/* Navigation Arrows for Consumers */}
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "4%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={handleBackConsumer}
                  sx={{ bgcolor: "#6f99e8" }}
                  disabled={activeConsumerIndex === 0}
                >
                  <ChevronLeft />
                </IconButton>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "45%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={handleNextConsumer}
                  sx={{ bgcolor: "#6f99e8" }}
                  disabled={
                    activeConsumerIndex === allLostConsumerTotalCan.length - 1
                  }
                >
                  <ChevronRight />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  ml: "10px",
                  mt: { md: "0px", xs: "20px" },
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ color: "red" }}>* Note </Typography>
                <Typography>
                  : Total Product Quantity that have been with All consumers for
                  more than 40 days.{" "}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography>No products found</Typography>
          )}
        </Box>

        {/* Filling Station Section */}
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "1rem",
            m: { md: "10px", xs: "40px" },
            padding: "20px 10px 0px 10px",
            borderRadius: "20px",
            bgcolor: theme.palette.mode === "dark" ? "#3c4559" : "white",
            position: "relative", // Added to position arrows correctly
          }}
          component={Paper}
          elevation={18}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
              User Type :{" "}
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
              Filling station{" "}
            </Typography>
          </Box>
          {allLostWFSTotalCan && allLostWFSTotalCan.length > 0 ? (
            <>
              <SwipeableViews
                index={activeFillingStationIndex}
                onChangeIndex={setActiveFillingStationIndex}
                enableMouseEvents
              >
                {allLostWFSTotalCan.map((product, productIndex) => (
                  <Box
                    key={productIndex}
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      ml: { md: "110px", xs: "0px" },
                      justifyContent: "flex-start",
                      mb: "20px",
                    }}
                  >
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      icon={faBottleWater}
                      title={product.capacity + "-" + product.material}
                      iconSize="40px"
                      titleSize="25px"
                    />
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      value={product.count}
                      subTitle="Quantity"
                      valueSize="40px"
                      subtitleSize="25px"
                      title={"Total"}
                    />
                  </Box>
                ))}
              </SwipeableViews>

              {/* Navigation Arrows for Filling Station */}
              <Box
                sx={{
                  position: "absolute",
                  top: { md: "50%", xs: "50%" },
                  left: { md: "4%", xs: "10px" },
                  right: { md: "37%", xs: "20px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={handleBackFillingStation}
                  sx={{ bgcolor: "#6f99e8" }}
                  disabled={activeFillingStationIndex === 0}
                >
                  <ChevronLeft />
                </IconButton>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "45%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={handleNextFillingStation}
                  sx={{ bgcolor: "#6f99e8" }}
                  disabled={
                    activeFillingStationIndex === allLostWFSTotalCan.length - 1
                  }
                >
                  <ChevronRight />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  ml: "10px",
                  mt: { md: "0px", xs: "20px" },
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ color: "red" }}>* Note </Typography>
                <Typography>
                  : Total Product Quantity that have been with All Filling
                  Station for more than 40 days.{" "}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography>No products found</Typography>
          )}
        </Box>

        <Paper
          component={Paper}
          elevation={18}
          sx={{
            borderRadius: "20px",
            bgcolor: theme.palette.mode === "dark" ? "#3c4559" : "white",
            padding: "10px 0px 10px 0px",
            m: "10px",
          }}
        >
          <Typography sx={{ textAlign: "center" }}>
            List of Consumers & Filling Stations
          </Typography>
          <CustomTable
            tableHead={["Name", "Phone", "User Type", "Products", "Quantity"]}
            tableData={lostCanList}
            tableDataKey={[
              "name",
              "phone",
              {
                component: ({ row }) => {
                  console.log("---getLostCanStock---", row);
                  return row.user_type === 100 ? "WFS" : "Consumer";
                },
              },
              // {
              //   component: ({ row }) => {
              //     console.log("---collapseTableDataKey---", row.product_details);
              //     return (
              //       <Table>
              //         <TableHead>
              //           <TableRow>
              //             <TableCell sx={{ fontWeight: 700 }}>Capacity</TableCell>
              //             <TableCell sx={{ fontWeight: 700 }}>Material</TableCell>
              //             <TableCell sx={{ fontWeight: 700 }}>Count</TableCell>
              //           </TableRow>
              //         </TableHead>
              //         <TableBody>
              //           {row.product_details.map((data) => {
              //             return (
              //               <TableRow>
              //                 <TableCell>{data.capacity}</TableCell>
              //                 <TableCell>{data.material}</TableCell>
              //                 <TableCell>{data.count}</TableCell>
              //               </TableRow>
              //             );
              //           })}
              //         </TableBody>
              //       </Table>
              //     );
              //   },
              // },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.product_details.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.capacity}-{data.material}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.product_details.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.count}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
            ]}
            isCollapsable={false}
            collapseTableHead={["Products"]}
            collapseTableDataKey={[
              {
                component: ({ row }) => {
                  console.log(
                    "---collapseTableDataKey---",
                    row.product_details
                  );
                  return (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 700 }}>
                            Capacity
                          </TableCell>
                          <TableCell sx={{ fontWeight: 700 }}>
                            Material
                          </TableCell>
                          <TableCell sx={{ fontWeight: 700 }}>Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.product_details.map((data) => {
                          return (
                            <TableRow>
                              <TableCell>{data.capacity}</TableCell>
                              <TableCell>{data.material}</TableCell>
                              <TableCell>{data.count}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
            ]}
            filter={false}
            filterSelectOption={[
              { value: "name", label: "Name" },
              { value: "phone", label: "Phone" },
              {
                type: "select",
                value: "is_consumer",
                label: "User",
                input: [
                  { value: true, menuItem: "Consumer" },
                  { value: false, menuItem: "WFS" },
                ],
              },
            ]}
            handleFilterClose={handleLostCanFilter}
            sortSelectOption={[{ value: "created_at", label: "Created at" }]}
            pagination={true}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
          />
          <Box
            sx={{
              display: "flex",
              ml: "20px",
              mt: { md: "-38px", xs: "20px" },
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ color: "red" }}>* Note </Typography>
            <Typography>
              : Cans that have been with consumers for more than 40 days.{" "}
            </Typography>
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
}

export default ReportsLostCan;

import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

function LineChartForIot({ inputs = [], xAxisLabel = [] }) {
  console.log("---input---", inputs, xAxisLabel);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const xLabels = xAxisLabel;

  return (
    <LineChart
      width={isMobile ? 200 : 400}
      height={300}
      series={inputs.map((input) => ({
        data: input.data,
        label: input.label,
      }))}
      xAxis={[{ scaleType: "point", data: xLabels }]}
    />
  );
}

export default LineChartForIot;

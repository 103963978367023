import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { reportsApi } from "../../Redux/Reducers/reportsApi";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import appMeta from "../../AppMeta/appMeta";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import HomeDataCard from "../Home/HomeDataCard";
import { faBottleWater, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";
function ReportsConsumers() {
  //selectors
  let dispatch = useDispatch();
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [consumerStockList, setConsumerStockList] = React.useState([]);
  const [formSelectedObject, setFormSelectedObject] = React.useState({});
  const [consumerSearch, setConsumerSearch] = React.useState("");
  const [activeProductIndex, setActiveProductIndex] = React.useState(0);
  const [autoScrollActive, setAutoScrollActive] = React.useState(true);
  const [allConsumerTotalStock, setAllConsumerTotalStock] = React.useState([]);
  console.log("-------consumerSearch------", consumerSearch);
  // functions
  let getConsumerStock = async (props) => {
    let response = await dispatch(
      HandleApiActions({
        ...reportsApi.getConsumerStock,
        params: props,
        show_toast: false,
      })
    );
    console.log("---getConsumerStock---", response);
    if (response.status === 200) {
      setConsumerStockList(response.data);
    }
  };

  const getAllConsumertotalStock = async (props) => {
    let response = await dispatch(
      HandleApiActions({
        ...reportsApi.getAllConsumertotalStock,
        params: props,
        show_toast: false,
      })
    );
    console.log("---getAllConsumerStock---", response);
    if (response.status === 200) {
      setAllConsumerTotalStock(response.data);
    }
  };

  let handleConsumerStockFilter = ({ value, searchText }) => {
    console.log("---handleConsumerStockFilter---", value, searchText);
    getConsumerStock({ [value]: searchText });
  };
  const handleChangeConsumer = (obj) => {
    console.log("----handleChangeConsumer----", obj);
    getConsumerStock({
      consumer_name:
        obj?.consumer_name !== undefined &&
        obj?.consumer_name !== null &&
        obj?.consumer_name,
    });
  };
  useEffect(() => {
    getConsumerStock();
    getAllConsumertotalStock();
  }, []);
  const products = [
    {
      containers_to_be_filled: 1,
      filled_containers: 23,
      product_name: "20 Liter",
    },
    {
      containers_to_be_filled: 1,
      filled_containers: 23,
      product_name: "25 Liter",
    },
  ];
  // Auto scroll functionality
  useEffect(() => {
    let interval;
    if (autoScrollActive) {
      interval = setInterval(() => {
        if (activeProductIndex < allConsumerTotalStock.length - 1) {
          setActiveProductIndex((prevIndex) => prevIndex + 1);
        } else {
          setActiveProductIndex(0); // Reset to the first product when it reaches the end
        }
      }, 4000); // 5 seconds interval for auto-scroll
    }

    return () => clearInterval(interval); // Clean up interval when component unmounts
  }, [activeProductIndex, allConsumerTotalStock.length, autoScrollActive]);

  const handleNextProduct = () => {
    if (allConsumerTotalStock && allConsumerTotalStock.length > 0) {
      console.log("--products---", allConsumerTotalStock);
      const currentBatch = allConsumerTotalStock[0]; // Assuming we're viewing the first batch
      console.log("----currentBatch----", currentBatch - 1, activeProductIndex);
      // if (activeProductIndex < currentBatch.length - 1) {
      setActiveProductIndex(activeProductIndex + 1);
      // }
    }
  };

  const handleBackProduct = () => {
    if (activeProductIndex > 0) {
      setActiveProductIndex(activeProductIndex - 1);
    }
  };

  return (
    <Box>
      <Paper
        sx={{
          padding: "10px",
          m: "10px",
          bgcolor: theme.palette.mode === "dark" ? "#333333" : "white",
          borderRadius: "20px",
        }}
        elevation={18}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { md: "flex-start", xs: "flex-end" },
            m: "0px 0px 20px 20px",
          }}
        >
          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "consumer_name",
              // optionKey2: "department",
              outputKey: "consumer_name",
              searchAndUpdateAPI: (searchText) => {
                setConsumerSearch(searchText);
              },
              input: consumerStockList,
              name: "consumer_name",
              label: "Search Consumer Name",
            }}
            handleInputChange={(name, selectedOption, obj) => {
              handleChangeConsumer(obj);
            }}
          />
        </Box>

        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "1rem",
            m: { md: "10px", xs: "20px" },
            padding: "20px 10px 0px 10px",
            borderRadius: "20px",
            bgcolor: theme.palette.mode === "dark" ? "#3c4559" : "white",
            position: "relative",
          }}
          component={Paper}
          elevation={18}
          onMouseEnter={() => setAutoScrollActive(false)} // Pause on hover
          onMouseLeave={() => setAutoScrollActive(true)} // Resume on leave
        >
          {allConsumerTotalStock !== undefined &&
          allConsumerTotalStock !== null &&
          allConsumerTotalStock.length > 0 ? (
            <>
              <SwipeableViews
                index={activeProductIndex}
                onChangeIndex={setActiveProductIndex}
                enableMouseEvents
              >
                {allConsumerTotalStock.map((product, productIndex) => (
                  <Box
                    key={productIndex}
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      ml: { md: "110px", xs: "25px" },
                      mr: { md: "110px", xs: "25px" },
                      justifyContent: "flex-start",
                      mb: "20px",
                    }}
                  >
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      icon={faBottleWater}
                      title={
                        product.container_capacity +
                        "-" +
                        product.container_material
                      }
                      iconSize="40px"
                      titleSize="25px"
                    />
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      value={product.total_filled_containers}
                      subTitle="Quantity"
                      valueSize="40px"
                      subtitleSize="25px"
                      title={"Total"}
                    />
                  </Box>
                ))}
              </SwipeableViews>

              {/* Navigation Arrows for Products */}
              <Box
                sx={{
                  position: "absolute",
                  top: { md: "50%", xs: "50%" },
                  left: { md: "5%", xs: "4px" },
                  right: { md: "45%", xs: "4px" },
                  display: "flex",
                  justifyContent: "space-between",
                  transform: "translateY(-50%)",
                }}
              >
                <IconButton
                  onClick={handleBackProduct}
                  sx={{
                    position: "relative",
                    bgcolor: "#6f99e8",
                  }}
                  disabled={activeProductIndex === 0}
                >
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  onClick={handleNextProduct}
                  sx={{
                    position: "relative",
                    bgcolor: "#6f99e8",
                  }}
                  disabled={
                    activeProductIndex === allConsumerTotalStock.length - 1
                  }
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            </>
          ) : (
            <Typography>No products found</Typography>
          )}
          <Box
            sx={{
              display: "flex",
              ml: "10px",
              mt: { md: "0px", xs: "20px" },
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ color: "red" }}>* Note </Typography>
            <Typography>: All consumers total products quantity . </Typography>
          </Box>
        </Box>
        <Paper
          component={Paper}
          elevation={18}
          sx={{
            borderRadius: "20px",
            bgcolor: theme.palette.mode === "dark" ? "#3c4559" : "white",
            padding: "10px 0px 10px 0px",
            m: "10px",
          }}
        >
          <Typography sx={{ textAlign: "center" }}>
            List of consumers & stocks
          </Typography>
          <CustomTable
            tableHead={[
              "Consumer Name",
              "Phone",
              "Email",
              "Products",
              "Quantity",
            ]}
            tableData={consumerStockList}
            tableDataKey={[
              "consumer_name",
              "phone",
              "email",
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.container_capacity}-
                                {data.container_material}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.filled_containers}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
            ]}
            isCollapsable={false}
            collapseTableHead={["Stock"]}
            collapseTableDataKey={[
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);
                  return (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Capacity</TableCell>
                          <TableCell>Material</TableCell>
                          <TableCell>Filled</TableCell>
                          <TableCell>Empty</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell>{data.container_capacity}</TableCell>
                              <TableCell>{data.container_material}</TableCell>
                              <TableCell>{data.filled_containers}</TableCell>
                              <TableCell>{data.empty_containers}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
            ]}
            filter={false}
            // defaultView="card"
            filterSelectOption={[
              { value: "consumer_name", label: "Name" },
              { value: "phone", label: "Phone" },
            ]}
            handleFilterClose={handleConsumerStockFilter}
            // sort={true}
            sortSelectOption={[{ value: "created_at", label: "Created at" }]}
            // handleSortClose={handleBatchDetailSort}
            // addButton={true}
            // handleOnClickAddButton={() => {
            //   setDrawOpenProp(true);
            // }}
            // tableRowClickFunction={(index, row) => {
            //   handleEditClick(0, row);
            // }}
            pagination={false}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
          />
        </Paper>
      </Paper>
    </Box>
  );
}

export default ReportsConsumers;

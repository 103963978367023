import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import actionTypes from "../actionTypes";

export const GetWfsReports = (reqObj) => {
  console.log("---Recycle order---", reqObj);
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/PSCM/wfs_stock`, { params: reqObj });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionTypes.GET_WFS_REPORTS,
          payload: response?.data,
        });
        if (response.data.msg) {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
        return response;
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
export const GetAllTotalWfsQuantity = (reqObj) => {
  console.log("---Recycle order---", reqObj);
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/PSCM/all_wfs_total_stock_product`, {
        params: reqObj,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionTypes.GET_ALL_TOTAL_WFS_REPORTS,
          payload: response?.data,
        });
        if (response.data.msg) {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
        return response;
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

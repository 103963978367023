import { Box } from "@mui/material";
import React from "react";
import SKUProducts from "../StockKeepingUnit/SKUProducts";
import { useDispatch, useSelector } from "react-redux";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";

function ProductsDemo() {
  const productDetails = useSelector((state) => state.productData);
  const dispatch = useDispatch();
  const [allProducts, setAllProducts] = React.useState([]);
  const [drawOpenProp, setDrawOpenProp] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [view, setView] = React.useState("table");
  React.useEffect(() => {
    dispatch(GetFinalProductSKU());
  }, []);
  React.useEffect(() => {
    console.log("Product details");
    console.log(productDetails);
    if (productDetails.error === false) {
      setAllProducts(productDetails?.products?.data);
    }
  }, [productDetails]);
  return (
    <Box>
      {" "}
      <SKUProducts
        allProducts={allProducts}
        setDrawOpenProp={setDrawOpenProp}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        view={view}
        setView={setView}
      />
    </Box>
  );
}

export default ProductsDemo;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";

function IotDataAlretDialog(props) {
  const handleClose = () => {
    props.handleClose();
  };
  return (
    <Dialog open={props.openIotDataAlertDialog} sx={{ borderRadius: "20px" }}>
      <DialogTitle sx={{ fontSize: "16px" }}>Alert</DialogTitle>
      <DialogContent>
        <DialogContentText color="error" fontSize="20px">
          TDS Reached More than 300
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton handleClick={handleClose} color="error" label="Close" />
      </DialogActions>
    </Dialog>
  );
}

export default IotDataAlretDialog;

import {
  Box,
  CircularProgress,
  Paper,
  styled,
  Typography,
  useMediaQuery,
  circularProgressClasses,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LineChart from "./LineChart";
// import BarChartsMUI from "./BarChartsMUI";
import HomeDataCard from "./HomeDataCard";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBottleWater } from "@fortawesome/free-solid-svg-icons";
import GaugeChart from "./GaugeChart";
import SegmentedProgressBar from "./SegmentedProgressBar";
import HomeOrderCards from "./HomeTwoLayerCards";
import CustomDatePicker from "./CustomDatePicker";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import appMeta from "../../AppMeta/appMeta";
import { useDispatch, useSelector } from "react-redux";
import SelectWfs from "../AccessManagement/AccessConfiguration/SelectWfs";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import CustomButton from "../../Components/CustomComp/CustomButton";
import moment from "moment/moment";
import { useTheme } from "@emotion/react";
import { tokens } from "../../AppMeta/theme";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import IotDataAlretDialog from "./IotDataAlretDialog";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import {
  CreateIotJob,
  FetchDeviceStatus,
  FetchIotJobs,
} from "../../Redux/Actions/iotDeviceAction";
import CustomTable from "../../Components/CustomComp/CustomTable";
import useAxios from "../../Hooks/useAxios";
import useWebSocket from "../../Hooks/useWebSocket";
import RangeCharts from "./RangeCharts";

// const WebSocketURL = "ws://localhost:6010/ws";

function IOTGraph() {
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const WfsData = useSelector((state) => state.batchData);
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  const {
    jobs = [],
    error,
    msg,
  } = useSelector((state) => {
    console.log("Redux State:", state.iotDeviceData);
    return state.iotDeviceData || {};
  });
  React.useEffect(() => {
    console.log("Jobs:", jobs);
    console.log("Error:", error);
    console.log("Message:", msg);
  }, [jobs, error, msg]);

  // console.log("---IOTGraph---", wfsList[0].wfs_id);
  const dispatch = useDispatch();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  //state
  const [openIotDataAlertDialog, setOpenIotDataAlertDialog] =
    React.useState(false);
  const [searchLine, setSearchLine] = useState("");
  const [lineIds, setLineIds] = useState([]);
  const [iotReading, setIotReading] = useState([]);
  const [currentTemp, setCurrentTemp] = useState(0);
  // const [iotReadingUpdatedOn, setIotReadingUpdatedOn] = useState("");

  const [currentTDS, setCurrentTDS] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  console.log("---isLoading----", isLoading);
  const [defaultWfsName, setDefaultWfsname] = useState("");

  //variables
  let maxTemp = 100;
  let maxTDS = 400;

  //required state variables

  const [fromDate, setFromDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [toDate, setTodate] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedLine, setSelectedLine] = useState("");
  const [selectedWfs, setSelectedWfs] = useState("");
  const [currentReading, setCurrentReading] = useState({});
  // const [deviceId, setDeviceId] = useState("BWCN0012");
  const [commandType, setCommandType] = useState(""); // start, stop, data
  const [deviceDetails, setDeviceDetails] = useState("");
  const [deviceId, setDeviceId] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [lastJobStatus, setLastJobStatus] = useState(null); // Track last job status

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  //functions
  let getWfsDetails = (_, wfsID, wfsObject) => {
    console.log("---getWfsDetails---", wfsID);
    setSelectedWfs(wfsID);
    setSelectedLine("");
    setDeviceDetails(deviceDetails?.device_id === "");
    // setDeviceId("");
  };

  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };
  // const fetchStatus = async () => {
  //   const data = await FetchDeviceStatus({ device_id: deviceId });
  //   console.log(data, "----data in fetchStatus-----");
  //   if (data) {
  //     setIsActive(data.is_active === 1); // Convert 1 to true, 0 to false
  //   }
  // };
  const fetchJobs = () => {
    // dispatch(FetchIotJobs(deviceDetails));
    dispatch(FetchIotJobs(deviceDetails?.device_id));
  };
  const handleUpdate = async (type) => {
    if (!type) {
      toast.error("Invalid command type!");
      return;
    }

    // Prevent creating a new job if there is already a QUEUED job
    if (jobs.length > 0 && jobs[0].job_status === "QUEUED") {
      toast.error(
        "A job is already queued. Please wait until it is completed."
      );
      return;
    }

    // Dispatch API call using `CreateIotJob`
    let res = await dispatch(
      CreateIotJob(deviceDetails?.device_id, type, true)
    ).then((data) => {
      console.log("------data-----", data);
      if (data?.status === 200) {
        // toast.success(`Job for "${type}" created successfully!`);
        // setIsActive(type === "start");
        fetchJobs();
        toast.success(
          `Kindly have patience till the assigned job gets completed...`
        );
      }
    });
    console.log(res, "----res----");
  };

  // const handleToggle = () => {
  //   const command = isActive ? "stop" : "start";
  //   setIsActive(!isActive);
  //   handleSendCommand(command);
  // };

  // const handleDataResend = () => {
  //   handleSendCommand("data");
  // };

  const searchAndUpdateLines = async () => {
    let res = await dispatch(
      HandleApiActions({
        ...wfsApis.get_lines,
        params: {
          call: 1,
          wfs_id: selectedWfs,
          // ...(searchLine !== "" && { org_name: searchLine }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
    setSelectedLine("");
    setIotReading([]);
    if (res.error) {
      toast.error("No lines found for WFS");
    } else {
      if (firstLoad) {
        setSelectedLine(res.data.lineIds[0]);
        setFirstLoad(false);
      }
      toast.success("Lines fetched successfully");
    }

    console.log("---searchAndUpdateLines---", res);
    console.log("---searchAndUpdateLines---", wfsDetails.wfsLinesList.lineIds);
  };

  const getIotForLine = async () => {
    let res = await dispatch(
      HandleApiActions({
        ...wfsApis.getIotForLines,
        params: {
          line_id: selectedLine,
        },
        show_toast: false,
      })
    );
    if (!res.error) {
      console.log("---getIotForLine---", res);
      setDeviceDetails(res.data?.[0] || {});
      console.log(deviceDetails, "----deviceDetails from getIotForLine---");
    } else {
      console.log("---you are here---");
      setIotReading([]);
      setCurrentReading({});
      toast.error("IOT device not linked to line");
    }
  };

  const getIotReadings = async () => {
    setIsLoading(true);
    let res = await dispatch(
      HandleApiActions({
        ...wfsApis.getIotReading,
        params: {
          page_size: isMobile ? 5 : 10,
          page_number: 1,
          device_id: deviceDetails?.device_id,
          // from_date: fromDate,
          // to_date: toDate,
          sort_order: "DESC",
        },
        // show_toast: false,
      })
    );

    console.log("---getIotDetails---", res);
    if (res.error) {
      toast.error(res.msg);
    } else {
      toast.success(res.msg);
      setIotReading(res.data.reverse());
      setCurrentReading(res.latestData);
      setIsLoading(false);
    }
  };

  const handleRefreshClick = () => {
    console.log("---You have clicked refresh button---");
    getIotReadings();
  };

  //useEffect

  React.useEffect(() => {
    searchAndUpdateWfs();
  }, []);

  React.useEffect(() => {
    if (
      firstLoad === true &&
      wfsList !== undefined &&
      wfsList !== null &&
      wfsList?.length > 0
    ) {
      setSelectedWfs(wfsList[0].wfs_id);
      setDefaultWfsname(wfsList[0].wfs_name);
    }
  }, [wfsList]);

  React.useEffect(() => {
    if (profileRole !== "1000" || selectedWfs !== "" || searchLine !== "") {
      console.log(
        "---wfsDetails.wfsLinesList.lineIds---",
        wfsDetails.wfsLinesList.lineIds,
        wfsDetails.wfsLinesList
      );
      setLineIds(wfsDetails.wfsLinesList.lineIds);
    } else if (profileRole === "1000") {
      setLineIds(wfsDetails.wfsLinesList.lineIds);
    }
  }, [wfsDetails]);

  React.useEffect(() => {
    if (firstLoad === true && wfsDetails?.wfsLinesList?.lineIds?.length > 0) {
      setSelectedLine(wfsDetails.wfsLinesList.lineIds[0]);
    }
  }, [wfsDetails.wfsLinesList.lineIds]);

  React.useEffect(() => {
    console.log("----first  searchAndUpdateLines----");
    setSelectedLine("");
    selectedWfs !== "" && searchAndUpdateLines();
  }, [selectedWfs]);

  React.useEffect(() => {
    console.log("first");
    // console.log(deviceId, "----deviceId---");
    selectedLine !== "" && getIotForLine();
    // selectedLine !== "" && dispatch(FetchIotJobs(deviceId));
  }, [selectedLine]);

  useEffect(() => {
    // console.log("----currentReading---", currentReading?.tdsTsensor);
    if (
      currentReading?.tdsBsensor !== null &&
      currentReading?.tdsBsensor !== undefined
    ) {
      // setCurrentTemp(parseInt(iotReading[0].temp));
      // setIotReadingUpdatedOn(moment.unix().format("DD-MM-YYYY HH:mm:ss"));

      let topTds = currentReading?.tdsBsensor?.split("-");
      console.log("----topTds----", topTds);
      if (currentReading?.tdsBsensor === "400+") {
        setCurrentTDS(400);
      } else {
        let avgTds = Number(topTds[0]) + Number(topTds[1]);
        setCurrentTDS(avgTds / 2);
      }
    } else {
      setIsLoading(true);
      setCurrentTemp(0);
      setCurrentTDS(0);
    }
  }, [iotReading, currentReading]);

  //MUI style component
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
      ...theme.applyStyles("dark", {
        backgroundColor: "#308fe8",
      }),
    },
  }));

  useEffect(() => {
    console.log("-----initial loading app----");
    console.log("-----deviceId-------", deviceDetails?.device_id);
    console.log("-----selectedLine-------", selectedLine);
    console.log("-----selectedWfs-------", selectedWfs);
    if (deviceDetails) {
      if (deviceDetails?.device_id) {
        getIotReadings();
        // dispatch(FetchIotJobs(deviceId));
        fetchJobs();
        setDeviceId(deviceDetails?.device_id);
        // fetchStatus();
      }
    }
  }, [deviceDetails?.device_id]);

  const WebSocketURL =
    selectedLine && deviceDetails?.device_id
      ? `wss://paasstage.bookwater.com/PIOT/ws/${deviceDetails?.device_id}`
      : null;

  const { messages, sendMessage } = useWebSocket(WebSocketURL);

  useEffect(() => {
    if (selectedLine) {
      sendMessage(JSON.stringify(deviceDetails?.device_id)); // Send deviceId when WebSocket is active
    }
  }, [selectedLine]);

  // useEffect(() => {
  //   if (
  //     deviceDetails?.data[0]?.device_id !== undefined &&
  //     deviceDetails?.data[0]?.device_id !== null
  //   ) {
  //     setIotDeviceId(deviceDetails?.data[0]?.device_id);
  //   }
  // }, [deviceDetails]);

  // WebSocket: Update Current Reading in Real-Time
  useEffect(() => {
    if (messages.length > 0) {
      console.log(messages, "----message from websocket live data-----");

      const latestMessage = messages[messages.length - 1];
      console.log(latestMessage, "----latestMessage----");

      // Validate message structure before accessing properties
      if (!latestMessage?.data?.state?.reported) {
        console.warn(
          "⚠️ WebSocket message is missing 'reported' data!",
          latestMessage
        );
        return;
      }

      const { reported } = latestMessage.data.state;

      // Ensure correct fields are updated and prevent overwriting with undefined
      setCurrentReading((prev) => ({
        ...prev,
        DeviceID: reported.DeviceID || prev.DeviceID,
        lattitude: reported.lattitude ?? prev.lattitude,
        longitude: reported.longitude ?? prev.longitude,
        temp:
          reported.temp !== undefined ? Math.round(reported.temp) : prev.temp,
        tdsBsensor: reported.tdsBsensor || prev.tdsBsensor,
        tdsTsensor: reported.tdsTsensor || prev.tdsTsensor,
        timestamp: latestMessage.data.timestamp,
      }));

      console.log("🔥 Updated real-time reading:", reported);
      console.log("🕒 Timestamp:", latestMessage.data.timestamp);
      console.log("📈 Current Reading:", currentReading);
    }
  }, [messages]);

  // useEffect(() => {
  //   console.log("----from data, to data----");
  //   getIotReadings();
  // }, [fromDate, toDate]);

  // useEffect(() => {
  //   console.log("---jobs ");
  //   if (!deviceDetails?.device_id) return;

  //   let interval = null;
  //   if (jobs.length > 0 && jobs[0].job_status === "QUEUED") {
  //     interval = setInterval(() => {
  //       fetchJobs();
  //     }, 30000);
  //   }

  //   // Check if the job has been completed and update `isActive`
  //   if (jobs.length > 0 && jobs[0].job_status === "COMPLETED") {
  //     setDeviceDetails((prevDetails) => ({
  //       ...prevDetails,
  //       is_active: jobs[0].job_type === "start" ? 1 : 0,
  //     }));
  //     getIotForLine();
  //   }

  //   return () => {
  //     if (interval) clearInterval(interval);
  //   };
  // }, [
  //   !deviceDetails,
  //   jobs.length > 0 ? jobs[0].job_status : null,
  //   selectedLine,
  // ]);

  // Effect 2: Runs when deviceDetails.device_id is available & QUEUED jobs exist
  useEffect(() => {
    if (!deviceDetails?.device_id) return;

    let interval = null;
    if (jobs.length > 0 && jobs[0].job_status === "QUEUED") {
      interval = setInterval(() => {
        fetchJobs();
      }, 30000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [deviceDetails?.device_id, jobs.length > 0 ? jobs[0].job_status : null]);

  useEffect(() => {
    console.log("----job status----");
    if (!deviceDetails?.device_id || jobs.length === 0) return;

    const currentJob = jobs[0]; // Get the latest job

    // Only proceed if the job status has changed from QUEUED -> COMPLETED
    if (
      currentJob.job_status === "COMPLETED" &&
      lastJobStatus !== "COMPLETED"
    ) {
      console.log("----queued --> completed");
      setDeviceDetails((prevDetails) => ({
        ...prevDetails,
        is_active: currentJob.job_type === "start" ? 1 : 0,
      }));

      getIotForLine(); // Fetch latest IoT data

      setLastJobStatus("COMPLETED"); // Update lastJobStatus to prevent re-runs
    } else if (currentJob.job_status !== "COMPLETED") {
      console.log("----queued --> not completed");
      setLastJobStatus(currentJob.job_status); // Update the last status if it's not completed
    }
  }, [deviceDetails, jobs.length > 0 ? jobs[0].job_status : null]);

  React.useEffect(() => {
    console.log("----currentTDS-----", currentTDS);
    if (currentTDS >= 300) {
      setOpenIotDataAlertDialog(true);
    }
  }, [currentTDS]);

  const handleClose = () => {
    setOpenIotDataAlertDialog(false);
  };

  // React.useEffect(() => {
  //   console.log("-----deviceDetails------", deviceDetails);
  //   if (deviceDetails) {
  //     if (
  //       deviceDetails?.data[0]?.device_id !== undefined &&
  //       deviceDetails?.data[0]?.device_id !== null &&
  //       deviceDetails?.data[0]?.device_id.length
  //     ) {
  //       console.log(
  //         deviceDetails?.data[0]?.device_id,
  //         "----deviceDetailstesting---"
  //       );
  //     }
  //     setDeviceId(deviceDetails?data[0]?.device_id)
  //   }
  // }, [deviceDetails]);
  // React.useEffect(() => {
  //   console.log("-----deviceId------", deviceId);
  // }, [deviceId]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mt: "15px",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {profileRole === "1000" && (
            <CustomAutoComplete
              field={{
                inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                optionKey: "role_name",
                optionKey2: "department",
                outputKey: "role_name",
                name: "role_1",
                label: "Search Facility",
              }}
            />
          )}
          <SelectWfs
            profileRole={profileRole}
            defaultWfs={defaultWfsName}
            getWfsDetails={getWfsDetails}
          />

          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "key",
              outputKey: "value",
              searchAndUpdateAPI: (searchText) => {
                setSearchLine(searchText);
              },
              defaultValue: selectedLine,
              input:
                lineIds?.map((ele) => {
                  return { key: ele, value: ele };
                }) || [],
              name: "lineId",
              label: "Select Line ID",
            }}
            handleInputChange={(_, line, obj) => {
              console.log("---Auto complete line---", line, lineIds);
              setSelectedLine(line);
              // setDeviceDetails(deviceDetails?.device_id === "");
              // setDeviceId("");
            }}
          />

          {/* <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "key",
              outputKey: "value",
              input: WfsData?.IotData?.map((line) => {
                console.log("----iotdeviceIds----", line);
                return {
                  key: line.device_id,
                  value: line.device_id,
                };
              }),
              name: "device_id",
              label: "Search Device ID",
            }}
          /> */}
        </Box>

        {/* <CustomButton label="Get" handleClick={getIotReadings} /> */}
      </Box>
      <Box
        component={Paper}
        sx={{
          display: "flex",
          gap: isMobile ? "0.25rem" : "1rem",
          flexDirection: "column",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "1rem",
          pl: "10px",
          pr: "10px",
        }}
      >
        {/* <TableContainer
          component={Paper}
          sx={{ maxWidth: 800, margin: "20px auto" }}
        >
          <h2 style={{ textAlign: "center", marginTop: "10px" }}>
            Device Logs
          </h2>

          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#1976d2", color: "white" }}>
                <TableCell style={{ color: "white" }}>Job ID</TableCell>
                <TableCell style={{ color: "white" }}>Status</TableCell>
                <TableCell style={{ color: "white" }}>Created At</TableCell>
                <TableCell style={{ color: "white" }}>Last Updated</TableCell>
                <TableCell style={{ color: "white" }}>Device ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs && jobs.length > 0 ? (
                jobs.slice(0, 5).map((job) => (
                  <TableRow key={job.jobId}>
                    <TableCell>{job.jobId}</TableCell>
                    <TableCell>{job.status}</TableCell>
                    <TableCell>
                      {new Date(job.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(job.lastUpdatedAt).toLocaleString()}
                    </TableCell>
                    <TableCell>{job.targets[0]?.split("/").pop()}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> */}

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            // alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            gap: { md: 5, xs: 2 },
            padding: "10px",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          {/* Toggle Switch for Active/Inactive */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              mt: "10px",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                WFS Name:
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                {defaultWfsName}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                Line ID :
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                {selectedLine}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                Device ID :
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                {deviceId}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mt: "4px" }}>
                <AntSwitch
                  checked={deviceDetails?.is_active === 1}
                  onChange={() => {
                    const isCurrentlyActive = deviceDetails?.is_active === 1;
                    handleUpdate(isCurrentlyActive ? "stop" : "start");
                  }}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Box>
              {/* 
            <Switch
              checked={isActive}
              onChange={() => {
                const newState = !isActive;
                setIsActive(newState);
                handleUpdate(newState ? "start" : "stop");
              }}
            /> */}
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", ml: "10px" }}
              >
                {deviceDetails?.is_active === 1 ? "Active" : "Inactive"}
              </Typography>
            </Box>
          </Box>
          {/* Data Resend Button */}
          <Box>
            <CustomButton
              label="Data Resend"
              handleClick={() => handleUpdate("data")}
            />
          </Box>
        </Box>
        {/* Temperature */}
        <Box
          component={Paper}
          elevation={15}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            gap: "1rem",
            padding: isMobile ? "0.5rem" : "2rem",
            position: "relative",
            ...(isMobile && { width: "220px" }),
          }}
        >
          {/* <Box
            sx={{
              background: theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
              border: `1px solid ${
                theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
              }`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.75rem",
              fontSize: "18px",
              position: "absolute",
              right: "1rem",
              top: "1rem",
              cursor: "pointer",
              transition: "background 0.3s ease, transform 0.2s ease",
              "&:hover": {
                background:
                  theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                transform: "scale(1.1)",
              },
            }}
            onClick={handleRefreshClick}
          > */}
          {/* <RefreshIcon /> */}
          {/* </Box> */}
          {/* <Box
            sx={{
              ...(isMobile && { marginTop: "4rem" }),
            }}
          >
            {isMobile || currentReading?.ph !== undefined ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "150px",
                  paddingX: "2rem",
                  background:
                    theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                  border: `1px solid ${
                    theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
                  }`,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
                      : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    background:
                      theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    WFS Name :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    {defaultWfsName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Line ID :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    {selectedLine}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Device ID :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    {deviceDetails?.device_id}
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              // m: "20px",
            }}
          >
            {/* Temperature */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                height: "300px",
                paddingX: "2rem",
                background:
                  theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                border: `1px solid ${
                  theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
                }`,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
                    : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  background:
                    theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                },
              }}
            >
              <Typography
                sx={{ fontSize: "16px", fontWeight: 600, textAlign: "left" }}
              >
                Temperatures
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <GaugeChart
                  value={parseInt(currentReading?.temp) || 0}
                  valueMax={maxTemp}
                  fillColor={"#a3dc9a"}
                  displayValue={
                    currentReading?.temp ? (
                      `${parseInt(currentReading?.temp)}°C`
                    ) : (
                      // <CircularProgress />
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={(theme) => ({
                          color: "#1a90ff",

                          animationDuration: "450ms",
                          position: "absolute",
                          left: 30,
                          bottom: 30,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                          },
                          ...theme.applyStyles("dark", {
                            color: "#308fe8",
                          }),
                        })}
                        size={40}
                        thickness={4}
                        // {...props}
                      />
                    )
                  }
                  background={{
                    "&:hover": {
                      background:
                        theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                    },
                    background:
                      theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                  }}
                />
              </Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 600, textAlign: "left" }}
              >
                {`Last Updated on ${moment
                  .unix(currentReading?.timestamp)
                  .format("DD-MM-YYYY HH:mm:ss")}`}
              </Typography>
            </Box>

            {/* device detail card */}
            {/* {currentReading?.ph === undefined && !isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  paddingX: "2rem",
                  background:
                    theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                  border: `1px solid ${
                    theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
                  }`,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
                      : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    background:
                      theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    WFS Name :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    {defaultWfsName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Line ID :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    {selectedLine}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Device ID :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    {deviceId}
                  </Typography>
                </Box>
              </Box>
            ) : null} */}
            {/* TDS */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                height: "300px",
                paddingX: "2rem",
                background:
                  theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                border: `1px solid ${
                  theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
                }`,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
                    : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  background:
                    theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                },
              }}
            >
              <Typography
                sx={{ fontSize: "16px", fontWeight: 600, textAlign: "left" }}
              >
                TDS Range
              </Typography>
              {/* <Typography>{tdsRange}</Typography> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <GaugeChart
                  // value={((89 - 75) / (100 - 75)) * 100}
                  value={currentTDS}
                  displayValue={
                    currentReading?.tdsBsensor ? (
                      currentReading?.tdsBsensor
                    ) : (
                      // <CircularProgress />
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={(theme) => ({
                          color: "#1a90ff",

                          animationDuration: "450ms",
                          position: "absolute",
                          left: 30,
                          bottom: 30,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                          },
                          ...theme.applyStyles("dark", {
                            color: "#308fe8",
                          }),
                        })}
                        size={40}
                        thickness={4}
                        // {...props}
                      />
                    )
                  }
                  valueMax={maxTDS}
                  fillColor={
                    currentTDS < 150
                      ? "green"
                      : currentTDS > 150 && currentTDS < 250
                      ? "#a3dc9a"
                      : currentTDS >= 250 && currentTDS < 300
                      ? "#f0b27a"
                      : currentTDS >= 300 && currentTDS < 400
                      ? "red"
                      : currentTDS >= 400
                      ? "red"
                      : ""
                  }
                  background={{
                    "&:hover": {
                      background:
                        theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                    },
                    background:
                      theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                  }}
                  // fillColor={"#a3dc9a"}
                />
                {/* <BorderLinearProgress variant="determinate" value={50} /> */}
                {/* <SegmentedProgressBar value={89} /> */}
              </Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 600, textAlign: "left" }}
              >
                {`Last Updated on ${moment
                  .unix(currentReading?.timestamp)
                  .format("DD-MM-YYYY HH:mm:ss")}`}
              </Typography>
            </Box>

            {/* PH */}
            {currentReading?.ph !== undefined && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "300px",
                  paddingX: "2rem",
                  background:
                    theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                  border: `1px solid ${
                    theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
                  }`,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
                      : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    background:
                      theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                  },
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, textAlign: "left" }}
                >
                  PH
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <GaugeChart
                    value={currentTDS}
                    valueMax={maxTDS}
                    fillColor={"#a3dc9a"}
                  />
                </Box>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, textAlign: "left" }}
                >
                  {`Last Updated on ${moment
                    .unix(currentReading?.timestamp)
                    .format("DD-MM-YYYY HH:mm:ss")}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {/* TDS */}
        <Box
          component={Paper}
          elevation={15}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            gap: "1rem",
            padding: isMobile ? "0.5rem" : "2rem",
            // padding: "2rem",
            ...(isMobile && { width: "220px" }),
            // background: theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
            // border: `1px solid ${
            //   theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
            // }`,
            // boxShadow:
            //   theme.palette.mode === "dark"
            //     ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
            //     : "0px 4px 10px rgba(0, 0, 0, 0.1)",
            // "&:hover": {
            //
            //   background: theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
            // },
          }}
        >
          <Box sx={{ marginBottom: "1rem", mt: "20px" }}>
            <CustomDatePicker
              format="YYYY-MM-DD"
              handleChange={(date) => {
                console.log("---date---", date);
                setFromDate(date.from);
                setTodate(date.to);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <Box
              // component={Paper}
              // elevation={15}
              sx={{
                // padding: "0.5rem",
                // margin: "10px",
                paddingX: "1rem",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
                background:
                  theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                border: `1px solid ${
                  theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
                }`,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
                    : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  background:
                    theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                },
                ...(isMobile && { width: "220px" }),
              }}
            >
              <Typography sx={{ fontSize: 18, textAlign: "center" }}>
                Temperature
              </Typography>
              <LineChart
                inputs={[
                  {
                    data: iotReading?.map((reading) => reading?.temp) || [],
                    label: "temp",
                  },
                ]}
                xAxisLabel={
                  iotReading?.length
                    ? iotReading?.map(
                        (reading) =>
                          moment
                            .unix(reading?.timestamp)
                            .format("DD-MM-YYYY  HH:mm:ss") || []
                      )
                    : []
                }
              />
            </Box>
            <Box
              // component={Paper}
              // elevation={15}
              sx={{
                // margin: "10px",
                // padding: "0.5rem",
                paddingX: "1rem",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
                background:
                  theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
                border: `1px solid ${
                  theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
                }`,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
                    : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  background:
                    theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
                },
                ...(isMobile && { width: "220px" }),
              }}
            >
              <Typography sx={{ fontSize: 18, textAlign: "center" }}>
                TDS
              </Typography>
              <LineChart
                inputs={[
                  {
                    data: iotReading?.length
                      ? iotReading?.map(
                          (reading) =>
                            (Number(reading?.tdsBsensor?.split("-")[0]) +
                              Number(reading?.tdsBsensor?.split("-")[1])) /
                              2 || 0
                        )
                      : [],
                    label: "Top TDS",
                  },
                  {
                    data: iotReading?.length
                      ? iotReading?.map(
                          (reading) =>
                            (Number(reading?.tdsBsensor?.split("-")[0]) +
                              Number(reading?.tdsBsensor?.split("-")[1])) /
                              2 || 0
                        )
                      : [],

                    label: "Bottom TDS",
                  },
                ]}
                xAxisLabel={iotReading?.map(
                  (reading) =>
                    moment
                      .unix(reading?.timestamp)
                      .format("DD-MM-YYYY HH:mm:ss") || 0
                )}
              />
              {/* <BarChartsMUI /> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component={Paper} elevation={15}>
        <Box>
          <Typography
            sx={{ textAlign: "center", fontSize: "16px", margin: "5px" }}
          >
            Device Logs
          </Typography>
        </Box>
        <CustomTable
          tableHead={[
            "Job ID",
            "Status",
            "Created At",
            "Last Updated",
            "Device ID",
          ]}
          tableDataKey={[
            "job_id", // Use the correct key for job ID
            "job_status", // Use the correct key for job status
            {
              type: "component",
              component: ({ row }) =>
                moment(row.created_at).format("DD-MM-YYYY h:mm:ss a"), // Correct field for createdAt
            },
            {
              type: "component",
              component: ({ row }) =>
                moment(row.updated_at).format("DD-MM-YYYY h:mm:ss a"),
            },
            "device_id", // Use the correct key for device ID
          ]}
          tableData={jobs.slice(0, 5)} // Limit to 5 jobs
          pagination={false} // No pagination for a small dataset
        />
      </Box>
      <IotDataAlretDialog
        openIotDataAlertDialog={openIotDataAlertDialog}
        handleClose={handleClose}
        // setOpenIotDataAlertDialog={setOpenIotDataAlertDialog}
      />
    </Box>
  );
}

export default IOTGraph;

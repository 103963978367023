import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddFinalProductSKU,
  GetFinalProductSKU,
} from "../../Redux/Actions/productsAction";
import { toast } from "react-toastify";
import appStyle from "../../AppMeta/appStyle";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import CustomDialog from "../../Components/CustomComp/CustomDialog";

function AddFinalSkuProduct({
  pageNumber,
  pageSize,
  available,
  createdAtSort,
  handleDrawerClose,
}) {
  const organisationDetails = useSelector((state) => state.organizationData);
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const countryCodes = useSelector(
    (state) => state.userManagementReducer.countryCodes
  );
  console.log("---profileRole---", profileRole);
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.productData);
  const [allFacility, setAllFacility] = React.useState([]);
  const [organizationSearch, setOrganizationSearch] = React.useState("");

  const [searchCountry, setSearchCountry] = React.useState("");
  const [searchState, setSearchState] = React.useState("");
  console.log("-----searchState-----", searchState);
  console.log("----searchCountry------", searchCountry);
  const [containerId, setContainerId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [skuQty, setSkuQty] = React.useState(1);
  const [image, setImage] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [cgstPerc, setCgstPerc] = React.useState("");
  const [sgstPerc, setSgctPerc] = React.useState("");
  const [igstPerc, setIgstPerc] = React.useState("");
  const [minBookQty, setMinBookQty] = React.useState("");
  const [maxBookQty, setMaxBookQty] = React.useState("");
  const [deliveryCharges, setDeliveryCharges] = React.useState("");
  const [orderType, setOrderType] = React.useState("");
  const [bookingType, setBookingType] = React.useState("1");
  const [mrpR, setMrpR] = React.useState(1);
  const [spR, setSpR] = React.useState(1);
  const [depositR, setDepositR] = React.useState(1);
  const [refundTypeR, setRefundTypeR] = React.useState(100);
  const [refundAmountR, setRefundAmountR] = React.useState(1);
  const [mrpO, setMrpO] = React.useState(1);
  const [spO, setSpO] = React.useState(1);
  const [depositO, setDepositO] = React.useState(1);
  const [refundTypeO, setRefundTypeO] = React.useState("100");
  const [refundAmountO, setRefundAmountO] = React.useState(1);
  const [isActive, setIsActive] = React.useState(true);
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [error, setError] = React.useState("");
  const [error1, setError1] = React.useState("");
  const [itemIgst, setItemIgst] = useState("");
  const [formSelectedObject, setFormSelectedObject] = React.useState({});
  const [formSelectedStateOption, setFormSelectedStateOption] =
    React.useState("");
  console.log(
    "-----formSelectedStateOption------------",
    formSelectedStateOption
  );
  const [rows, setRows] = useState([]);
  console.log("-----rows--------", rows);
  console.log("------formSelectedObject-----", formSelectedObject);
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_country_codes,
        params: { ignore_app_loading_spinner: true },
        show_toast: false,
      })
    );
  }, []);

  React.useEffect(() => {
    console.log("productData");
    console.log(productData);
  }, [productData]);

  React.useEffect(() => {
    searchAndUpdateFacility();
  }, [organizationSearch]);

  React.useEffect(() => {
    if (formSelectedObject?.country_code) {
      setRows((prevRows) => [
        ...prevRows,
        { country_code: formSelectedObject.country_code },
      ]);
    }
  }, [formSelectedObject]);
  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  const handleChangeBookingType = (event) => {
    setBookingType(event.target.value);
  };

  const handleChangeOrderType = (event) => {
    setOrderType(event.target.value);
  };

  const handleAddFinalProductSubmit = async (formData) => {
    console.log("---profileRole---", formData);

    formData.bookingType = 1;
    // const reqObj = {
    //   org_id: profileRole === "1000" && formData.facility,
    //   name: formData.name,
    //   description: formData.description,
    //   capacity: formData.capacity,
    //   material: formData.material,
    //   life_span: formData.lifeSpan,
    //   amount: refundAmountR,
    //   sku_qty: [formData.skuQuantity],
    //   image: formData.image,
    //   facility_price: { deposit_price: 200 },
    //   hsn_code: formData.hsnCode,
    //   cgst_perc: parseInt(formData.CGST),
    //   sgst_perc: parseInt(formData.SGST),
    //   igst_perc: parseInt(formData.IGST),
    //   min_book_qty: formData.minimumBookQuantity,
    //   max_book_qty: formData.maximumBookQuantity,
    //   delivery_charges: formData.deliveryCharge,
    //   order_type: formData.orderType,
    //   booking_type: formData.bookingType,
    //   refill: {
    //     mrp: ["1", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.refilMrp)
    //       : 1,
    //     sp: ["1", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.refilSalePrice)
    //       : 1,
    //     deposit: ["1", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.refilDeposit)
    //       : 1,
    //     refund_type: ["1", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.refilManufactureType)
    //       : 100,
    //     refund_amount: ["1", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.refilRefundAmount)
    //       : 1,
    //   },
    //   one_time_sale: {
    //     mrp: ["2", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.otsMrp)
    //       : 1,
    //     sp: ["2", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.otsSalePrice)
    //       : 1,
    //     deposit: ["2", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.otsDeposit)
    //       : 1,
    //     refund_type: ["2", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.OtsManufactureType)
    //       : 100,
    //     refund_amount: ["2", "3"].includes(formData.bookingType)
    //       ? parseInt(formData.otsRefundAmount)
    //       : 1,
    //   },
    //   is_active: true,
    // };
    const reqObj = {
      name: formData.name,
      description: formData.description || "Adding New Product",
      container_capacity: formData.container_capacity,
      container_material: formData.container_material,
      life_span: formData.lifeSpan,
      amount: refundAmountR,
      sku_qty: [formData.skuQuantity || "1"],
      image: formData.image,
      min_book_qty: formData.minimumBookQuantity,
      max_book_qty: formData.maximumBookQuantity,
      order_type: formData.orderType || "200",
      booking_type: formData.bookingType || "1",
      taxes: rows || [],
      // refill: {
      //   mrp: 200.0,
      //   sp: 180.0,
      //   deposit: 34,
      //   refund_type: 100,
      //   refund_amount: 2345,
      // },
      refill: {
        mrp: ["1", "3"].includes(String(formData.bookingType))
          ? parseInt(formData.refilMrp)
          : 1,
        sp: ["1", "3"].includes(String(formData.bookingType))
          ? parseInt(formData.refilSalePrice)
          : 1,
        deposit: ["1", "3"].includes(String(formData.bookingType))
          ? parseInt(formData.refilDeposit)
          : 1,
        refund_type: 100,
        refund_amount: 200,
      },
      // one_time_sale: {
      //   mrp: 250.0,
      //   sp: 200.0,
      //   deposit: 1,
      //   refund_type: 100,
      //   refund_amount: 1,
      // },
      one_time_sale: {
        mrp: ["2", "3"].includes(String(formData.bookingType))
          ? parseInt(formData.otsMrp)
          : 1,
        sp: ["2", "3"].includes(String(formData.bookingType))
          ? parseInt(formData.otsSalePrice)
          : 1,
        deposit: ["2", "3"].includes(formData.bookingType)
          ? parseInt(formData.otsDeposit)
          : 1,
        refund_type: ["2", "3"].includes(formData.bookingType)
          ? parseInt(formData.OtsManufactureType)
          : 100,
        refund_amount: ["2", "3"].includes(formData.bookingType)
          ? parseInt(formData.otsRefundAmount)
          : 1,
      },

      org_id: formData.facility,
      weight: formData.weight || 400,
      // bookwater_charges: {
      //   fixed_revenue: formData.fixed_revenue,
      //   monthly_charge: formData.monthly_charge,
      //   per_product_refilling_charge: formData.per_product_refilling_charge,
      //   per_product_charge: formData.per_product_charge,
      // },
    };
    console.log("---ReqObj---", reqObj);
    const response = await dispatch(AddFinalProductSKU(reqObj));
    if (response.status === 200) {
      setTimeout(() => {
        dispatch(GetFinalProductSKU());
      }, 1500);
      handleDrawerClose();
    }
  };

  // const handleAddProduct = () => {
  //   if (minBookQty < maxBookQty) {
  //     toast.error(
  //       "MinBook Quantity should not be greater than MaxBook Quantity",
  //       {
  //         position: "top-right",
  //         autoClose: 1000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       }
  //     );
  //   } else {
  //     setError1("");
  //     const reqObj = {
  //       name: name,
  //       description: description,
  //       capacity: capacity,
  //       material: material,
  //       life_span: lifeSpan,
  //       amount: refundAmountR,
  //       sku_qty: [skuQty],
  //       image: image,
  //       facility_price: { deposit_price: 200 },
  //       hsn_code: hsnCode,
  //       cgst_perc: parseInt(itemCgst),
  //       sgst_perc: parseInt(itemSgst),
  //       igst_perc: parseInt(itemIgst),
  //       min_book_qty: minBookQty,
  //       max_book_qty: maxBookQty,
  //       delivery_charges: deliveryCharges,
  //       order_type: orderType,
  //       booking_type: bookingType,
  //       refill: {
  //         mrp: parseInt(mrpR),
  //         sp: parseInt(spR),
  //         deposit: parseInt(depositR),
  //         refund_type: parseInt(refundTypeR),
  //         refund_amount: parseInt(refundAmountR),
  //       },
  //       one_time_sale: {
  //         mrp: parseInt(mrpO),
  //         sp: parseInt(spO),
  //         deposit: parseInt(depositO),
  //         refund_type: parseInt(refundTypeO),
  //         refund_amount: parseInt(refundAmountO),
  //       },
  //       is_active: true,
  //     };
  //     console.log("reqObj");
  //     console.log(reqObj);
  //     dispatch(AddFinalProductSKU(reqObj));
  //     setTimeout(() => {
  //       dispatch(
  //         GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort)
  //       );
  //     }, 1000);
  //   }
  // };

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          ...(organizationSearch !== "" && { org_name: organizationSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const handleMinBookQtyChange = (event) => {
    setMinBookQty(event.target.value);
  };

  const handleMaxBookQtyChange = (event) => {
    setMaxBookQty(event.target.value);
  };

  const handleInputChange = (index, field, value) => {
    let row = rows[index];
    if (row) {
      rows[index] = {
        ...row,
        country_code: formSelectedObject.country_code,
        state_code: formSelectedStateOption,
        [field]: value,
      };
    } else {
      rows.push({
        country_code: formSelectedObject.country_code,
        state_code: formSelectedStateOption,
        [field]: value,
      });
    }
    // const updatedRows = rows.map((row, i) =>
    //   i === index
    //     ? {
    //         ...row,
    //         country_code: formSelectedObject.country_code,
    //         [field]: value,
    //       }
    //     : row
    // );
    setRows([...rows]);

    // Automatically add a new row when editing the last row
    // if (
    //   index === rows.length - 1 &&
    //   (field === "tax_name" || field === "charges_perc") &&
    //   value !== ""
    // ) {
    //   setRows([...updatedRows]);
    // }
  };

  const handleCheckboxChange = (index) => {
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, checked: !row.checked } : row
    );
    setRows(updatedRows);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <CustomForm
            handleSubmit={handleAddFinalProductSubmit}
            handleClose={handleDrawerClose}
            data={[
              {
                ...(profileRole === "1000" && {
                  data: [
                    {
                      inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                      optionKey: "org_name",
                      outputKey: "id",
                      searchAndUpdateAPI: (searchText) => {
                        setOrganizationSearch(searchText);
                      },
                      input: allFacility || [],
                      name: "facility",
                      label: "Enter Facility Name",
                    },
                  ],
                }),
              },
              {
                numberOfColumn: 2,
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Product Name",
                    defaultvalue: "",
                    name: "name",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "Description",
                  //   defaultvalue: "",
                  //   name: "description",
                  //   textFieldType: appMeta.textFieldTypes.TEXT,
                  //   isHintRequired: true,
                  //   isRequired: true,
                  // },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Capacity",
                    defaultvalue: "",
                    name: "container_capacity",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },

                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Material",
                    defaultvalue: "",
                    name: "container_material",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Life Span",
                    defaultvalue: "",
                    name: "lifeSpan",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },

                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "SKU Quantity",
                  //   defaultvalue: "",
                  //   name: "skuQuantity",
                  //   textFieldType: appMeta.textFieldTypes.TEXT,
                  //   isHintRequired: true,
                  //   isRequired: true,
                  // },
                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "HSN Code",
                  //   defaultvalue: "",
                  //   name: "hsnCode",
                  //   textFieldType: appMeta.textFieldTypes.TEXT,
                  //   isHintRequired: true,
                  //   isRequired: true,
                  // },
                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "CGST(%)",
                  //   defaultvalue: "",
                  //   name: "CGST",
                  //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                  //   isHintRequired: true,
                  //   isRequired: true,
                  // },
                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "SGST(%)",
                  //   defaultvalue: "",
                  //   name: "SGST",
                  //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                  //   isHintRequired: true,
                  //   isRequired: true,
                  // },
                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "IGST(%)",
                  //   defaultvalue: "",
                  //   name: "IGST",
                  //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                  //   isHintRequired: true,
                  //   isRequired: true,
                  // },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "MRP",
                    defaultvalue: "",
                    name: "refilMrp",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Sale Price",
                    defaultvalue: "",
                    name: "refilSalePrice",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Deposit",
                    defaultvalue: "",
                    name: "refilDeposit",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Minimum Book Quantity",
                    defaultvalue: "",
                    name: "minimumBookQuantity",
                    textFieldType: appMeta.textFieldTypes.NUMBER,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Maximum Book Quantity",
                    defaultvalue: "",
                    name: "maximumBookQuantity",
                    textFieldType: appMeta.textFieldTypes.NUMBER,
                    isHintRequired: true,
                    isRequired: true,
                  },

                  // {
                  //   inputType: appMeta.inputFieldTypes.SELECT,
                  //   placeHolder: "Order Type",
                  //   // defaultvalue: "",
                  //   name: "orderType",
                  //   input: [
                  //     {
                  //       menuItem: "Insta",
                  //       value: 100,
                  //     },
                  //     {
                  //       menuItem: "Regular",
                  //       value: 200,
                  //     },
                  //     {
                  //       menuItem: "Subscription",
                  //       value: 300,
                  //     },
                  //     {
                  //       menuItem: "All types",
                  //       value: 400,
                  //     },
                  //   ],
                  // },
                  // Delivery charges
                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "Delivery Charge",
                  //   defaultValue: "",
                  //   name: "deliveryCharge",
                  //   textFieldType: appMeta.textFieldTypes.TEXT,
                  //   isHintRequired: true,
                  //   isRequired: true,

                  // },
                  // {
                  //   inputType: appMeta.inputFieldTypes.INPUT,
                  //   placeHolder: "Product Weight in grms",
                  //   defaultValue: "",
                  //   name: "weight",
                  //   textFieldType: appMeta.textFieldTypes.TEXT,
                  //   isHintRequired: true,
                  //   isRequired: true,
                  // },
                ],
              },
              // {
              //   title: "",
              //   data: [
              //     {
              //       inputType: appMeta.inputFieldTypes.RADIO,
              //       defaultValue: "1",
              //       name: "bookingType",
              //       placeHolder: "Booking type",
              //       input: [
              //         {
              //           name: "bookingType",
              //           value: "1",
              //           label: "Refill",
              //         },
              //         {
              //           name: "bookingType",
              //           value: "2",
              //           label: "One Time Sale",
              //         },
              //         {
              //           name: "bookingType",
              //           value: "3",
              //           label: "Both",
              //         },
              //       ],
              //     },
              //   ],
              // },
              // {
              //   conditionallyRenderData: {
              //     name: "bookingType",
              //     value: ["1", "3"],
              //   },
              //   numberOfColumn: 2,
              //   title: "Refill",
              //   data: [
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "MRP",
              //       defaultvalue: "",
              //       name: "refilMrp",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Sale Price",
              //       defaultvalue: "",
              //       name: "refilSalePrice",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Deposit",
              //       defaultvalue: "",
              //       name: "refilDeposit",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     // Check in edit form
              //     {
              //       inputType: appMeta.inputFieldTypes.SELECT,
              //       placeHolder: "Refund Type",
              //       name: "refilManufactureType",
              //       input: [
              //         {
              //           menuItem: "Wallet",
              //           value: 100,
              //         },
              //         {
              //           menuItem: "Water Drops",
              //           value: 200,
              //         },
              //       ],
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Refund amount",
              //       defaultvalue: "",
              //       name: "refilRefundAmount",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //   ],
              // },
              // {
              //   conditionallyRenderData: {
              //     name: "bookingType",
              //     value: ["2", "3"],
              //   },
              //   numberOfColumn: 2,
              //   title: "One time sale",
              //   data: [
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "MRP",
              //       defaultvalue: "",
              //       name: "otsMrp",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Sale Price",
              //       defaultvalue: "",
              //       name: "otsSalePrice",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Deposit",
              //       defaultvalue: "",
              //       name: "otsDeposit",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.SELECT,
              //       placeHolder: "Refund Type",
              //       name: "OtsManufactureType",
              //       input: [
              //         {
              //           menuItem: "Wallet",
              //           value: 100,
              //         },
              //         {
              //           menuItem: "Water Drops",
              //           value: 200,
              //         },
              //       ],
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Refund amount",
              //       defaultvalue: "",
              //       name: "otsRefundAmount",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //   ],
              // },
              // {
              //   title: "Add Taxes",
              //   numberOfColumn: 2,
              //   data: [
              //     {
              //       component: () => {
              //         return (
              //           <CustomAutoComplete
              //             field={{
              //               inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              //               optionKey: "country",
              //               outputKey: "country_code",
              //               searchAndUpdateAPI: (searchText) => {
              //                 setSearchCountry(searchText);
              //               },
              //               input: countryCodes,
              //               name: "country_code",
              //               label: "Select Country",
              //             }}
              //             handleInputChange={(name, selectedOption, obj) => {
              //               setFormSelectedObject(obj);
              //             }}
              //           />
              //         );
              //       },
              //     },
              //     {
              //       component: () => {
              //         return (
              //           <CustomAutoComplete
              //             field={{
              //               inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              //               optionKey: "state_name",
              //               outputKey: "state_code",
              //               searchAndUpdateAPI: (searchText) => {
              //                 setSearchState(searchText);
              //               },
              //               input: formSelectedObject?.state,

              //               name: "state_code",
              //               label: "Select State",
              //             }}
              //             handleInputChange={(name, selectedOption) => {
              //               setFormSelectedStateOption(selectedOption);
              //             }}
              //           />
              //         );
              //       },
              //     },
              //   ],
              // },
              // {
              //   data: [
              //     {
              //       component: () => {
              //         return (
              //           <TableContainer component={Paper}>
              //             <Table sx={{ border: "1px solid black" }}>
              //               <TableHead>
              //                 <TableRow>

              //                   <TableCell
              //                     sx={{
              //                       fontWeight: 800,
              //                       border: "1px solid black",
              //                     }}
              //                   >
              //                     Tax Name
              //                   </TableCell>
              //                   <TableCell
              //                     sx={{
              //                       fontWeight: 800,
              //                       border: "1px solid black",
              //                     }}
              //                   >
              //                     Tax Value
              //                   </TableCell>
              //                 </TableRow>
              //               </TableHead>
              //               <TableBody>
              //                 {[...rows, { tax_name: "" }].map((row, index) => (
              //                   <TableRow key={index}>

              //                     <TableCell sx={{ border: "1px solid black" }}>
              //                       <TextField
              //                         autoFocus={true}
              //                         value={row.key}
              //                         onChange={(e) =>
              //                           handleInputChange(
              //                             index,
              //                             "tax_name",
              //                             e.target.value
              //                           )
              //                         }
              //                         variant="outlined"
              //                         size="small"
              //                       />
              //                     </TableCell>
              //                     <TableCell sx={{ border: "1px solid black" }}>
              //                       <TextField
              //                         value={row.value}
              //                         onChange={(e) =>
              //                           handleInputChange(
              //                             index,
              //                             "charges_perc",
              //                             e.target.value
              //                           )
              //                         }
              //                         variant="outlined"
              //                         size="small"
              //                       />
              //                     </TableCell>
              //                   </TableRow>
              //                 ))}
              //               </TableBody>
              //             </Table>
              //           </TableContainer>
              //         );
              //       },
              //     },
              //   ],
              // },
              // {
              //   title: "Book Water Charges",
              //   numberOfColumn: 2,
              //   data: [
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Fixed Revenue",
              //       defaultvalue: "",
              //       name: "fixed_revenue",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Monthly Charge",
              //       defaultvalue: "",
              //       name: "monthly_charge",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Per Refilling Charge",
              //       defaultvalue: "",
              //       name: "per_product_refilling_charge",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //     {
              //       inputType: appMeta.inputFieldTypes.INPUT,
              //       placeHolder: "Product Charge",
              //       defaultvalue: "",
              //       name: "per_product_charge",
              //       textFieldType: appMeta.textFieldTypes.TEXT,
              //       isHintRequired: true,
              //       // isRequired: true,
              //     },
              //   ],
              // },
              {
                title: "Upload",
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.FILE,
                    name: "image",
                    placeHolder: "Product Image",
                  },
                ],
              },
            ]}
          />
          {/* <TextField
            label="Name"
            fullWidth
            value={name}
            size="small"
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="Capacity"
            fullWidth
            value={capacity}
            size="small"
            onChange={(event) => setCapacity(event.target.value)}
          /> */}
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="Material"
            fullWidth
            value={material}
            size="small"
            onChange={(event) => setMaterial(event.target.value)}
          />
          <TextField
            label="Life Spans"
            fullWidth
            value={lifeSpan}
            onChange={(event) => setLifeSpan(event.target.value)}
            size="small"
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="SKU Quantity"
            fullWidth
            value={skuQty[0]}
            onChange={(event) => setSkuQty(event.target.value)}
            size="small"
            type="number"
          />
          <TextField
            label="HSN Code"
            fullWidth
            value={hsnCode}
            type="number"
            size="small"
            onChange={(event) => setHsnCode(event.target.value)}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="CGST %"
            fullWidth
            size="small"
            value={itemCgst}
            onChange={(e) => setItemCgst(e.target.value)}
          />
          <TextField
            label="SGST %"
            fullWidth
            value={itemSgst}
            size="small"
            onChange={(e) => setItemSgst(e.target.value)}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="IGST %"
            fullWidth
            value={itemIgst}
            size="small"
            onChange={(e) => setItemIgst(e.target.value)}
          />
          <TextField
            label="Min Book Quantity"
            fullWidth
            type="number"
            value={minBookQty}
            size="small"
            onChange={handleMinBookQtyChange}
            inputProps={{ maxlength: 2 }}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="Max Book Quantity"
            fullWidth
            value={maxBookQty}
            size="small"
            type="number"
            error={error1 !== ""}
            helperText={error1}
            onChange={handleMaxBookQtyChange}
            inputProps={{ maxlength: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Order Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={orderType}
                label="Order Type"
                onChange={handleChangeOrderType}
              >
                <MenuItem value={100}>Insta</MenuItem>
                <MenuItem value={200}>Regualar</MenuItem>
                <MenuItem value={300}>Subscription</MenuItem>
                <MenuItem value={400}>All types</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
         
          <TextField
            label="Description"
            fullWidth
            value={description}
            size="small"
            onChange={(event) => setDescription(event.target.value)}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={bookingType}
              onChange={handleChangeBookingType}
            >
              <FormControlLabel value={1} control={<Radio />} label="Refill" />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="One Time Sale"
              />
              <FormControlLabel value={3} control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>
        </Box> */}
        {/* {bookingType === "1" && (
          <>
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              Refill
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpR}
                size="small"
                type="number"
                onChange={(event) => setMrpR(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spR}
                size="small"
                type="number"
                onChange={(event) => setSpR(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositR}
                size="small"
                type="number"
                onChange={(event) => setDepositR(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountR}
                size="small"
                type="number"
                onChange={(event) => setRefundAmountR(event.target.value)}
              />
            </Box>
          </>
        )} */}

        {/* {bookingType === "2" ? (
          <>
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              One time sale
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpO}
                size="small"
                onChange={(event) => setMrpO(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spO}
                size="small"
                onChange={(event) => setSpO(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositO}
                size="small"
                onChange={(event) => setDepositO(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountO}
                size="small"
                onChange={(event) => setRefundAmountO(event.target.value)}
              />
            </Box>
          </>
        ) : (
          ""
        )} */}

        {/* {bookingType === "3" && (
          <>
            {" "}
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              Refill
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpR}
                size="small"
                onChange={(event) => setMrpR(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spR}
                size="small"
                onChange={(event) => setSpR(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositR}
                size="small"
                onChange={(event) => setDepositR(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountR}
                size="small"
                onChange={(event) => setRefundAmountR(event.target.value)}
              />
            </Box>
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              One time sale
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpO}
                size="small"
                onChange={(event) => setMrpO(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spO}
                size="small"
                onChange={(event) => setSpO(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositO}
                size="small"
                onChange={(event) => setDepositO(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountO}
                size="small"
                onChange={(event) => setRefundAmountO(event.target.value)}
              />
            </Box>
          </>
        )} */}

        {/* <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddProduct}
            sx={appStyle.general.button.blueButton}
          >
            Add Final Product
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDrawerClose}
            sx={appStyle.general.button.redButton}
          >
            Close
          </Button>
        </Box> */}
      </Box>
    </div>
  );
}

export default AddFinalSkuProduct;

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

function CustomDatePicker({
  handleChange,
  format = "YYYY-MM-DD",
  setToDate,
  toDate,
  fromDate,
  setFromDate,
}) {
  //state

  const [dateOpen, setDateOpen] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => {
              console.log("---date---", date);
              setFromDate(date);
              setToDate(fromDate);
              setDateOpen(true);
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        -
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="To Date"
            open={dateOpen}
            value={toDate}
            minDate={fromDate}
            onChange={(date) => {
              setToDate(date);
              handleChange({
                from: dayjs(fromDate).format(format),
                to: dayjs(date).format(format),
              });
              setDateOpen(false);
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}

export default CustomDatePicker;

/* <QRPdfGenerator
            input={["Hello", "World", "This", "Is", "QR"]}
            label="Generate PDF"
            height="200px"
            width="200px"
          /> */

import { Box, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import CustomButton from "./CustomButton";
import { QRCodeCanvas } from "qrcode.react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function QRPdfGenerator({ input, label, width }) {
  const qrRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGeneratePDF = async () => {
    try {
      setIsGenerating(true); // Show QR codes for rendering

      // Wait for the UI to update
      await new Promise((resolve) => setTimeout(resolve, 500));

      const qrCodes = qrRef.current;
      console.log("----QR Code container----", qrCodes);
      if (!qrCodes) {
        console.error("QR Code container not found.");
        return;
      }

      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const margin = 10; // Margin in mm
      let yOffset = margin; // Tracks Y position

      const qrPerRow = 3; // Number of QR codes per row
      const qrSize = width; // QR code size in mm
      const qrPadding = 10; // Space between QR codes
      const rowHeight = qrSize + qrPadding; // Total row height

      const canvas = await html2canvas(qrCodes, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/png");

      ///old code
      // const pdf = new jsPDF("p", "mm", "a4");
      // const imgWidth = width; // Adjust width
      // const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

      // pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      for (let i = 0; i < input.length; i += qrPerRow) {
        if (yOffset + rowHeight > pageHeight - margin) {
          pdf.addPage();
          yOffset = margin;
        }

        for (let j = 0; j < qrPerRow && i + j < input.length; j++) {
          const xPos = margin + j * (qrSize + qrPadding);
          const qrCanvas = await html2canvas(qrRef.current.children[i + j]);
          const imgData = qrCanvas.toDataURL("image/png");

          pdf.addImage(imgData, "PNG", xPos, yOffset, qrSize, qrSize);
          // pdf.text(input[i + j], xPos, yOffset + qrSize + 5, {
          //   maxWidth: qrSize,
          // });
        }

        yOffset += rowHeight; // Move to the next row
      }
      pdf.save("qrcodes.pdf");
      setIsGenerating(false); // Hide QR codes after capture
    } catch (error) {
      console.error("Error generating PDF:", error);
      setIsGenerating(false);
    }
  };

  return (
    <Box textAlign="center">
      <CustomButton
        handleClick={handleGeneratePDF}
        endIcon={<PictureAsPdfIcon />}
        label={label}
      />

      {/* QR Codes Container (Hidden Off-screen) */}
      <Box
        ref={qrRef}
        sx={{
          display: isGenerating ? "flex" : "none", // Only show during capture
          flexWrap: "wrap",
          gap: "20px",
          padding: "20px",
          background: "#fff", // Ensure white background
          flexDirection: "row",
          maxHeight: "0px",
          maxWidth: "0px",
          overflow: "hidden",
        }}
      >
        {input.map((text, index) => (
          <Box
            key={index}
            sx={{
              padding: "10px",
              border: "1px solid #ddd",
              textAlign: "center",
              backgroundColor: "white",
            }}
          >
            <QRCodeCanvas value={text} size={150} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default QRPdfGenerator;

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
  TablePagination,
  Typography,
} from "@mui/material";

import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { appColors } from "../../AppMeta/appColors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  GetFinalProductSKU,
  UpdateFinalProductSKU,
} from "../../Redux/Actions/productsAction";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import appMeta from "../../AppMeta/appMeta";
import CustomForm from "../../Components/CustomComp/CustomForm";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import CustomFileUploadButton from "../../Components/CustomComp/CustomFileUploadButton";
import UploadIcon from "@mui/icons-material/Upload";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { toast } from "react-toastify";
import CustomDialog from "../../Components/CustomComp/CustomDialog";
import GetQrCodeDialog from "../../Pages/StockKeepingUnit/GetQrCodesDialog";
import {
  GenerateQRWithOutMftOrderId,
  GetGeneratedQR,
} from "../../Redux/Actions/GenerateQrAction";
import { wfsApis } from "../../Redux/Actions/wfsAction";

const SKUProducts = ({
  allProducts,
  setDrawOpenProp,
  setPageNumber,
  setPageSize,
  view,
  setView,
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    console.log("allProducts");
    console.log(allProducts);
  }, [allProducts]);
  const organisationDetails = useSelector((state) => state.organizationData);
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const countryCodes = useSelector(
    (state) => state.userManagementReducer.countryCodes
  );
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  const [organizationSearch, setOrganizationSearch] = React.useState("");
  const [formSelectedStateOption, setFormSelectedStateOption] =
    React.useState("");
  const [allFacility, setAllFacility] = React.useState([]);
  const [openRows, setOpenRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchCountry, setSearchCountry] = React.useState("");
  const [searchState, setSearchState] = React.useState("");
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [formSelectedObject, setFormSelectedObject] = React.useState({});
  const [saleType, setSaleType] = React.useState("refill");
  const [openGenerateQrDialog, setOpenGenerateQrDialog] = React.useState(false);
  const [singleProductGen, setSingleProductGen] = React.useState({});
  const [containerId, setContainerId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [skuQty, setSkuQty] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [cgstPerc, setCgstPerc] = React.useState("");
  const [sgstPerc, setSgctPerc] = React.useState("");
  const [igstPerc, setIgstPerc] = React.useState("");
  const [minBookQty, setMinBookQty] = React.useState("");
  const [maxBookQty, setMaxBookQty] = React.useState("");
  const [deliveryCharges, setDeliveryCharges] = React.useState("");
  const [orderType, setOrderType] = React.useState("");
  const [bookingType, setBookingType] = React.useState("");
  const [mrpR, setMrpR] = React.useState("");
  const [spR, setSpR] = React.useState("");
  const [depositR, setDepositR] = React.useState("");
  const [refundTypeR, setRefundTypeR] = React.useState("");
  const [refundAmountR, setRefundAmountR] = React.useState("");
  const [mrpO, setMrpO] = React.useState("");
  const [spO, setSpO] = React.useState("");
  const [depositO, setDepositO] = React.useState("");
  const [refundTypeO, setRefundTypeO] = React.useState("");
  const [refundAmountO, setRefundAmountO] = React.useState("");
  const [isActive, setIsActive] = React.useState(true);
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [error, setError] = React.useState("");
  const [error1, setError1] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getOrders, setGetOrders] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [openGetQrCodeDialog, setOpenGetQrCodeDialog] = React.useState(false);
  const [allWfs, setAllWfs] = React.useState(wfsList);
  const [searchWfs, setSearchWfs] = React.useState("");
  const [selectedWfs, setSelectedWfs] = React.useState("");
  const [defaultWfsName, setDefaultWfsName] = React.useState("");
  const [singleProductQR, setSingleProductQR] = React.useState({});

  React.useEffect(() => {
    setAllWfs(wfsList);
  }, [wfsList]);

  React.useEffect(() => {
    if (allWfs !== undefined && allWfs !== null && allWfs.length > 0) {
      console.log("----allWfs------");
      setSelectedWfs(allWfs[0].wfs_id);
      setDefaultWfsName(allWfs[0].wfs_name);
    }
  }, [allWfs]);

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  }, []);
  React.useEffect(() => {
    console.log("selectedRow");
    console.log(selectedRow);
    if (selectedRow) {
      setContainerId(selectedRow.product_details.id);
      setName(selectedRow.product_details.name);
      setDescription(selectedRow.product_details.description);
      setCapacity(selectedRow.product_details.capacity);
      setMaterial(selectedRow.product_details.material);
      setLifeSpan(selectedRow.product_details.life_span);
      setAmount(selectedRow.product_details.amount);
      setSkuQty(selectedRow.product_details.sku_qty);
      setImage(selectedRow.product_details.image);
      setHsnCode(selectedRow.product_details.hsn_code);
      setCgstPerc(selectedRow.product_details.cgst_perc);
      setSgctPerc(selectedRow.product_details.sgst_perc);
      setIgstPerc(selectedRow.product_details.igst_perc);
      setMinBookQty(selectedRow.product_details.min_book_qty);
      setMaxBookQty(selectedRow.product_details.max_book_qty);
      // setDeliveryCharges(selectedRow)
      setOrderType(selectedRow.product_details.order_type);
      setBookingType(selectedRow.product_details.booking_type);
      setMrpR(selectedRow.product_details.refill.mrp);
      setSpR(selectedRow.product_details.refill.sp);
      setDepositR(selectedRow.product_details.refill.deposit);
      setRefundTypeR(selectedRow.product_details.refill.refund_amount);
      setRefundAmountR(selectedRow.product_details.refill.refund_amount);
      setMrpO(selectedRow.product_details.one_time_sale.mrp);
      setSpO(selectedRow.product_details.one_time_sale.sp);
      setDepositO(selectedRow.product_details.one_time_sale.deposit);
      setRefundTypeO(selectedRow.product_details.one_time_sale.refund_type);
      setRefundAmountO(selectedRow.product_details.one_time_sale.refund_amount);
      setIsActive(true);
    }
  }, [selectedRow]);

  const handleClickSave = (formData) => {
    // const reqObj = {
    //   container_id: containerId,
    //   name: name,
    //   description: description,
    //   capacity: capacity,
    //   material: material,
    //   life_span: lifeSpan,
    //   amount: amount,
    //   sku_qty: skuQty,
    //   image: image,
    //   hsn_code: hsnCode,
    //   cgst_perc: cgstPerc,
    //   sgst_perc: sgstPerc,
    //   igst_perc: igstPerc,
    //   min_book_qty: minBookQty,
    //   max_book_qty: maxBookQty,
    //   delivery_charges: deliveryCharges,
    //   order_type: orderType,
    //   booking_type: bookingType,
    //   refill: {
    //     mrp: mrpR,
    //     sp: spR,
    //     deposit: depositR,
    //     refund_type: refundTypeR,
    //     refund_amount: refundAmountR,
    //   },
    //   one_time_sale: {
    //     mrp: mrpO,
    //     sp: spO,
    //     deposit: depositO,
    //     refund_type: refundTypeO,
    //     refund_amount: refundAmountO,
    //   },
    //   is_active: true,
    // };
    const reqObj = {
      container_id: selectedRow.product_details.id,
      product_details: {
        name: formData.name,
        description: formData.description,
        container_capacity: formData.capacity,
        container_material: formData.material,
        life_span: formData.lifeSpan,
        amount: refundAmountR,
        sku_qty: [formData.skuQuantity],
        image: formData.image,
        facility_price: { deposit_price: 200 },
        // hsn_code: formData.hsnCode,
        // cgst_perc: parseInt(formData.CGST),
        // sgst_perc: parseInt(formData.SGST),
        // igst_perc: parseInt(formData.IGST),
        min_book_qty: formData.minimumBookQuantity,
        max_book_qty: formData.maximumBookQuantity,
        // delivery_charges: deliveryCharges,
        order_type: formData.orderType,
        booking_type: formData.bookingType,

        weight: formData.weight,
        // bookwater_charges: {
        //   fixed_revenue: formData.fixed_revenue,
        //   monthly_charge: formData.monthly_charge,
        //   per_product_refilling_charge: formData.per_product_refilling_charge,
        //   per_product_charge: formData.per_product_charge,
        // },
        // refill: {
        //   mrp:
        //     formData.bookingType === "1" || "3"
        //       ? parseInt(formData.refilMrp)
        //       : 1,
        //   sp:
        //     formData.bookingType === "1" || "3"
        //       ? parseInt(formData.refilSalePrice)
        //       : 1,
        //   deposit:
        //     formData.bookingType === "1" || "3"
        //       ? parseInt(formData.refilDeposit)
        //       : 1,
        //   refund_type:
        //     formData.bookingType === "1" || "3"
        //       ? parseInt(formData.refilManufactureType)
        //       : 1,
        //   refund_amount:
        //     formData.bookingType === "1"
        //       ? parseInt(formData.refilRefundAmount)
        //       : 1,
        // },
        refill: {
          mrp: formData.refilMrp,
          sp: formData.refilSalePrice,
          deposit:
            formData.bookingType === "1" || "3"
              ? parseInt(formData.refilDeposit)
              : 1,
          refund_type:
            formData.bookingType === "1" || "3"
              ? parseInt(formData.refilManufactureType)
              : 1,
          refund_amount:
            formData.bookingType === "1"
              ? parseInt(formData.refilRefundAmount)
              : 1,
        },
        one_time_sale: {
          mrp:
            formData.bookingType === "2" || "3" ? parseInt(formData.otsMrp) : 1,
          sp:
            formData.bookingType === "2" || "3"
              ? parseInt(formData.otsSalePrice)
              : 1,
          deposit:
            formData.bookingType === "2" || "3"
              ? parseInt(formData.otsDeposit)
              : 1,
          refund_type:
            formData.bookingType === "2" || "3"
              ? parseInt(formData.OtsManufactureType)
              : 1,
          refund_amount:
            formData.bookingType === "2" || "3"
              ? parseInt(formData.otsRefundAmount)
              : 1,
        },
        is_active: true,
      },
      taxes: rows,
    };
    console.log(reqObj);
    dispatch(UpdateFinalProductSKU(reqObj))
      .then((data) => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(GetFinalProductSKU());
          }, 1500);
          setOpenEditDialog(false);
        }
      })
      .catch((error) => {
        console.log("---error----", error);
      });

    // setOpenEditDialog(false);
  };
  const handleToggle = (index) => {
    console.log("OpenRows", openRows);
    console.log("index", index);
    setOpenRows((prevOpenRows) => {
      const isOpen = prevOpenRows.includes(index);
      if (isOpen) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const handleEditClick = (row) => {
    // Handle the edit button click, you can use selectedRow to get the data
    setSelectedRow(row);
    console.log("Edit clicked for row:", row);
    setOpenEditDialog(true);
  };

  const handleChange = (event) => {
    setBookingType(event.target.value);
  };

  const handleItemCgstChange = (event) => {
    setItemCgst(event.target.value);
    if (event.target.value !== setItemSgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must not be greater than two digit number");
    } else {
      setItemCgst(newValue);
      setError("");
    }
  };

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return (
      error !== "" ||
      itemCgst === "" ||
      itemSgst === "" ||
      maxBookQty === "" ||
      minBookQty === ""
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleItemSgstChange = (event) => {
    setItemSgst(event.target.value);
    if (event.target.value !== setItemCgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must be a number less than or equal to 99");
    } else {
      setItemSgst(newValue);
      setError("");
    }
  };

  const handleMinBookQtyChange = (event) => {
    const newMinBookQty = event.target.value;
    setMinBookQty(newMinBookQty);
    if (newMinBookQty > maxBookQty) {
      setError1(
        "Minimum book quantity cannot be greater than maximum book quantity"
      );
    } else {
      setError1("");
    }
  };

  const handleMaxBookQtyChange = (event) => {
    const newMaxBookQty = event.target.value;
    setMaxBookQty(newMaxBookQty);
    if (minBookQty > newMaxBookQty) {
      setError1(
        "Minimum book quantity cannot be greater than maximum book quantity"
      );
    } else {
      setError1("");
    }
  };

  const handleFinalProductFilterClose = (data) => {};
  const handleFinalProductSortClose = (data) => {};
  const handleAddFinalProductClick = () => {
    setDrawOpenProp(true);
  };
  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          ...(organizationSearch !== "" && { org_name: organizationSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };
  const handleInputChange = (index, field, value) => {
    let row = rows[index];
    if (row) {
      rows[index] = {
        ...row,
        country_code: formSelectedObject.country_code,
        state_code: formSelectedStateOption,
        [field]: value,
      };
    } else {
      rows.push({
        country_code: formSelectedObject.country_code,
        state_code: formSelectedStateOption,
        [field]: value,
      });
    }
    // const updatedRows = rows.map((row, i) =>
    //   i === index
    //     ? {
    //         ...row,
    //         country_code: formSelectedObject.country_code,
    //         [field]: value,
    //       }
    //     : row
    // );
    setRows([...rows]);

    // Automatically add a new row when editing the last row
    // if (
    //   index === rows.length - 1 &&
    //   (field === "tax_name" || field === "charges_perc") &&
    //   value !== ""
    // ) {
    //   setRows([...updatedRows]);
    // }
  };

  React.useEffect(() => {
    searchAndUpdateFacility();
  }, [organizationSearch]);

  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;

  const hnadleGenerateQrandBarcode = (data) => {
    console.log("-----data-------", data);
    console.log("-----hnadleGenerateQrandBarcode------", data?.product_details);
    setSingleProductGen(data?.product_details);
    setOpenGenerateQrDialog(true);
  };
  const hnadleGetQrandBarcode = (data) => {
    console.log("----hnadleGetQrandBarcode---", data);
    if (
      data?.product_details?.id !== undefined &&
      data?.product_details?.id !== null
    ) {
      setSingleProductQR(data?.product_details);
    }
    setOpenGetQrCodeDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenGetQrCodeDialog(false);
  };
  const handleSubmitQuantity = (data) => {
    console.log("------data--------", data);

    const reqObj = {
      quantity: parseInt(data.quantity),
      product_material: singleProductGen.material,
      product_capacity: singleProductGen.capacity,
      wfs_id: parseInt(selectedWfs),
      batch_id: "3456",
      // org_id: ,
    };
    console.log("----reqObj-----", reqObj);
    if (
      reqObj.quantity !== "" &&
      reqObj.product_material !== "" &&
      reqObj.product_capacity !== "" &&
      reqObj.wfs_id !== ""
      // reqObj.batch_id !== ""
      // reqObj.org_id !== ""
    ) {
      dispatch(GenerateQRWithOutMftOrderId(reqObj))
        .then((data) => {
          console.log("-----data------", data);
          if (data.status === 200) {
            dispatch(
              GetGeneratedQR({
                // page_number: pageNumber,
                // page_size: pageSize,
                created_at_sort: "DESC",
                is_qr: true,
                // qr_barcode_Id: true,
                // order_id: orderId,
              })
            );
          }
          setOpenGenerateQrDialog(false);
        })
        .catch((error) => {
          console.log("----error-----", error);
        });
      // setTimeout(() => {
      //   dispatch(
      //     GetGeneratedQR({
      //       // page_number: pageNumber,
      //       // page_size: pageSize,
      //       created_at_sort: "DESC",
      //       is_qr: true,
      //       // qr_barcode_Id: true,
      //       // order_id: orderId,
      //     })
      //   );
      // });
    }
    // toast.success("Qr and Barcode generated successfully");
  };
  return (
    <>
      <CustomTable
        tableHead={[
          "Name",
          "Capacity",
          "Material",
          "LifeSpan",
          // "Order Type",
          // "SKU qty",
          "Max Book qty",
          "Min Book qty",
          // "Image Icon",
          "Generate QR Codes",
          "Get QR Codes",
        ]}
        tableDataKey={[
          "product_details.name",
          "product_details.capacity",
          "product_details.material",
          "product_details.life_span",
          // "product_details.order_type",
          // "product_details.sku_qty",
          "product_details.max_book_qty",
          "product_details.min_book_qty",
          // {
          //   type: "component",
          //   // rowClickEnable: true,
          //   component: (props) => {
          //     return props.row.product_details.image !== "" ? (
          //       <CustomFileUploadButton
          //         field={{ name: "downloadImage" }}
          //         hideUploadButton={true}
          //         downloadIcon={<VisibilityRoundedIcon />}
          //         formData={{
          //           downloadImage: "props.row.product_details.image",
          //         }}
          //       />
          //     ) : (
          //       <UploadIcon />
          //     );
          //   },
          // },
          {
            type: "component",
            component: ({ row }) => {
              return (
                <CustomButton
                  label="generate Qr&Barcode"
                  handleClick={() => hnadleGenerateQrandBarcode(row)}
                />
              );
            },
          },
          {
            type: "component",
            component: ({ row }) => {
              return (
                <CustomButton
                  label="get Qr/Barcode"
                  handleClick={() => hnadleGetQrandBarcode(row)}
                />
              );
            },
          },
        ]}
        tableData={allProducts}
        isCollapsable={false}
        collapseTableHead={["tax_name", "charges_perc"]}
        collapseTableDataKey={[
          // "tax_details.tax_name",
          {
            component: ({ row }) => {
              console.log("---collapseTableDataKey---", row);

              return (
                <Table>
                  <TableBody>
                    {row.tax_details.map((data) => {
                      return (
                        <TableRow>
                          <TableCell>{data.tax_name}</TableCell>
                          {/* <TableCell>{data.charges_perc}</TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              );
            },
          },
          {
            component: ({ row }) => {
              console.log("---collapseTableDataKey---", row);

              return (
                <Table>
                  <TableBody>
                    {row.tax_details.map((data) => {
                      return (
                        <TableRow>
                          <TableCell>{data.charges_perc}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              );
            },
          },
        ]}
        // isCollapseViewVertical={true}
        tableRowClickFunction={(index, detail) => handleEditClick(detail)}
        pagination={true}
        // filter={true}
        filterSelectOption={[
          { value: "Roll Name", label: "role_name" },
          { value: "Full Name", label: "fullname" },
        ]}
        handleFilterClose={handleFinalProductFilterClose}
        // sort={true}
        sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
        handleSortClose={handleFinalProductSortClose}
        // addButton={true}
        handleOnClickAddButton={handleAddFinalProductClick}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        tableView={view}
        cardTitle={{ label: "Name", key: "product_details.name" }}
        cardSubTitle={{ label: "Capacity", key: "product_details.capacity" }}
        cardInput={(row) => [
          {
            data: [
              { label: "Material", value: row.product_details.material },
              { label: "Life span", value: row.product_details.life_span },
            ],
          },
          {
            data: [
              { label: "Order Type", value: row.product_details.order_type },
            ],
          },
        ]}
        cardButton={(row) => [
          {
            label: "Edit",
            handleClick: (row) => {
              console.log("cardButton", row);
              handleEditClick(row);
            },
          },
          {
            label: "generate Qr&Barcode",
            handleClick: hnadleGenerateQrandBarcode,
          },
          {
            label: "get Qr/Barcode",
            handleClick: hnadleGetQrandBarcode,
          },
        ]}
        formSearchOptionKey="product_details.name"
        formSearchLabel="Enter Product"
        formInput={(row) => [
          { label: "Name", value: "product_details.name" },
          { label: "Price", value: "product_details.price" },
          { label: "Capacity", value: "product_details.capacity" },
          { label: "Life Span", value: "product_details.life_span" },
          {
            label: "Maximun booking quality",
            value: "product_details.max_book_qty",
          },
          {
            label: "Minimum booking quality",
            value: "product_details.min_book_qty",
          },
        ]}
        formButton={(row) => [
          {
            label: "Edit",
            handleClick: (row) => {
              handleEditClick(row);
            },
          },
        ]}
      />

      {/* Dialog for editing */}
      <CustomDrawer
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        title={"Edit final product"}
      >
        {selectedRow && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              marginTop: "10px",
            }}
          >
            <CustomForm
              handleSubmit={handleClickSave}
              handleClose={() => setOpenEditDialog(false)}
              data={[
                // {
                //   ...(profileRole === "1000" && {
                //     data: [
                //       {
                //         inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                //         optionKey: "org_name",
                //         outputKey: "id",
                //         searchAndUpdateAPI: (searchText) => {
                //           setOrganizationSearch(searchText);
                //         },
                //         input: allFacility || [],
                //         name: "facility",
                //         label: "Enter Facility Name",
                //       },
                //     ],
                //   }),
                // },
                {
                  numberOfColumn: 2,
                  data: [
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Product Name",
                      defaultValue: selectedRow.product_details.name,
                      name: "name",
                      textFieldType: appMeta.textFieldTypes.TEXT,
                      isHintRequired: true,
                      isRequired: true,
                    },
                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "Description",
                    //   defaultValue: selectedRow.product_details.description,
                    //   name: "description",
                    //   textFieldType: appMeta.textFieldTypes.TEXT,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Capacity",
                      defaultValue: selectedRow.product_details.capacity,
                      name: "capacity",
                      textFieldType: appMeta.textFieldTypes.TEXT,
                      isHintRequired: true,
                      isRequired: true,
                    },

                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Material",
                      defaultValue: selectedRow.product_details.material,
                      name: "material",
                      textFieldType: appMeta.textFieldTypes.TEXT,
                      isHintRequired: true,
                      isRequired: true,
                    },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Life Span",
                      defaultValue: selectedRow.product_details.life_span,
                      name: "lifeSpan",
                      textFieldType: appMeta.textFieldTypes.TEXT,
                      isHintRequired: true,
                      isRequired: true,
                    },

                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "SKU Quantity",
                    //   defaultValue: selectedRow.product_details.sku_qty,
                    //   name: "skuQuantity",
                    //   textFieldType: appMeta.textFieldTypes.TEXT,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "HSN Code",
                    //   defaultValue: selectedRow.product_details.hsn_code,
                    //   name: "hsnCode",
                    //   textFieldType: appMeta.textFieldTypes.TEXT,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "CGST(%)",
                    //   defaultValue: selectedRow.product_details.cgst_perc,
                    //   name: "CGST",
                    //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "SGST(%)",
                    //   defaultValue: selectedRow.product_details.sgst_perc,
                    //   name: "SGST",
                    //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "IGST(%)",
                    //   defaultValue: selectedRow.product_details.igst_perc,
                    //   name: "IGST",
                    //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Minimum Book Quantity",
                      defaultValue: selectedRow.product_details.min_book_qty,
                      name: "minimumBookQuantity",
                      textFieldType: appMeta.textFieldTypes.NUMBER,
                      isHintRequired: true,
                      isRequired: true,
                    },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Maximum Book Quantity",
                      defaultValue: selectedRow.product_details.max_book_qty,
                      name: "maximumBookQuantity",
                      textFieldType: appMeta.textFieldTypes.NUMBER,
                      isHintRequired: true,
                      isRequired: true,
                    },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "MRP",
                      defaultvalue: "",
                      name: "refilMrp",
                      textFieldType: appMeta.textFieldTypes.TEXT,
                      defaultValue: selectedRow.product_details.refill.mrp,
                      isHintRequired: true,
                    },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Sale Price",
                      defaultvalue: "",
                      name: "refilSalePrice",
                      textFieldType: appMeta.textFieldTypes.TEXT,
                      defaultValue: selectedRow.product_details.refill.sp,
                      isHintRequired: true,
                    },
                    // {
                    //   inputType: appMeta.inputFieldTypes.SELECT,
                    //   placeHolder: "Order Type",
                    //   defaultValue: selectedRow.product_details.order_type,
                    //   name: "orderType",
                    //   input: [
                    //     {
                    //       menuItem: "Insta",
                    //       value: 100,
                    //     },
                    //     {
                    //       menuItem: "Regular",
                    //       value: 200,
                    //     },
                    //     {
                    //       menuItem: "Subscription",
                    //       value: 300,
                    //     },
                    //     {
                    //       menuItem: "All types",
                    //       value: 400,
                    //     },
                    //   ],
                    // },
                    // Delivery charges
                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "Delivery Charge",
                    //   defaultValue: selectedRow.product_details.delivery_charge,
                    //   name: "deliveryCharge",
                    //   textFieldType: appMeta.textFieldTypes.TEXT,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    // {
                    //   inputType: appMeta.inputFieldTypes.INPUT,
                    //   placeHolder: "Product Weight in grms",
                    //   defaultValue: "",
                    //   name: "weight",
                    //   textFieldType: appMeta.textFieldTypes.TEXT,
                    //   isHintRequired: true,
                    //   isRequired: true,
                    // },
                    // {
                    //   component: () => {
                    //     return (
                    //       <CustomAutoComplete
                    //         field={{
                    //           inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                    //           optionKey: "country",
                    //           outputKey: "country_code",
                    //           searchAndUpdateAPI: (searchText) => {
                    //             setSearchCountry(searchText);
                    //           },
                    //           input: countryCodes,
                    //           name: "country_code",
                    //           label: "Select Country",
                    //         }}
                    //         handleInputChange={(name, selectedOption, obj) => {
                    //           setFormSelectedObject(obj);
                    //         }}
                    //       />
                    //     );
                    //   },
                    // },
                    // {
                    //   component: () => {
                    //     return (
                    //       <CustomAutoComplete
                    //         field={{
                    //           inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                    //           optionKey: "state_name",
                    //           outputKey: "state_code",
                    //           searchAndUpdateAPI: (searchText) => {
                    //             setSearchState(searchText);
                    //           },
                    //           input: formSelectedObject?.state,

                    //           name: "state_code",
                    //           label: "Select State",
                    //         }}
                    //         handleInputChange={(name, selectedOption) => {
                    //           setFormSelectedStateOption(selectedOption);
                    //         }}
                    //       />
                    //     );
                    //   },
                    // },
                  ],
                },
                // {
                //   title: "",
                //   data: [
                //     {
                //       // default
                //       inputType: appMeta.inputFieldTypes.RADIO,
                //       placeHolder: "Booking type",
                //       name: "bookingType",
                //       defaultValue: selectedRow.product_details.booking_type,
                //       input: [
                //         {
                //           name: "bookingType",
                //           value: "1",
                //           label: "Refill",
                //         },
                //         {
                //           name: "bookingType",
                //           value: "2",
                //           label: "One Time Sale",
                //         },
                //         {
                //           name: "bookingType",
                //           value: "3",
                //           label: "Both",
                //         },
                //       ],
                //     },
                //   ],
                // },
                // {
                //   conditionallyRenderData: {
                //     name: "bookingType",
                //     value: ["1", "3"],
                //   },
                //   numberOfColumn: 2,
                //   title: "Refill",
                //   data: [
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "MRP",
                //       defaultValue: selectedRow.product_details.refill.mrp,
                //       name: "refilMrp",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Sale Price",
                //       defaultValue: selectedRow.product_details.refill.sp,
                //       name: "refilSalePrice",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Deposit",
                //       defaultValue: selectedRow.product_details.refill.deposit,
                //       name: "refilDeposit",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     // Check in edit form
                //     {
                //       // default

                //       inputType: appMeta.inputFieldTypes.SELECT,
                //       placeHolder: "Refund Type",
                //       name: "refilManufactureType",
                //       defaultValue:
                //         selectedRow.product_details.refill.refund_type,
                //       input: [
                //         {
                //           menuItem: "Insta",
                //           value: 100,
                //         },
                //         {
                //           menuItem: "Regualar",
                //           value: 200,
                //         },
                //         {
                //           menuItem: "Subscription",
                //           value: 300,
                //         },
                //         {
                //           menuItem: "All types",
                //           value: 500,
                //         },
                //       ],
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Refund amount",
                //       defaultValue:
                //         selectedRow.product_details.refill.refund_amount,
                //       name: "refilRefundAmount",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //   ],
                // },
                // {
                //   conditionallyRenderData: {
                //     name: "bookingType",
                //     value: ["2", "3"],
                //   },
                //   title: "One time sale",
                //   numberOfColumn: 2,
                //   data: [
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "MRP",
                //       defaultValue:
                //         selectedRow.product_details.one_time_sale.mrp,
                //       name: "otsMrp",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Sale Price",
                //       defaultValue:
                //         selectedRow.product_details.one_time_sale.sp,
                //       name: "otsSalePrice",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Deposit",
                //       defaultValue:
                //         selectedRow.product_details.one_time_sale.deposit,
                //       name: "otsDeposit",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       // default
                //       inputType: appMeta.inputFieldTypes.SELECT,
                //       placeHolder: "Refund Type",
                //       defaultValue:
                //         selectedRow.product_details.one_time_sale.refund_type,
                //       name: "OtsManufactureType",
                //       input: [
                //         {
                //           menuItem: "Insta",
                //           value: 100,
                //         },
                //         {
                //           menuItem: "Regualar",
                //           value: 200,
                //         },
                //         {
                //           menuItem: "Subscription",
                //           value: 300,
                //         },
                //         {
                //           menuItem: "All types",
                //           value: 500,
                //         },
                //       ],
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Refund amount",
                //       defaultValue:
                //         selectedRow.product_details.one_time_sale.refund_amount,
                //       name: "otsRefundAmount",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //   ],
                // },
                // {
                //   title: "Add Taxes",
                //   data: [
                //     {
                //       component: () => {
                //         return (
                //           <TableContainer component={Paper}>
                //             <Table sx={{ border: "1px solid black" }}>
                //               <TableHead>
                //                 <TableRow>

                //                   <TableCell
                //                     sx={{
                //                       fontWeight: 800,
                //                       border: "1px solid black",
                //                     }}
                //                   >
                //                     Tax Name
                //                   </TableCell>
                //                   <TableCell
                //                     sx={{
                //                       fontWeight: 800,
                //                       border: "1px solid black",
                //                     }}
                //                   >
                //                     Tax Value
                //                   </TableCell>
                //                 </TableRow>
                //               </TableHead>
                //               <TableBody>
                //                 {[...rows, { tax_name: "" }].map(
                //                   (row, index) => (
                //                     <TableRow key={index}>
                //                       <TableCell
                //                         sx={{ border: "1px solid black" }}
                //                       >
                //                         <TextField
                //                           value={row.key}
                //                           onChange={(e) =>
                //                             handleInputChange(
                //                               index,
                //                               "tax_name",
                //                               e.target.value
                //                             )
                //                           }
                //                           variant="outlined"
                //                           size="small"
                //                         />
                //                       </TableCell>
                //                       <TableCell
                //                         sx={{ border: "1px solid black" }}
                //                       >
                //                         <TextField
                //                           value={row.value}
                //                           onChange={(e) =>
                //                             handleInputChange(
                //                               index,
                //                               "charges_perc",
                //                               e.target.value
                //                             )
                //                           }
                //                           variant="outlined"
                //                           size="small"
                //                         />
                //                       </TableCell>
                //                     </TableRow>
                //                   )
                //                 )}
                //               </TableBody>
                //             </Table>
                //           </TableContainer>
                //         );
                //       },
                //     },
                //   ],
                // },
                // {
                //   title: "Book Water Charges",
                //   numberOfColumn: 2,
                //   data: [
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Fixed Revenue",
                //       defaultvalue: "",
                //       name: "fixed_revenue",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Monthly Charge",
                //       defaultvalue: "",
                //       name: "monthly_charge",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Per Refilling Charge",
                //       defaultvalue: "",
                //       name: "per_product_refilling_charge",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //     {
                //       inputType: appMeta.inputFieldTypes.INPUT,
                //       placeHolder: "Product Charge",
                //       defaultvalue: "",
                //       name: "per_product_charge",
                //       textFieldType: appMeta.textFieldTypes.TEXT,
                //       isHintRequired: true,
                //       // isRequired: true,
                //     },
                //   ],
                // },
                {
                  title: "Upload",
                  data: [
                    {
                      inputType: appMeta.inputFieldTypes.FILE,
                      name: "image",
                      placeHolder: "Product Image",
                      defaultValue: selectedRow.product_details.image,
                    },
                  ],
                },
              ]}
            />
            {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="Name"
                  fullWidth
                  value={name}
                  size="small"
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  label="Capacity"
                  fullWidth
                  value={capacity}
                  size="small"
                  onChange={(e) => setCapacity(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="Material"
                  fullWidth
                  value={material}
                  size="small"
                  onChange={(e) => setMaterial(e.target.value)}
                />
                <TextField
                  label="Life Span"
                  fullWidth
                  value={lifeSpan}
                  size="small"
                  onChange={(e) => setLifeSpan(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="SKU Quantity"
                  fullWidth
                  value={skuQty[0]}
                  size="small"
                  type="number"
                  onChange={(e) => setSkuQty(e.target.value)}
                />
                <TextField
                  label="HSN Code"
                  fullWidth
                  value={hsnCode}
                  size="small"
                  onChange={(e) => setHsnCode(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="CGST %"
                  fullWidth
                  size="small"
                  value={cgstPerc}
                  onChange={handleItemCgstChange}
                  error={error !== ""}
                  helperText={error}
                  type="number"
                />
                <TextField
                  label="SGST %"
                  fullWidth
                  size="small"
                  value={sgstPerc}
                  onChange={handleItemSgstChange}
                  error={error !== ""}
                  helperText={error}
                  type="number"
                  inputProps={{ maxlength: 2 }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="IGST %"
                  fullWidth
                  value={igstPerc}
                  size="small"
                />
                <TextField
                  label="Min Book Quantity"
                  fullWidth
                  size="small"
                  value={minBookQty}
                  error={error1 !== ""}
                  helperText={error1}
                  onChange={handleMinBookQtyChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="Max Book Quantity"
                  fullWidth
                  value={maxBookQty}
                  size="small"
                  type="number"
                  error={error1 !== ""}
                  helperText={error1}
                  onChange={handleMaxBookQtyChange}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Order Type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={orderType}
                      label="Order Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={100}>Insta</MenuItem>
                      <MenuItem value={200}>Regualar</MenuItem>
                      <MenuItem value={300}>Subscription</MenuItem>
                      <MenuItem value={400}>All types</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="Delivery charges"
                  fullWidth
                  defaultValue={selectedRow}
                  size="small"
                />
                <TextField
                  label="Description"
                  fullWidth
                  value={description}
                  size="small"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={bookingType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Refill"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="One Time Sale"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <TextField
                  label="MRP"
                  type="number"
                  fullWidth
                  value={mrpR}
                  size="small"
                  onChange={(e) => setMrpR(e.target.value)}
                />
                <TextField
                  label="Sale price"
                  fullWidth
                  value={spR}
                  size="small"
                  type="number"
                  onChange={(e) => setSpR(e.target.value)}
                />
                <TextField
                  label="Deposit"
                  fullWidth
                  value={depositR}
                  size="small"
                  type="number"
                  onChange={(e) => setDepositR(e.target.value)}
                />
                <TextField
                  label="Order Type"
                  fullWidth
                  value={refundTypeR}
                  size="small"
                  type="number"
                  onChange={(e) => setRefundTypeR(e.target.value)}
                />
                <TextField
                  label="Refund Amount"
                  fullWidth
                  value={refundAmountR}
                  size="small"
                  type="number"
                  onChange={(e) => setRefundAmountR(e.target.value)}
                />
              </Box> */}

            {/* Add other text fields as needed */}
            {/* <Box sx={{ display: "flex", gap: "2rem" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClickSave}
                  sx={appStyle.general.button.blueButton}
                >
                  Save
                </Button>
                <Button
                  onClick={() => setOpenEditDialog(false)}
                  sx={appStyle.general.button.redButton}
                >
                  Close
                </Button>
              </Box> */}
          </Box>
        )}
      </CustomDrawer>
      {/* <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent></DialogContent>
      </Dialog> */}
      {openGenerateQrDialog && (
        <CustomDialog open={openGenerateQrDialog} title="Enter Quantity">
          <CustomForm
            // hideCloseButton={true}
            handleClose={() => {
              setOpenGenerateQrDialog(false);
            }}
            handleSubmit={handleSubmitQuantity}
            data={[
              {
                data: [
                  {
                    ...(profileRole !== "2034" && {
                      inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                      optionKey: "wfs_name",
                      outputKey: "wfs_id",
                      defaultValue: defaultWfsName,
                      onChange: (name, value) => {
                        console.log("---Value--", value);
                        setSelectedWfs(value);
                      },
                      searchAndUpdateAPI: (searchText) => {
                        setSearchWfs(searchText);
                      },
                      input: allWfs || [],
                      name: "wfs",
                      label: "Enter WFS Name",
                    }),
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Enter Quantity",
                    defaultValue: "",
                    name: "quantity",
                    textFieldType: appMeta.textFieldTypes.NUMBER,
                    isHintRequired: true,
                    isRequired: true,
                  },
                ],
              },
            ]}
          />
        </CustomDialog>
      )}
      {openGetQrCodeDialog && (
        <GetQrCodeDialog
          openGetQrCodeDialog={openGetQrCodeDialog}
          singleProductQR={singleProductQR}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};

export default SKUProducts;

import React from "react";
import CustomDrawer from "../../../Components/CustomComp/CustomDrawer";
import { Box } from "@mui/material";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import appMeta from "../../../AppMeta/appMeta";
import { useSelector } from "react-redux";

function SingleWfsInchargeDialog(props) {
  const countryCodes = useSelector(
    (state) => state.userManagementReducer.countryCodes
  );
  const [incharageDetails, setIncharageDetails] = React.useState(
    props.singleWFSInchargeDetail.wfs_incharge_details !== undefined &&
      props.singleWFSInchargeDetail.wfs_incharge_details !== null &&
      props.singleWFSInchargeDetail.wfs_incharge_details.length &&
      props.singleWFSInchargeDetail?.wfs_incharge_details[0]
  );

  //   React.useEffect(() => {
  //     if (
  //       props.singleWFSInchargeDetail.wfs_incharge_details !== undefined &&
  //       props.singleWFSInchargeDetail.wfs_incharge_details !== null &&
  //       props.singleWFSInchargeDetail.wfs_incharge_details.length
  //     ) {
  //       setIncharageDetails(
  //         props.singleWFSInchargeDetail?.wfs_incharge_details[0]
  //       );
  //     }
  //   }, [props.singleWFSInchargeDetail?.wfs_incharge_details]);
  console.log(
    "------singleWFSInchargeDetail---------",
    props.singleWFSInchargeDetail
  );
  console.log("------incharageDetails----------", incharageDetails);
  const handleInchargedWfsSubmit = (data) => {
    console.log("------data----------", data);
    props.handleInchargedWfsSubmit(data);
  };
  const handleCloseIncharge = () => {
    props.handleCloseIncharge();
  };
  return (
    <CustomDrawer
      open={props.openSingleWfsInchargeDialog}
      title="Incharge Details"
    >
      <CustomForm
        handleClose={handleCloseIncharge}
        handleSubmit={handleInchargedWfsSubmit}
        data={[
          {
            title: "WFS incharge details",
            numberOfColumn: 2,
            data: [
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Name",
                defaultValue: incharageDetails?.fullname,
                name: "wfsInchargeName",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.SELECT,
                placeHolder: "Time Zone",
                name: "phone_code",
                input: countryCodes?.map((data) => {
                  return {
                    value: `${data.time_zone}-${data.phone_code}-${data.country_code}`,
                    menuItem: `${data.phone_code}-${data.time_zone}`,
                  };
                }),
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Phone",
                defaultValue: incharageDetails?.phone,
                name: "wfsInchargePhone",
                textFieldType: appMeta.textFieldTypes.PHONE,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Email",
                defaultValue: incharageDetails?.email,
                name: "wfsInchargeEmail",
                textFieldType: appMeta.textFieldTypes.EMAIL,
                isHintRequired: true,
                isRequired: true,
              },
            ],
          },
        ]}
      />
    </CustomDrawer>
  );
}

export default SingleWfsInchargeDialog;

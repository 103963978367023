import React from "react";
import Home from "../Home/Home";
import { Box } from "@mui/material";

function IOTDataDemo() {
  return (
    <Box>
      <Home />
    </Box>
  );
}

export default IOTDataDemo;

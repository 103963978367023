import { Box } from "@mui/material";
import React from "react";
import AccessManagementTabs1 from "../AccessManagement/AccessManagementTabs1";

function UsersDemo() {
  return (
    <Box>
      <AccessManagementTabs1 />
    </Box>
  );
}

export default UsersDemo;

import { ArrowBack, Call, Place } from "@mui/icons-material";
import { Box, Button, Chip, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import DeliveriesEnterOtpDialog from "./DeliveriesEnterOtpDialog";
import { toast } from "react-toastify";
import ScanningDialog from "../../Components/CustomComp/ScanningDialog";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ImageViewer from "../../Components/CustomComp/imageLoader";

function SingleOrderDetails() {
  const dispatch = useDispatch();

  const listOfOrders = useSelector(
    (state) => state.userManagementReducer.distributor_consumer_orders
  );
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [orderData, setOrderData] = React.useState({});
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [scanOrderId, setScanOrderId] = React.useState(null);
  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [scannedCount, setScannedCount] = React.useState(0);
  const [productData, setProductData] = React.useState({});
  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes"))
  );
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");
  React.useEffect(() => {
    fetchData();
    handleSubmitOtp();
  }, []);

  const fetchData = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_consumer_orders,
        params: { order_id: orderId },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    if (Object.keys(requestObject).length) {
      //@Removed/console.log("request Object in parent");
      //@Removed/console.log(requestObject);
      console.log("setRequestObject", requestObject);
      console.log(requestObject?.codes?.length);
      if (requestObject?.codes?.length) {
        console.log("----productData-----", productData);
        if (
          productData.is_inscan_completed === false &&
          productData.is_outscan_completed === false
        ) {
          dispatch(
            HandleApiActions({
              ...userManagementApis.put_in_scan,
              params: {
                order_id: requestObject.order_id,
                qr_codes: requestObject.codes,
              },
              show_toast: true,
            })
          );
        } else if (
          productData.is_inscan_completed === true &&
          productData.is_outscan_completed === false
        ) {
          dispatch(
            HandleApiActions({
              ...userManagementApis.put_out_scan,
              params: {
                order_id: requestObject.order_id,
                qr_codes: requestObject.codes,
              },
              show_toast: true,
            })
          );
        }
        // setTimeout(() => {
        //   fetchBatch();
        // }, 1000);
        // setOpenProductCapColorDialog(true);
      }
      setTimeout(() => {
        // dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
        // dispatch(GetCreateBatch({ sort_created_at: "DESC" }));

        localStorage.removeItem("current_scanned_codes");
        setCurrentScanned({});
        setScannedCount(0);
        fetchData();
        setOpenScanningDialog(false);
        setRequestObject({});
      }, 1500);
    }
  }, [requestObject]);
  const handleBack = () => {
    window.history.back();
  };

  const handleOpenEnterOtpDialog = (props) => {
    console.log("----orderData-props----", props);
    setOrderData(props);
    setOpenEnterOtpDialog(true);
  };
  const handleSubmitOtp = () => {
    console.log("----orderData-----", orderData);
    if (listOfOrders[0]?.order_status === "200") {
      dispatch(
        HandleApiActions({
          ...userManagementApis.put_verify_otp_order,
          params: {
            otp: enterOtpTextfied || listOfOrders[0]?.otp,
            order_id: listOfOrders[0]?.order_id,
          },
          show_toast: false,
        })
      );
      setTimeout(() => {
        fetchData();
        setOpenEnterOtpDialog(false);
      }, 1000);
    }
  };
  const handleCloseDialog = () => {
    setOpenEnterOtpDialog(false);
  };

  const handlePhone = (phone_number) => {
    console.log("--phone_number----", phone_number);
    const telUrl = `tel:${phone_number}`;
    window.location.href = telUrl;
  };
  const handleLocation = (props) => {
    console.log("----props-------", props);
    if (
      props.data?.latitude !== undefined &&
      props.data?.latitude !== null &&
      props.data?.longitude !== undefined &&
      props.data?.longitude !== null &&
      parseFloat(props.data?.latitude) > 0 &&
      parseFloat(props.data?.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props?.data?.latitude},${props?.data?.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handleinscanopen = (data) => {
    // setOpenLinkDialog(true);
    console.log("----data--------", data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.return_quantity - data?.inscaned_quantity);
    setProductData(data);
  };
  const handleoutscanopen = (data) => {
    console.log("----data--------", data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.quantity - data?.outscaned_quantity);
    setProductData(data);
  };

  const handleCompleteOrder = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.post_complete_consumer_order,
        params: {
          order_id: listOfOrders[0]?.order_id,
        },
        show_toast: true,
      })
    );
    setTimeout(() => {
      fetchData();
    }, 1500);
  };

  return (
    <Box>
      {" "}
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: "7px",
          bgcolor: "#074276;",
          //   borderTopLeftRadius: "10px",
          //   borderTopRightRadius: "10px",
        }}
        // divider={<Divider orientation="vertical" flexItem />}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            "&:hover": { cursor: "pointer" },
          }}
        >
          <ArrowBack
            onClick={() => handleBack()}
            sx={{
              fontSize: "25px",
              bgcolor: "white",
              borderRadius: "5px",
              color: "black",
            }}
          />
        </Box>
        <Chip
          label={`Order ID : ${listOfOrders[0]?.order_id}`}
          // onClick={handleViewDetails}
          // sx={{
          //   bgcolor:
          //     props.data.payment_type === 300
          //       ? "#6d147d"
          //       : props.data.packaged_drinking_water.order_type === 700
          //       ? "#EE4190"
          //       : props.data.packaged_drinking_water.is_subscribed_order ===
          //         true
          //       ? "#3BC2C0"
          //       : "#924EEF",
          //   color: "white",
          // }}
          sx={{
            bgcolor: "white",
            color: "black",
            fontWeight: 800,
            fontSize: "14px",
          }}
        />

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            "&:hover": { cursor: "pointer" },
          }}
        >
          <Call
            onClick={() => handlePhone(listOfOrders[0]?.contact_phone)}
            sx={{
              fontSize: "25px",
              bgcolor: "white",
              borderRadius: "5px",
              color: "green",
            }}
          />
          <Place
            onClick={() => handleLocation(listOfOrders[0])}
            color="primary"
            sx={{
              fontSize: "25px",
              marginLeft: "15px",
              color: "red",
              bgcolor: "white",
              borderRadius: "5px",
              "&:hover": { cursor: "pointer" },
            }}
          />
        </Box>
      </Stack>
      <Box sx={{ p: "0px 10px 0px 10px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // padding: "5px 5px 0px 5px",
            borderBottom: "2px dashed gray",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>Contact Name :</Typography>
          <Typography>{listOfOrders[0]?.contact_name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // padding: "5px 5px 0px 5px",
            borderBottom: "2px dashed gray",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>Contact Number :</Typography>
          <Typography>{listOfOrders[0]?.contact_phone}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // padding: "5px 5px 0px 5px",
            borderBottom: "2px dashed gray",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>status :</Typography>
          <Typography>
            {parseInt(listOfOrders[0]?.order_status) === 200
              ? "NOT STARTED"
              : parseInt(listOfOrders[0]?.order_status) === 201
              ? "STARTED"
              : parseInt(listOfOrders[0]?.order_status) === 400
              ? "CANCELLED"
              : "COMPLETED"}
          </Typography>
        </Box>
        <Box sx={{ borderBottom: "2px dashed gray" }}>
          <Typography sx={{ fontSize: "14px" }}>Contact Address :</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Typography sx={{ display: "flex", flexWrap: "wrap" }}>
              {listOfOrders[0]?.address_line1}
            </Typography>
            ,
            <Typography sx={{ display: "flex", flexWrap: "wrap" }}>
              {listOfOrders[0]?.address_line2}
            </Typography>
            ,
            <Typography sx={{ display: "flex", flexWrap: "wrap" }}>
              {listOfOrders[0]?.address_line3}
            </Typography>
            ,
            <Typography sx={{ display: "flex", flexWrap: "wrap" }}>
              {listOfOrders[0]?.city}
            </Typography>
            ,
            <Typography sx={{ display: "flex", flexWrap: "wrap" }}>
              {listOfOrders[0]?.country}
            </Typography>{" "}
            -
            <Typography sx={{ display: "flex", flexWrap: "wrap" }}>
              {listOfOrders[0]?.pincode}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box> </Box> */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button disabled></Button>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 800,
            fontSize: "18px",
            mt: "4px",
          }}
        >
          PRODUCTS
        </Typography>
        {parseInt(listOfOrders[0]?.order_status) === 200 ? (
          <CustomButton
            label="Enter Otp"
            handleClick={() => handleOpenEnterOtpDialog(listOfOrders[0])}
          />
        ) : parseInt(listOfOrders[0]?.order_status) === 201 &&
          listOfOrders[0]?.is_all_scan_completed === true ? (
          <CustomButton
            label="Complete order"
            handleClick={handleCompleteOrder}
          />
        ) : (
          <Button disabled></Button>
        )}
      </Box>
      <Box>
        {listOfOrders[0]?.products?.map((data) => {
          return (
            <Paper elevation={10} sx={{ m: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  bgcolor: "#074276;",
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 800,
                    bgcolor: "white",
                    padding: "0px 5px 0px 5px",
                    borderRadius: "10px",
                  }}
                >
                  {data.name}
                </Typography> */}
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 800,
                    bgcolor: "white",
                    padding: "0px 5px 0px 5px",
                    borderRadius: "10px",
                    color: "black",
                  }}
                >
                  ({data.capacity}-{data.material})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box sx={{ mt: "10px" }}>
                  {" "}
                  <ImageViewer filename={data.image} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    pb: "0px",
                    gap: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                    Empty : {data.inscaned_quantity}/{data.return_quantity || 0}
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                    Filled : {data.outscaned_quantity}/{data.quantity || 0}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {data.is_inscan_completed === false &&
                data.is_outscan_completed === false ? (
                  <CustomButton
                    label="in-scan"
                    handleClick={() => handleinscanopen(data)}
                  />
                ) : data.is_inscan_completed === true &&
                  data.is_outscan_completed === false ? (
                  <CustomButton
                    label="out-scan"
                    handleClick={() => handleoutscanopen(data)}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Paper>
          );
        })}
      </Box>
      <DeliveriesEnterOtpDialog
        openEnterOtpDialog={openEnterOtpDialog}
        enterOtpTextfied={enterOtpTextfied}
        otpValidationError={otpValidationError}
        setOtpValidationError={setOtpValidationError}
        setEnterOtpTextfied={setEnterOtpTextfied}
        handleSubmitOTP={handleSubmitOtp}
        handleClose={handleCloseDialog}
      />
      {openScanningDialog && (
        <ScanningDialog
          setOpenScanningDialog={setOpenScanningDialog}
          openScanningDialog={openScanningDialog}
          scannedQrBarcode={scannedQrBarcode}
          setScannedQrBarcode={setScannedQrBarcode}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          scanQtyMax={scanQtyMax}
          scanOrderId={listOfOrders[0]?.order_id}
          scannedCount={scannedCount}
          setScannedCount={setScannedCount}
          setRequestObject={setRequestObject}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          isBatch={true}
          validateExpired={validateExpired}
          setSubmitBtnFlg={setSubmitBtnFlg}
        />
      )}
    </Box>
  );
}

export default SingleOrderDetails;

import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import IOTHomePage from "./IOTHomePage";
import RecycleHomePage from "./RecycleHomePage";
import { useTheme } from "@emotion/react";
import { tokens } from "../../AppMeta/theme";
import CustomTab from "../../Components/CustomComp/CustomTab/CustomTab";
import IotDevicesMain from "../FillingStation/IotDevicesMain";
import { AddLinesFacilty } from "../FillingStation/LinkingAndAddingFacilitiesAndIOTDevices/AddLinesFacilty";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import appData, { tabValues } from "../../AppMeta/appData";
import userManagementApis from "../../Redux/Actions/userManagement";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import { LinkIOTtoFacilityFillingLines } from "../FillingStation/LinkingAndAddingFacilitiesAndIOTDevices/LinkIOTtoFacilityFillingLines";

function Home() {
  const dispatch = useDispatch();
  const organisationDetails = useSelector((state) => state.organizationData);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const [allWfs, setAllWfs] = React.useState(wfsDetails.wfsLineId);
  const [allFacilities, setAllFacilities] = React.useState(
    organisationDetails.organizationList
  );

  React.useEffect(() => {
    searchAndUpdateFacility();
    dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          // ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  }, []);

  //Organisation Deatils
  React.useEffect(() => {
    console.log("organisationDetails");
    console.log(organisationDetails);
    setAllFacilities(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    setAllWfs(wfsDetails.wfsLineId);
  }, [wfsDetails]);

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          // ...(organizationSearch !== "" && { org_name: organizationSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  //hooks
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState("iot");
  const [addValue, setAddValue] = React.useState(0);

  const handleChangeTabs1 = (event) => {
    setAddValue(event.target.value);
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <CustomTab
        bgColor="#d4e2ea"
        defaultValue="recycle"
        value={value}
        setValue={setValue}
        input={[
          // {
          //   value: "recycle",
          //   label: "Recycle",
          //   component: <RecycleHomePage />,
          // },
          {
            value: "iot",
            label: "Dashboard",
            component: <IOTHomePage />,
          },
          {
            value: "onboard_iot",
            label: "Onboard IoT",
            component: <IotDevicesMain />,
          },
          {
            value: "onboard_lines",
            label: "Create Lines",
            component: (
              // <AddLinesFacilty allFacilities={allFacilities} allWfs={allWfs} />
              <Box sx={{ marginTop: "1rem" }}>
                {/* <Box
                  sx={{ display: "flex", justifyContent: "center", mb: "20px" }}
                >
                  <CustomSelect
                    name={"Filling station"}
                    input={appData.fillingStationSubSelector}
                    handleChange={handleChangeTabs1}
                    defaultValue={addValue}
                  />
                </Box> */}
                {addValue === tabValues.addLinesToFacility && (
                  <AddLinesFacilty
                    allFacilities={allFacilities}
                    allWfs={allWfs}
                  />
                )}

                {/* {addValue === 1 && <AddIOTDevices />} */}

                {addValue === tabValues.linkIotToFacility && (
                  <LinkIOTtoFacilityFillingLines
                    allFacilities={allFacilities}
                  />
                )}
              </Box>
            ),
          },
        ]}
      />
    </Box>
  );
}

export default Home;

import { useEffect, useRef, useState } from "react";

const useWebSocket = (url) => {
  const socketRef = useRef(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!url) return;

    console.log("🛠 Initializing WebSocket...", url);
    const ws = new WebSocket(url);
    socketRef.current = ws; // Storing WebSocket instance

    ws.onopen = () => {
      console.log("✅ WebSocket Connected");
    };

    ws.onmessage = (event) => {
      console.log("📩 WebSocket Message Received:", event.data);
      setMessages((prevMessages) => [...prevMessages, JSON.parse(event.data)]);
    };

    ws.onerror = (error) => {
      console.error("❌ WebSocket Error:", error);
    };

    ws.onclose = (event) => {
      console.log("❌ WebSocket Disconnected from", url);
      console.error("❌ WebSocket Closed:", event.code, event.reason);
      socketRef.current = null; // Reset reference
    };

    // ws.onclose = (event) => {
    //   console.error("❌ WebSocket Closed:", event.code, event.reason);
    // };

    return () => {
      console.log("🔌 Closing WebSocket Connection...");
      ws.close();
    };
  }, [url]);

  // ✅ Function to send messages (using socketRef.current)
  const sendMessage = (message) => {
    console.log("🔍 Checking WebSocket state:", socketRef.current?.readyState);

    if (socketRef.current) {
      switch (socketRef.current.readyState) {
        case WebSocket.CONNECTING:
          console.log("⏳ WebSocket is still CONNECTING...");
          break;
        case WebSocket.OPEN:
          console.log("✅ WebSocket is OPEN. Sending message...");
          socketRef.current.send(message);
          break;
        case WebSocket.CLOSING:
          console.log("⚠️ WebSocket is CLOSING. Cannot send message.");
          break;
        case WebSocket.CLOSED:
          console.log("❌ WebSocket is CLOSED. Reconnecting...");
          break;
        default:
          console.log(
            "🤷‍♂️ Unknown WebSocket state:",
            socketRef.current.readyState
          );
      }
    } else {
      console.log("⚠️ No WebSocket instance found!");
    }
  };

  return { messages, sendMessage };
};

export default useWebSocket;

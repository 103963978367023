import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useTheme } from "@emotion/react";

function CustomAccordion({ title, body, index, expanded, setExpanded }) {
  const theme = useTheme();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(expanded === panel ? false : panel);
  };
  return (
    <Accordion
      onChange={handleChange(index)}
      expanded={expanded === index}
      sx={{
        background: theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
        border: `1px solid ${
          theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
        }`,
        boxShadow:
          theme.palette.mode === "dark"
            ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
            : "0px 4px 10px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          background: theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordion;

import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import BookWaterIcon from "../../Assests/FOM/bw_logo.png";
import {
  AccountCircle,
  Handshake,
  Inventory,
  LocalShipping,
  ProductionQuantityLimits,
  Recycling,
  ShoppingCartCheckout,
  Summarize,
  WifiProtectedSetup,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

export const sideBarData = [
  {
    title: "HOME",
    path: "/dashboard/home",
    icon: <HomeTwoToneIcon />,
  },
  {
    title: "IOT",
    path: "/dashboard/iot_device_data",
    icon: <HomeTwoToneIcon />,
  },
  {
    title: "PRODUCTS",
    path: "/dashboard/products",
    icon: <ProductionQuantityLimits />,
  },
  {
    title: "QR/BARCODES",
    path: "/dashboard/codesManagement",
    icon: <QrCodeRoundedIcon />,
  },

  {
    title: "FILLING",
    path: "/dashboard/filling_process",
    icon: <CorporateFareRoundedIcon />,
  },
  {
    title: "DELIVERY",
    path: "/dashboard/delivery_process",
    icon: <LocalShipping />,
  },
  {
    title: "USERS",
    path: "/dashboard/users_process",
    icon: <ManageAccountsRoundedIcon />,
  },
  {
    title: "PROCESS FLOW",
    path: "/dashboard/app_process",
    icon: <WifiProtectedSetup />,
  },
  // {
  //   title: "SKU",
  //   path: "/dashboard/products",
  //   icon: <Inventory />,
  // },

  {
    title: "USERS MANAGEMENT",
    path: "/dashboard/user-management",
    icon: <ManageAccountsRoundedIcon />,
  },
  {
    title: "ONBOARD",
    path: "/dashboard/onboard_users",
    icon: <Handshake />,
  },

  {
    title: "ALL ORDERS",
    path: "/dashboard/AdminAllOrders",
    icon: <AddShoppingCartRoundedIcon />,
  },
  {
    title: "PROFILE",
    path: "/dashboard/profile",
    icon: <AccountCircle />,
  },
  {
    title: "BATCH DETAILS",
    path: "/dashboard/water-station",
    icon: <CorporateFareRoundedIcon />,
  },
  {
    title: "DISTRIBUTOR ORDERS",
    path: "/dashboard/distributor_placed_orders",
    icon: <ShoppingCartCheckout />,
  },

  // {
  //   title: "FACILITY ORDERS",
  //   path: "/dashboard/facility-order-management",
  //   icon: <ListAltRoundedIcon />,
  // },
  // {
  //   title: "ORGANIZATION ORDERS",
  //   path: "/dashboard/organisation-order-management",
  //   icon: <ListAltRoundedIcon />,
  // },
  // {
  //   title: "All Orders(Organization)",
  //   path: "/dashboard/organization_orders_List",
  //   icon: <ListAltRoundedIcon />,
  // },
  // {
  //   title: "BOOKWATER ORDERS",
  //   path: "/dashboard/bookwater-order-management",
  //   icon: <ListAltRoundedIcon />,
  // },

  // {
  //   title: "REWARDS AND OFFERS",
  //   path: "/dashboard/organization",
  //   icon: <LocalOfferRoundedIcon />,
  // },
  // {
  //   title: "REPORTS",
  //   path: "/dashboard/organization",
  //   icon: <SummarizeRoundedIcon />,
  // },
  // {
  //   title: "SUPPORT",
  //   path: "/dashboard/organization",
  //   icon: <HelpCenterRoundedIcon />,
  // },
  // {
  //   title: "SETTINGS",
  //   path: "/dashboard/settings",
  //   icon: <SettingsRoundedIcon />,
  // },
  {
    title: "RECYCLE ORDERS",
    path: "/dashboard/recycleOrders",
    icon: <Recycling />,
  },
  {
    title: "MASTER SETTING",
    path: "/dashboard/configuration",
    icon: <ListAltRoundedIcon />,
  },
  {
    title: "DISTRIBUTOR STOCK",
    path: "/dashboard/distributor",
    icon: <Recycling />,
  },
  {
    title: "API DOCUMENTATION",
    path: "/dashboard/api_documentation",
    icon: <ListAltRoundedIcon />,
  },
  {
    title: "BOOK NOW",
    path: "/dashboard/Distributor_BookNow",
    icon: <FontAwesomeIcon icon={faBook} />,
  },
  {
    title: "ORDERS",
    path: "/dashboard/filling_station_orders",
    icon: <ShoppingCartCheckout />,
  },
  {
    title: "DELIVERIES",
    path: "/dashboard/consumer_orders",
    icon: <LocalShipping />,
  },
  {
    title: "REPORTS",
    path: "/dashboard/reports",
    icon: <Summarize />,
  },
];

import React from "react";
// import { appColors } from "../../Assests/AppTheme/appThemeConst";
// import CustomConsole from "../../../CustomConsole";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { Height } from "@mui/icons-material";

function DeliveriesEnterOtpDialog(props) {
  const handleSubmitOTP = () => {
    props.handleSubmitOTP(props);
  };
  const handleClose = () => {
    props.handleClose(props);
  };

  const handleChangeOTP = (event) => {
    let dataTemp = event.target.value + "";
    props.setEnterOtpTextfied(event.target.value);
    // if (dataTemp.length <= 4) {
    //   props.setEnterOtpTextfied(event.target.value);
    //   if (dataTemp.length == 4) {
    //     props.setOtpValidationError(false);
    //   } else {
    //     props.setOtpValidationError(true);
    //   }
    // } else {
    //   props.setOtpValidationError(true);
    // }
  };
  return (
    <Box sx={{ borderRadius: "30px" }}>
      <Dialog
        open={props.openEnterOtpDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "30px",
            },
          },
        }}
      >
        <DialogContent
          // sx={{
          //   backgroundImage: "linear-gradient(to right, blue, brown)",
          //   borderRadius: "30px",
          // }}
          sx={
            {
              // background: "#074276",
              // backgroundImage:
              //   "radial-gradient(circle, rgb(11, 98, 238) 0%, rgba(148,187,233,1) 100%)",
            }
          }
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 800,
              marginBottom: "10px",
              // color: "white",
            }}
          >
            Enter OTP
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
              variant="outlined"
              type="number"
              label="Enter Your OTP"
              value={props.enterOtpTextfied}
              onChange={handleChangeOTP}
              // inputProps={{
              //   min: 0,
              //   maxLength: 4,
              //   inputMode: "numeric",
              //   pattern: "[0-9]*",
              // }}
            />
          </Box>
          <Box
            sx={{
              margin: "10px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              label="close"
              color="error"
              handleClick={handleClose}
            />
            <CustomButton
              label="Submit"
              // size="small"
              style={{ height: "30px", mt: "15px" }}
              handleClick={handleSubmitOTP}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default DeliveriesEnterOtpDialog;

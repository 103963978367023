import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function FlowChart({ flow }) {
  console.log("---FlowChart---", flow);
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        {flow.map((data, index) => (
          <Box key={index}>
            {/* <Box>
              <Typography>{data.name}</Typography>
            </Box> */}
            <Card
              sx={{
                // background: "linear-gradient(135deg, #ff9a9e, #fad0c4)",
                background: "#292929",
                boxShadow: "0px 4px 12px rgba(255, 255, 255, 0.1)", // Soft glow effect
                borderRadius: "12px",
                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                padding: "16px",
                textAlign: "center",
                maxWidth: 300,
                border: "1px solid rgba(255, 255, 255, 0.1)", // Subtle border for depth
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  //   sx={{ fontWeight: "bold", color: "#333" }}
                  sx={{
                    fontWeight: "bold",
                    color: "#ffffff",
                    letterSpacing: "0.5px",
                  }}
                >
                  {data.name}
                </Typography>
              </CardContent>
            </Card>
            {flow.length - 1 !== index && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>
                  <ArrowDownwardIcon sx={{ height: "75px" }} />
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default FlowChart;

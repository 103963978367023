import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import CustomAccordion from "../../Components/CustomComp/CustomAccordion";
import processData from "./processData";
import FlowChart from "./FlowChart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";

function ProcessHome() {
  //hooks
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(false);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <Box>
      <Card sx={{ position: "fixed", top: 0, width: "100vw" }}>
        <CardContent
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            background: "#0d47a1",
            boxShadow: "none",
            width: "100%",
            height: "60px",
            zIndex: 1000,
          }}
        >
          <Button
            // variant=""
            onClick={handleBack}
            sx={{
              color: "white",
              marginTop: "5px",
            }}
          >
            <ArrowBackIcon />
          </Button>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1px",
                marginTop: "10px",
              }}
            >
              <img
                alt=""
                src={bwLogo}
                style={{
                  cursor: "pointer",
                  height: "26px",
                  width: "24px",
                  marginTop: "-5px",
                }}
              />
              <Typography
                sx={{ color: "white", fontWeight: 800, fontSize: "18px" }}
              >
                OOK WATER
              </Typography>
            </Box>
          </Box>
          {/* <Box></Box> */}
        </CardContent>
      </Card>
      <Box sx={{ marginTop: "75px", padding: "0.5rem" }}>
        <Box sx={{ marginBottom: "30px" }}>
          <Typography
            sx={{ textAlign: "center", fontSize: "18px", fontWeight: 600 }}
          >
            Process Flow
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {processData.map((processObj, index) => (
            <CustomAccordion
              key={index}
              title={`Step ${index + 1} : ${processObj.process}`}
              body={<FlowChart flow={processObj.data} />}
              index={index}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default ProcessHome;

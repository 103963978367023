/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 * @author Vinay Kumar
 */
import React from "react";
import { useSelector } from "react-redux";
import BWCANNew from "../../Images/BWCANNew.png";

const ImageViewer = ({ filename }) => {
  const imageFilesstore = useSelector((state) => state.filesData.appImages);
  const loadImage = () => {
    try {
      // Retrieve the image URL from local storage
      const storedImageURL = imageFilesstore[`image_${filename}`];

      if (storedImageURL) {
        // Display the image in the UI using the stored URL
        return (
          <img
            loading="lazy"
            src={storedImageURL}
            alt={filename}
            style={{
              width: "100%", // Allow the width to adjust according to the aspect ratio
              height: "9rem", // Allow the height to adjust according to the aspect ratio
              objectFit: "contain",
            }}
          />
        );
      } else {
        // Handle the case when the image URL is not found in local storage
        return (
          <img
            alt=""
            src={process.env.PUBLIC_URL + "/bwLogo.png"}
            style={{ objectFit: "contain", height: "30px" }}
          />
        );
      }
    } catch (error) {
      // console.error("Error loading image:", error);
      // Handle errors, e.g., display an error message
      return (
        <img
          alt=""
          src={BWCANNew}
          style={{
            width: "100%", // Allow the width to adjust according to the aspect ratio
            height: "6rem", // Allow the height to adjust according to the aspect ratio
            objectFit: "contain",
          }}
        />
      );
    }
  };

  return <div>{loadImage()}</div>;
};

export default ImageViewer;

import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import appStyle from "../../AppMeta/appStyle";
import CustomButton from "./CustomButton";

function AddButton({ handleOnClickButton, label = "add" }) {
  return (
    <Box>
      <CustomButton
        variant="contained"
        startIcon={<AddIcon />}
        size="small"
        handleClick={handleOnClickButton}
        label={label}
        // sx={appStyle.general.button.blueButton}
      />
      {/* Add */}
      {/* </Button> */}
    </Box>
  );
}

export default AddButton;

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BadgeIcon from "@mui/icons-material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Card,
  CardContent,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Menu,
  Dialog,
  useTheme,
  Toolbar,
  AppBar,
  Drawer,
  TablePagination,
  useMediaQuery,
} from "@mui/material";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateWFS,
  CreateWFSLines,
  CreateWfsBatch,
  GetBatchDetails,
  GetWFS,
  GetWFSLines,
  GetWfsLineIds,
} from "../../Redux/Actions/wfsDetailsAction";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AllWfsList from "./AllWfsList";
import appMeta from "../../AppMeta/appMeta";
import { UploadFile, getFiles } from "../../Redux/Actions/fileHandlingActions";
import AddIotDevices from "./IotDevices/AddIotDevices";
import IotDevices from "./IotDevices/IotDevices";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import WfsLinesTable from "./WfsLinesTable";
import BatchDetailsTable from "./BatchDetailsTable";
import { AddLinesFacilty } from "./LinkingAndAddingFacilitiesAndIOTDevices/AddLinesFacilty";
import { LinkIOTtoFacilityFillingLines } from "./LinkingAndAddingFacilitiesAndIOTDevices/LinkIOTtoFacilityFillingLines";
import { AddIOTDevices } from "./LinkingAndAddingFacilitiesAndIOTDevices/AddIOTDevices";
import { GetOrganizationList } from "../../Redux/Actions/organizationAction";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import {
  Add,
  ConnectingAirportsOutlined,
  ConstructionOutlined,
  Dashboard,
  SelectAllSharp,
} from "@mui/icons-material";
import {
  GetLinesFacility,
  GetWfsForFacility,
  GetLinesForWfs,
} from "../../Redux/Actions/addFacilityWFSFillingAction";
import {
  AddCreateBatch,
  GetCreateBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import { tokens } from "../../AppMeta/theme";
import appStyle from "../../AppMeta/appStyle";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import appData, { tabValues } from "../../AppMeta/appData";
import IotDevicesMain from "./IotDevicesMain";
import Wfsindex from "./WaterFillingStation/Wfsindex";
import CustomForm from "../../Components/CustomComp/CustomForm";
import { getInputForSelectFromArray } from "../../Components/Utils/CustomFormUtils";
import NewCameraScanner from "../../Components/QrCodeScanner/NewCameraScanner";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import RecycleOrdersMain from "./RecycleOrdersMain";
import AddIcon from "@mui/icons-material/Add";
import FillingStationOrdersMain from "./FillingStationOrders/FillingStationOrdersMain";
import CustomTab from "../../Components/CustomComp/CustomTab/CustomTab";
import BatchDashboard from "./BatchDashboard";

const steps = [
  "Onboard filling station",
  "Add Filling Lines",
  "Add IoT Device",
  "Facility Lines And IOT Devices",
];

export default function FillingStation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const organisationDetails = useSelector((state) => state.organizationData);
  const wfsDetails = useSelector((state) => state.wfsDetails);

  const filesDetails = useSelector((state) => state.filesData);
  const productDetails = useSelector((state) => state.productData);
  const batchDetails = useSelector((state) => state.batchData);
  console.log("--batchDetails--", batchDetails);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [alignment, setAlignment] = React.useState("Existing filling stations");
  const [finalProducts, setFinalProducts] = React.useState([]);

  //   Textfield variables
  const [fillingStationName, setFillingStationName] = React.useState("");
  const [isFillingStationNameValid, setIsFillingStationNameValid] =
    React.useState(true);
  const [area, setArea] = React.useState("");
  const [isAreaValid, setIsAreaValid] = React.useState(true);
  const [phone, setPhone] = React.useState("");
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const [pincode, setPincode] = React.useState("");
  const [isPincodeValid, setIsPincodeValid] = React.useState(true);
  const [email, setEmail] = React.useState("");

  const [isEmailValid, setIsEmailValid] = React.useState(true);

  const [password, setPassword] = React.useState("");
  const [roleName, setRoleName] = React.useState("SUPER-ADMIN");

  const [orgName, setOrgName] = React.useState("");

  const [ownerName, setOwnerName] = React.useState("");
  const [isOwnerNameValid, setIsOwnerNameValid] = React.useState(true);
  const [gstNumber, setGstNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [fassaiNumber, setFassaiNumber] = React.useState("");
  const [isiNumber, setIsiNumber] = React.useState("");
  const [aadhaarNumber, setAadhaarNumber] = React.useState("");
  const [allFacilities, setAllFacilities] = React.useState(
    organisationDetails.organizationList
  );
  const [allWfs, setAllWfs] = React.useState(wfsDetails.wfsLineId);

  //   File uploads variables
  const [gstFile, setGstFile] = React.useState("");
  const [gstUrl, setGstUrl] = React.useState("");
  const [gstUploadBtn, setGstUploadBtn] = React.useState(false);
  const [gstFileFlag, setGstFileFlag] = React.useState(false);

  const [panFile, setPanFile] = React.useState("");
  const [panUrl, setPanUrl] = React.useState("");
  const [panUploadBtn, setPanUploadBtn] = React.useState(false);
  const [panFileFlag, setPanFileFlag] = React.useState(false);

  const [aadhaarFile, setAadhaarFile] = React.useState("");
  const [aadhaarUrl, setAadhaarUrl] = React.useState("");
  const [aadhaarUploadBtn, setAadhaarUploadBtn] = React.useState(false);
  const [aadhaarFileFlag, setAadhaarFileFlag] = React.useState(false);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [fssaiFile, setFssaiFile] = React.useState("");
  const [fssaiUrl, setFssaiUrl] = React.useState("");
  const [fssaiUploadBtn, setFssaiUploadBtn] = React.useState(false);
  const [fssaiFileFlag, setFssaiFileFlag] = React.useState(false);

  const [isiFile, setIsiFile] = React.useState("");
  const [isiUrl, setIsiUrl] = React.useState("");
  const [isiUploadBtn, setIsiUploadBtn] = React.useState(false);
  const [isiFileFlag, setIsiFileFlag] = React.useState(false);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [isActiveFilter, setIsActiveFilter] = React.useState(true);
  const [ascDesc, setAscDesc] = React.useState("ASC");

  const [value, setValue] = React.useState(0);
  const [addValue, setAddValue] = React.useState(0);

  const [newWfsId, setNewWfsId] = React.useState("");
  const [selectFacility, setSelectFacility] = React.useState("");

  // Batch details

  const [wfsId, setWfsId] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [lineId, setLineId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [isQuantityValid, setIsQuantityValid] = React.useState(true);
  const [batchList, setBatchList] = React.useState([]);

  //view
  const [view, setView] = React.useState("table");

  //pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeTabs = (event, newValue) => {
  //   setValue(newValue);
  // };
  const handleChangeTabs1 = (event) => {
    setAddValue(event.target.value);
  };

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          // ...(organizationSearch !== "" && { org_name: organizationSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };
  // GET API Call

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          // ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
    // dispatch(GetWFS(pageNumber, pageSize, isActiveFilter, ascDesc));
    dispatch(GetFinalProductSKU());
  }, [isActiveFilter, ascDesc, pageNumber, pageSize]);
  React.useEffect(() => {
    searchAndUpdateFacility();
    // dispatch(GetOrganizationList());
    console.log("organisation");
    console.log(organisationDetails);
  }, []);

  React.useEffect(() => {
    dispatch(GetFinalProductSKU());
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   console.log("selectFacility");
  //   console.log(selectFacility);

  //   dispatch(GetLinesFacility(selectFacility));
  // }, [selectFacility]);

  // Accessing wfs data from store

  React.useEffect(() => {
    console.log(wfsDetails);
    if (wfsDetails.error === false) {
      setBatchList(wfsDetails?.wfsbatchList?.data);
      setLastPageNumber(wfsDetails?.wfsList?.lastPageNumber);
      if (wfsDetails.new_wfs_id !== "" && wfsDetails.new_wfs_id !== null) {
        setNewWfsId(wfsDetails.new_wfs_id);
        setActiveStep(1);
      }
    }
  }, [wfsDetails]);

  React.useEffect(() => {
    console.log(newWfsId);
    if (newWfsId !== "") {
      // dispatch(GetWFSLines(newWfsId));
      dispatch(GetBatchDetails(newWfsId));
    }
  }, [newWfsId]);

  //Organisation Deatils
  React.useEffect(() => {
    console.log("organisationDetails");
    console.log(organisationDetails);
    setAllFacilities(organisationDetails.organizationList);
  }, [organisationDetails]);
  React.useEffect(() => {
    setAllWfs(wfsDetails.wfsLineId);
  }, [wfsDetails]);

  React.useEffect(() => {
    console.log("Product Data");
    console.log(productDetails);
    // setProducts(productDetails.finalProduct.product_details);
    // productDetails?.finalProduct?.map((product) => {
    //   setFinalProducts(product.product_details);
    // });
    setFinalProducts(productDetails?.finalProduct);
  }, [productDetails]);

  React.useEffect(() => {
    // console.log("products");
    // console.log(products);
  }, [products]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  React.useEffect(() => {
    console.log(activeStep);
  }, [activeStep]);

  //   ref variable for input uploads
  const gstInputRef = React.useRef(null);
  const panInputRef = React.useRef(null);
  const aadhaarInputRef = React.useRef(null);
  const fassaiInputRef = React.useRef(null);
  const isiInputRef = React.useRef(null);

  //   Handling upload file clicks here
  const handleUploadGstClick = () => {
    gstInputRef.current.click();
  };
  const handleUploadPanClick = () => {
    panInputRef.current.click();
  };
  const handleUploadAadhaarClick = () => {
    aadhaarInputRef.current.click();
  };
  const handleUploadFassaiClick = () => {
    fassaiInputRef.current.click();
  };
  const handleUploadIsiClick = () => {
    isiInputRef.current.click();
  };

  // React.useEffect(() => {
  //   if (gstFile !== "") {
  // dispatch(UploadFile(gstFile));
  //   }
  // }, [gstFile]);
  //   Handling changing function when user tries to upload files
  const handleChangeGstUpload = (event) => {
    console.log("Selected GST file:", event.target.files[0]);
    setGstFile(event.target.files[0]);
    setGstUploadBtn(true);
  };

  const handleChangePanUpload = (event) => {
    console.log("Selected PAN file:", event.target.files[0]);
    setPanFile(event.target.files[0]);
    setPanUploadBtn(true);
  };

  const handleChangeAadhaarUpload = (event) => {
    console.log("Selected Aadhaar file:", event.target.files[0]);
    setAadhaarFile(event.target.files[0]);
    setAadhaarUploadBtn(true);
  };
  const handleChangeFassaiUpload = (event) => {
    console.log("Selected FSSAI file:", event.target.files[0]);
    setFssaiFile(event.target.files[0]);
    setFssaiUploadBtn(true);
  };
  const handleChangeIsiUpload = (event) => {
    console.log("Selected Isi file:", event.target.files[0]);
    setIsiFile(event.target.files[0]);
    setIsiUploadBtn(true);
  };

  React.useEffect(() => {
    if (gstUploadBtn) {
      console.log("gst file upload");
      dispatch(UploadFile(gstFile));
      setGstUploadBtn(false);
      setGstFileFlag(true);
    } else if (panUploadBtn) {
      console.log("pan file upload");
      dispatch(UploadFile(panFile));
      setPanUploadBtn(false);
      setPanFileFlag(true);
    } else if (aadhaarUploadBtn) {
      console.log("Aadhaar file upload");
      dispatch(UploadFile(aadhaarFile));
      setAadhaarUploadBtn(false);
      setAadhaarFileFlag(true);
    } else if (fssaiUploadBtn) {
      console.log("Fssai file upload");
      dispatch(UploadFile(fssaiFile));
      setFssaiUploadBtn(false);
      setFssaiFileFlag(true);
    } else if (isiUploadBtn) {
      console.log("isi file upload");
      dispatch(UploadFile(isiFile));
      setIsiUploadBtn(false);
      setIsiFileFlag(true);
    }
  }, [gstFile, panFile, aadhaarFile, fssaiFile, isiFile]);

  React.useEffect(() => {
    console.log(`gstUrl ${gstUrl}`);
    console.log(`panUrl ${panUrl}`);
    console.log(`aadhaarUrl ${aadhaarUrl}`);
    console.log(`fssaiUrl ${fssaiUrl}`);
    console.log(`isiUrl ${isiUrl}`);
  }, [gstUrl, panUrl, aadhaarUrl, fssaiUrl, isiUrl]);

  React.useEffect(() => {
    console.log("filesDetails");
    console.log(filesDetails);
    if (gstFileFlag) {
      console.log("setting gst url");
      console.log(filesDetails.fileName);
      setGstUrl(filesDetails.fileName);
      setGstFileFlag(false);
    } else if (panFileFlag) {
      console.log("setting pan url");
      setPanUrl(filesDetails.fileName);
      setPanFileFlag(false);
    } else if (aadhaarFileFlag) {
      console.log("setting aadhaar url");
      setAadhaarUrl(filesDetails.fileName);
      setAadhaarFileFlag(false);
    } else if (fssaiFileFlag) {
      console.log("setting fssai url");
      setFssaiUrl(filesDetails.fileName);
      setFssaiFileFlag(false);
    } else if (isiFileFlag) {
      console.log("setting isi url");
      setIsiUrl(filesDetails.fileName);
      setIsiFileFlag(false);
    }
  }, [filesDetails]);

  React.useEffect(() => {
    // setFinalProducts(data1?.data);
  }, []);

  // React.useEffect(() => {
  //   setSelectedProduct(
  //     finalProducts?.filter(
  //       (item) => item.product_details?.id === selectedProductId
  //     )?.[0]
  //   );
  // }, [selectedProductId]);

  const handleCreateWFS = () => {
    const reqObj = {
      wfs_name: fillingStationName,
      area: area,
      pincode: pincode,
      wfs_owner: ownerName,
      gst_number: gstNumber,
      pan_number: panNumber,
      aadhaar_number: aadhaarNumber,
      fssai_number: fassaiNumber,
      isi_number: isiNumber,
      is_active: true,
      gst_file_url: gstUrl,
      pan_file_url: panUrl,
      aadhaar_file_url: aadhaarUrl,
      fssai_file_url: fssaiUrl,
      isi_file_url: isiUrl,
      email: email,
      org_name: orgName,
      role_name: roleName,
      password: password,
      phone: phone,
    };
    console.log(reqObj);
    const valuesFilled = Object.values(reqObj).every(
      (value) => value !== "" && value !== null
    );
    console.log(valuesFilled);
    dispatch(CreateWFS(reqObj));
    setTimeout(() => {
      // dispatch(GetWFS(pageNumber, pageSize, isActiveFilter, ascDesc));
    }, 1000);
  };

  const handleAddLines = () => {
    console.log("Handling Add Lines click");
    const reqObj = {
      wfs_id: parseInt(newWfsId),
    };
    dispatch(CreateWFSLines(reqObj));
    setTimeout(() => {
      // dispatch(GetWFSLines(newWfsId));
    }, 1200);
  };

  const handleCreateBatch = async () => {
    console.log("Handling creating batch click");
    const reqObj = {
      facility_id: selectFacility,
      material: material,
      capacity: capacity,
      quantity: quantity,
      line_id: lineId,
    };
    console.log("Req Obj");
    console.log(reqObj);
    let response = await dispatch(AddCreateBatch(reqObj));
    console.log("---Response---", response);
    if (response.status === 200) {
      setDrawOpenProp(false);
    }
    setTimeout(() => {
      dispatch(GetCreateBatch({ sort_created_at: "DESC" }));
    });
  };

  // React.useEffect(() => {
  //   console.log(wfsId);
  //   if (wfsId !== "") {
  //     const reqObj = {
  //       wfs_id: wfsId,
  //     };
  //     dispatch(GetWFSLines(reqObj.wfs_id));
  //   }
  // }, [wfsId]);
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const [material, setMaterial] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [drawOpenProp, setDrawOpenProp] = React.useState(false);
  const [wfsTabvalue, setWfsTabvalue] = React.useState("BATCH");
  const [filterLabel, setFilterLabel] = React.useState("device_id");
  const [filterSearchText, setFilterSearchText] = React.useState("");
  const [isBatchCompleted, setIsBatchCompleted] = React.useState(false);
  const [isCancelled, setIsCancelled] = React.useState(false);

  const handleChangeProduct = (event) => {
    console.log(event.target.value);
    setSelectedProductId(event.target.value);
  };
  const handleBatchDetailFilter = ({ value, searchText }) => {
    console.log(value, searchText);
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };

  const handleBatchDetailSort = ({ value, searchText }) => {
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };

  React.useEffect(() => {
    console.log(`Capacity: ${capacity}`);
    console.log(`Material: ${material}`);
  }, [capacity, material]);
  const handleChangeWfsTab = (event, newValue) => {
    setWfsTabvalue(newValue);
  };

  return (
    <Box
    // sx={{
    //   width: "100%",
    // }}
    >
      {/* <NewCameraScanner /> */}
      <Box
        sx={{
          //fox for SKU pages in dashboard
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {/* <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={wfsTabvalue}
          onChange={handleChangeWfsTab}
          textColor="secondary"
          indicatorColor="secondary"
        > */}
        {/* <Tab
            sx={appStyle.general.tab.firstLevel}
            value={1}
            label="APIs Management"
          /> */}
        {/* <Tab sx={appStyle.general.tab.firstLevel} label="Provide Access" /> */}
        {/* <Tab
            sx={appStyle.general.tab.firstLevel}
            value="ORDERS"
            label="ORDERS"
          /> */}
        {/* <Tab
            sx={appStyle.general.tab.firstLevel}
            value="BATCH"
            label="BATCH DETAILS"
          /> */}
        {/* <Tab
            sx={appStyle.general.tab.firstLevel}
            value="WFS"
            label="WFS DETAILS"
          /> */}
        {/* <Tab
            sx={appStyle.general.tab.firstLevel}
            value="IOTDEVICES"
            label="IOT DEVICES"
          /> */}
        {/* <Tab
            sx={appStyle.general.tab.firstLevel}
            value="PLACERECYCLEORDER"
            label="PLACE RECYCLE ORDER"
          /> */}
        {/* </Tabs> */}
        {wfsTabvalue === "IOTDEVICES" && (
          <Box>
            <IotDevicesMain />
          </Box>
        )}
        {wfsTabvalue === "ORDERS" && (
          <Box>
            <FillingStationOrdersMain />
          </Box>
        )}
        {wfsTabvalue === "WFS" && <Wfsindex />}
      </Box>
      {/* <DisplayTitle>Water Station</DisplayTitle> */}
      {/* <Header name="Water Station" /> */}
      {wfsTabvalue === "BATCH" && (
        <Box
          sx={{
            //fox for SKU pages in dashboard
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",

            padding: "10px",
            margin: "0.5rem",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {/* <ToggleButtonGroup
            color="secondary"
            value={value}
            exclusive
            onChange={handleChange}
            aria-label="text alignment"
          >
            <ToggleButton
              value={tabValues.createBatch}
              aria-label="left aligned"
            >
              Create batch
            </ToggleButton>
            <ToggleButton value={tabValues.iot} aria-label="left aligned">
              Link IOT
            </ToggleButton>
          </ToggleButtonGroup> */}
          {value === tabValues.iot && (
            <CustomSelect
              name={"Filling station"}
              input={appData.fillingStationSubSelector}
              handleChange={handleChangeTabs1}
              defaultValue={addValue}
            />
          )}
          {/* {value === tabValues.createBatch && (
            <Box
              sx={{
                display: "flex",
                justifyContent: isMobile ? "center" : "space-between",
                alignItems: isMobile && "center",
                flexDirection: isMobile ? "column-reverse" : "row",
              }}
            >
            
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                      <Box
                  sx={{
                    display: "flex",
                    mt: { md: 0, xs: "20px" },
                    // flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // mt: "10px",
                  }}
                >
                  <Box>
                    <CustomSelect
                      defaultValue={
                        isCancelled ? "cancelled" : isBatchCompleted
                      }
                      name={"Order Status"}
                      sx={{ width: "17ch", mr: "5px" }}
                      handleChange={(event) => {
                        console.log(event.target.value);
                        if (event.target.value === "cancelled") {
                          setIsCancelled(true); //
                        } else {
                          setIsBatchCompleted(event.target.value);
                          setIsCancelled(false);
                        }
                      }}
                      input={[
                        { menuItem: "Ongoing", value: false },
                        { menuItem: "Completed", value: true },
                        { menuItem: "Cancelled", value: "cancelled" },
                      ]}
                    />
                  </Box>
                  <Box sx={{ mr: "10px" }}>
                    <CustomSelect
                      name={"Select View"}
                      input={[
                        { value: "table", menuItem: "Table" },
                        {
                          value: "card",
                          menuItem: "Cards",
                        },
                        // {
                        //   value: "form",
                        //   menuItem: "Form",
                        // },
                      ]}
                      defaultValue={view}
                      handleChange={(e) => {
                        setView(e.target.value);
                      }}
                      sx={{ width: "100px" }}
                    />
                  </Box>
                
                  <Box>
                    <CustomSelect
                      defaultValue={rowsPerPage}
                      name={"Page size"}
                      sx={{ width: "10ch" }}
                      handleChange={(event) => {
                        console.log(event.target.value);
                        setRowsPerPage(event.target.value);
                      }}
                      input={[
                        { menuItem: 25, value: 25 },
                        { menuItem: 100, value: 100 },
                        { menuItem: 200, value: 200 },
                      ]}
                    />
                  </Box>
                </Box>
             
              </Box>
      
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // mt: "4px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      m: "0px 10px 0px 10px",
                      // ml: "10px",
                      // justifyContent: "center",
                      // background: "red",
                    }}
                  >
                    <InputLabel
                      sx={{ fontSize: "10px", fontWeight: "bold", mb: "10px" }}
                    >
                      Page number:
                    </InputLabel>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FirstPageIcon
                        sx={{
                          color: page - 1 > 0 ? "blue" : "gray",
                          fontSize: "24px",
                        }}
                        onClick={() => {
                          if (page - 1 >= 0) {
                            setPage(() => page - 1);
                          }
                        }}
                      />
                      <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        {page + 1}
                      </Typography>
                      <LastPageIcon
                        sx={{
                          color:
                            batchDetails?.batchData?.length === rowsPerPage
                              ? "blue"
                              : "gray",
                          fontSize: "24px",
                        }}
                        onClick={() => {
                          if (batchDetails?.batchData?.length === rowsPerPage) {
                            setPage(() => page + 1);
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", mt: "8px" }}>
                    <CustomFilter
                      name={"Filter"}
                      selectOption={[{ value: "batch_id", label: "Batch ID" }]}
                      type={"filter"}
                      startIcon={<FilterAltIcon />}
                      handleFilterClose={handleBatchDetailFilter}
                    />
              
                    <Box>
                      {" "}
                      <CustomButton
                        startIcon={<AddIcon />}
                        label={"Create Batch"}
                        handleClick={() => {
                          setDrawOpenProp(true);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )} */}
        </Box>
      )}
      {wfsTabvalue === "BATCH" && (
        <>
          {value === tabValues.createBatch && (
            <>
              <CreateBatchForFilling
                setIsBatchCompleted={setIsBatchCompleted}
                setView={setView}
                isMobile={isMobile}
                isCancelled={isCancelled}
                setIsCancelled={setIsCancelled}
                isBatchCompleted={isBatchCompleted}
                selectFacility={selectFacility}
                setSelectFacility={setSelectFacility}
                allFacilities={allFacilities}
                selectedProductId={selectedProductId}
                setSelectedProductId={setSelectedProductId}
                finalProducts={finalProducts}
                lineId={lineId}
                setLineId={setLineId}
                quantity={quantity}
                setQuantity={setQuantity}
                isQuantityValid={isQuantityValid}
                setIsQuantityValid={setIsQuantityValid}
                handleCreateBatch={handleCreateBatch}
                batchList={batchList}
                drawOpenProp={drawOpenProp}
                setDrawOpenProp={setDrawOpenProp}
                filterLabel={filterLabel}
                setFilterLabel={setFilterLabel}
                filterSearchText={filterSearchText}
                setFilterSearchText={setFilterSearchText}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                view={view}
                batchDetails={batchDetails}
              />
            </>
          )}
          {value === tabValues.iot && (
            <Box>
              {/* <AppBar
            position="relative"
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).secondarySkuTabBar.background,
              color: colors.primary[200],
              ...appStyle.sku.secondLevelTab.appBar,
            }}
          >
            <Toolbar>
              <Tabs
                variant="scrollable"
                allowScrollButtonsMobile
                value={addValue}
                onChange={handleChangeTabs1}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                sx={{
                  color: colors.primary[400],
                  indicatorColor:
                    appStyle.general.colors(colors).secondarySkuTabBar
                      .indicatorColor,
                }}
              >
                <Tab
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1.1rem" },
                    fontWeight: "bold",
                  }}
                  value={0}
                  label="Add Lines To Facility"
                />
                <Tab
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1.1rem" },
                    fontWeight: "bold",
                  }}
                  value={1}
                  label="Add IOT Device"
                />
                <Tab
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1.1rem" },
                    fontWeight: "bold",
                  }}
                  value={2}
                  label="Link IOT To Facility Filling Lines"
                />
              </Tabs>
            </Toolbar>
          </AppBar> */}
              <Box sx={{ marginTop: "1rem" }}>
                {addValue === tabValues.addLinesToFacility && (
                  <AddLinesFacilty
                    allFacilities={allFacilities}
                    allWfs={allWfs}
                  />
                )}

                {/* {addValue === 1 && <AddIOTDevices />} */}

                {addValue === tabValues.linkIotToFacility && (
                  <LinkIOTtoFacilityFillingLines
                    allFacilities={allFacilities}
                  />
                )}
              </Box>
            </Box>
          )}
        </>
      )}
      {wfsTabvalue === "PLACERECYCLEORDER" && <RecycleOrdersMain />}
    </Box>
  );
}

const CreateBatchForFilling = ({
  isMobile,
  batchDetails,
  setIsBatchCompleted,
  setView,
  selectFacility,
  setSelectFacility,
  allFacilities,
  selectedProductId,
  setSelectedProductId,
  finalProducts,
  lineId,
  setLineId,
  quantity,
  setQuantity,
  isQuantityValid,
  setIsQuantityValid,
  handleCreateBatch,
  batchList,
  drawOpenProp,
  setDrawOpenProp,
  filterLabel,
  setFilterLabel,
  filterSearchText,
  setFilterSearchText,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  view,
  isBatchCompleted,
  setIsCancelled,
  isCancelled,
}) => {
  console.log("----lineId----", lineId);
  const [openDialog, setOpenDialog] = React.useState(drawOpenProp);
  const [valuenewTab, setValuenewTab] = React.useState("FillingDashboard");
  const handleClose = () => {
    setOpenDialog(false);
    setDrawOpenProp(false);
  };
  const handleBatchDetailFilter = ({ value, searchText }) => {
    console.log(value, searchText);
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };

  React.useEffect(() => {
    setOpenDialog(drawOpenProp);
  }, [drawOpenProp]);

  return (
    <>
      <CustomTab
        bgColor="#CCCCFF"
        setValue={setValuenewTab}
        value={valuenewTab}
        input={[
          {
            value: "FillingDashboard",
            label: "Filling Dashboard",
            component: (
              <>
                {" "}
                <BatchDashboard />
              </>
            ),
          },
          {
            value: "CreateBatch",
            label: "Create Batch",
            component: (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: isMobile && "center",
                    flexDirection: isMobile ? "column-reverse" : "row",
                  }}
                >
                  {/* view */}

                  {/* {pagination && ( */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    {/* Page Size */}
                    <Box
                      sx={{
                        display: "flex",
                        mt: { md: 0, xs: "20px" },
                        // flexDirection: "column",
                        // alignItems: "center",
                        // justifyContent: "center",
                        // mt: "10px",
                      }}
                    >
                      <Box>
                        <CustomSelect
                          defaultValue={
                            isCancelled ? "cancelled" : isBatchCompleted
                          }
                          name={"Order Status"}
                          sx={{ width: "17ch", mr: "5px" }}
                          handleChange={(event) => {
                            console.log(event.target.value);
                            if (event.target.value === "cancelled") {
                              setIsCancelled(true); //
                            } else {
                              setIsBatchCompleted(event.target.value);
                              setIsCancelled(false);
                            }
                          }}
                          input={[
                            { menuItem: "Ongoing", value: false },
                            { menuItem: "Completed", value: true },
                            { menuItem: "Cancelled", value: "cancelled" },
                          ]}
                        />
                      </Box>
                      <Box sx={{ mr: "10px" }}>
                        <CustomSelect
                          name={"Select View"}
                          input={[
                            { value: "table", menuItem: "Table" },
                            {
                              value: "card",
                              menuItem: "Cards",
                            },
                            // {
                            //   value: "form",
                            //   menuItem: "Form",
                            // },
                          ]}
                          defaultValue={view}
                          handleChange={(e) => {
                            setView(e.target.value);
                          }}
                          sx={{ width: "100px" }}
                        />
                      </Box>
                      {/* <InputLabel sx={{ fontSize: "8px" }}>Page size:</InputLabel> */}
                      <Box>
                        <CustomSelect
                          defaultValue={rowsPerPage}
                          name={"Page size"}
                          sx={{ width: "10ch" }}
                          handleChange={(event) => {
                            console.log(event.target.value);
                            setRowsPerPage(event.target.value);
                          }}
                          input={[
                            { menuItem: 25, value: 25 },
                            { menuItem: 100, value: 100 },
                            { menuItem: 200, value: 200 },
                          ]}
                        />
                      </Box>
                    </Box>
                    {/* Page Number */}
                  </Box>
                  {/* )} */}
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        // mt: "4px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          m: "0px 10px 0px 10px",
                          // ml: "10px",
                          // justifyContent: "center",
                          // background: "red",
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontSize: "10px",
                            fontWeight: "bold",
                            mb: "10px",
                          }}
                        >
                          Page number:
                        </InputLabel>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FirstPageIcon
                            sx={{
                              color: page - 1 > 0 ? "blue" : "gray",
                              fontSize: "24px",
                            }}
                            onClick={() => {
                              if (page - 1 >= 0) {
                                setPage(() => page - 1);
                              }
                            }}
                          />
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            {page + 1}
                          </Typography>
                          <LastPageIcon
                            sx={{
                              color:
                                batchDetails?.batchData?.length === rowsPerPage
                                  ? "blue"
                                  : "gray",
                              fontSize: "24px",
                            }}
                            onClick={() => {
                              if (
                                batchDetails?.batchData?.length === rowsPerPage
                              ) {
                                setPage(() => page + 1);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", mt: "8px" }}>
                        <CustomFilter
                          name={"Filter"}
                          selectOption={[
                            { value: "batch_id", label: "Batch ID" },
                          ]}
                          type={"filter"}
                          startIcon={<FilterAltIcon />}
                          handleFilterClose={handleBatchDetailFilter}
                        />
                        {/* <CustomFilter
                    name={"Sort"}
                    selectOption={[
                      { value: "sort_created_at", label: "Created at" },
                    ]}
                    type={"sort"}
                    startIcon={<SwapVertIcon />}
                    handleFilterClose={handleBatchDetailSort}
                  /> */}
                        <Box>
                          {" "}
                          <CustomButton
                            startIcon={<AddIcon />}
                            label={"Create Batch"}
                            handleClick={() => {
                              setDrawOpenProp(true);
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <BatchDetailsTable
                  batchList={batchList}
                  setDrawOpenProp={setOpenDialog}
                  filterLabel={filterLabel}
                  setFilterLabel={setFilterLabel}
                  filterSearchText={filterSearchText}
                  setFilterSearchText={setFilterSearchText}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  view={view}
                  isBatchCompleted={isBatchCompleted}
                  setIsCancelled={setIsCancelled}
                  isCancelled={isCancelled}
                />

                {openDialog && (
                  <OpenDialogCreateBatch
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    setDrawOpenProp={setDrawOpenProp}
                    handleClose={handleClose}
                    selectFacility={selectFacility}
                    setSelectFacility={setSelectFacility}
                    allFacilities={allFacilities}
                    selectedProductId={selectedProductId}
                    setSelectedProductId={setSelectedProductId}
                    finalProducts={finalProducts}
                    lineId={lineId}
                    setLineId={setLineId}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    isQuantityValid={isQuantityValid}
                    setIsQuantityValid={setIsQuantityValid}
                    handleCreateBatch={handleCreateBatch}
                  />
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
};

const OpenDialogCreateBatch = ({
  setDrawOpenProp,
  openDialog,
  setOpenDialog,
  handleClose,
  selectFacility,
  setSelectFacility,
  allFacilities,
  selectedProductId,
  setSelectedProductId,
  finalProducts,
  lineId,
  setLineId,
  quantity,
  setQuantity,
  isQuantityValid,
  setIsQuantityValid,
  handleCreateBatch,
}) => {
  console.log("allFacilities", allFacilities);
  const dispatch = useDispatch();
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  const wfsDetails = useSelector((state) => state.wfsDetails);
  console.log("---wfsDetails---", wfsDetails.wfsLinesList.lineIds);
  console.log("---profileRole---", profileRole);
  const [lineIds, setLineIds] = React.useState([]);
  console.log("-----lineIds-------", lineIds);
  const [productDetails, setProductDetails] = React.useState({
    material: "",
    capacity: "",
  });
  const [organizationSearch, setOrganizationSearch] = React.useState("");
  const [allWfs, setAllWfs] = React.useState(wfsList);
  const [searchWfs, setSearchWfs] = React.useState("");
  const [selectedWfs, setSelectedWfs] = React.useState("");
  const [defaultWfsName, setDefaultWfsName] = React.useState("");
  const [searchLine, setsearchLine] = React.useState();
  const [selectedLine, setSelectedLine] = React.useState("");
  const [selecteddefaultProduct, setSelecteddefaultProduct] =
    React.useState("");
  console.log("------selecteddefaultProduct--------", selecteddefaultProduct);

  // const addFillingLinesFacilities = useSelector(
  //   (state) => state.addFillingLinesFacilities
  // );

  //function

  React.useEffect(() => {
    if (wfsDetails.error === false) {
      setLineIds(wfsDetails?.LinesList);
    }
  }, [wfsDetails]);

  React.useEffect(() => {
    if (lineIds !== undefined && lineIds !== null && lineIds.length > 0) {
      setSelectedLine(lineIds[0]?.line_id);
    }
  }, [lineIds]);
  // React.useEffect(() => {
  //   if (
  //     finalProducts[0].product_details?.id !== undefined &&
  //     finalProducts[0].product_details?.id !== null &&
  //     finalProducts[0].product_details?.id.length
  //   ) {
  //     console.log(
  //       "------finalProducts-------",
  //       finalProducts[0].product_details?.id
  //     );
  //     setSelecteddefaultProduct(finalProducts[0].product_details?.id);
  //   }
  // }, [finalProducts]);
  // React.useEffect(() => {
  //   if (
  //     finalProducts !== undefined &&
  //     finalProducts !== null &&
  //     finalProducts.length > 0
  //   ) {
  //     console.log("-----finalProducts------", finalProducts);
  //   }
  // }, [finalProducts]);
  React.useEffect(() => {
    console.log("selectFacility");
    console.log(selectFacility);
    if (selectFacility !== "") {
      searchAndUpdateWfs();
    }
  }, [selectFacility]);

  React.useEffect(() => {
    if (allWfs !== undefined && allWfs !== null && allWfs.length > 0) {
      console.log("----allWfs------");
      setSelectedWfs(allWfs[0].wfs_id);
      setDefaultWfsName(allWfs[0].wfs_name);
    }
  }, [allWfs]);

  // React.useEffect(() => {
  //   console.log("selectFacility");
  //   console.log(selectFacility);
  //   if (selectFacility !== "") {
  //     dispatch(GetWfsForFacility(selectFacility));
  //     dispatch(GetLinesForWfs(selectedWfs));
  //     dispatch(GetLinesFacility(selectedWfs));
  //   }
  // }, [selectedWfs]);

  const handleChangeProduct = (event) => {
    const productId = event.target.value;
    setSelectedProductId(productId);
    const selectedProduct = finalProducts.find(
      (item) => item.product_details.id === productId
    );
    if (selectedProduct) {
      setProductDetails({
        material: selectedProduct.product_details.material,
        capacity: selectedProduct.product_details.capacity,
      });
    }
  };

  const handleCreateBatchClick = async (formData) => {
    const selectedProduct = finalProducts.find(
      (item) => item.product_details.id === parseInt(formData.product)
    );
    console.log(
      "--selectedProduct--",
      selectedProduct,
      finalProducts,
      formData
    );
    const reqObj = {
      // facility_id: parseInt(formData.facility),
      // facility_id: 6,
      wfs_id: parseInt(selectedWfs),
      material: selectedProduct?.product_details?.material,
      capacity: selectedProduct?.product_details?.capacity,
      quantity: parseInt(formData.quantity),
      line_id: selectedLine,
    };
    console.log("reqObj");
    console.log(reqObj);
    let response = await dispatch(AddCreateBatch(reqObj));
    console.log("---response----", response);
    if (response.status === 200) {
      setOpenDialog(false);
      setDrawOpenProp(false);
      setTimeout(() => {
        dispatch(
          GetCreateBatch({
            sort_created_at: "DESC",
            is_batch_completed: false,
          })
        );
      }, 1000);
    }
  };

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          ...(organizationSearch !== "" && { org_name: organizationSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const searchAndUpdateLines = async () => {
    await dispatch(GetWfsLineIds({ wfs_id: selectedWfs }));
    //  dispatch(
    //   HandleApiActions({
    //     ...wfsApis.get_lines,
    //     params: {
    //       call: 1,
    //       wfs_id: selectedWfs,
    //       // ...(searchLine !== "" && { org_name: searchLine }),
    //       ignore_app_loading_spinner: true,
    //     },
    //     show_toast: false,
    //   })
    // );
  };

  React.useEffect(() => {
    setAllWfs(wfsList);
  }, [wfsList]);

  React.useEffect(() => {
    if (profileRole === "1000" && selectFacility !== "") {
      searchAndUpdateWfs();
    } else if (profileRole !== "1000" && searchWfs !== "") {
      searchAndUpdateWfs();
    }
  }, [searchWfs]);

  React.useEffect(() => {
    searchAndUpdateFacility();
  }, [organizationSearch]);

  React.useEffect(() => {
    (selectedWfs !== "" && searchAndUpdateLines()) ||
      (profileRole === "2034" && searchAndUpdateLines());
  }, [searchLine, selectedWfs, profileRole]);

  return (
    <CustomDrawer
      open={openDialog}
      setOpen={setOpenDialog}
      title={"Create Batch For Filling"}
    >
      <CustomForm
        handleSubmit={handleCreateBatchClick}
        handleClose={handleClose}
        data={[
          {
            numberOfColumn: 1,
            data: [
              // {
              //   inputType: appMeta.inputFieldTypes.SELECT,
              //   placeHolder: "Select Facility",
              //   onChange: (name, value) => {
              //     setSelectFacility(value);
              //   },
              //   name: "facility",
              //   input: getInputForSelectFromArray(
              //     allFacilities?.data,
              //     "org_name",
              //     "id"
              //   ),
              // },
              {
                ...(profileRole === "1000" && {
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "org_name",
                  outputKey: "id",
                  onChange: (name, value) => {
                    setSelectFacility(value);
                  },
                  searchAndUpdateAPI: (searchText) => {
                    setOrganizationSearch(searchText);
                  },
                  input: allFacilities || [],
                  name: "facility",
                  label: "Enter Facility Name",
                }),
              },
              {
                ...(profileRole !== "2034" && {
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "wfs_name",
                  outputKey: "wfs_id",
                  defaultValue: defaultWfsName,
                  onChange: (name, value) => {
                    console.log("---Value--", value);
                    setSelectedWfs(value);
                  },
                  searchAndUpdateAPI: (searchText) => {
                    setSearchWfs(searchText);
                  },
                  input: allWfs || [],
                  name: "wfs",
                  label: "Enter WFS Name",
                }),
              },
              {
                inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                optionKey: "line_id",
                outputKey: "line_id",
                defaultValue: selectedLine,
                onChange: (name, value) => {
                  console.log("---Value--", value);
                  setSelectedLine(value);
                },
                searchAndUpdateAPI: (searchText) => {
                  setsearchLine(searchText);
                },
                input: lineIds,
                name: "line_id",
                label: "Select Line ID",
              },
              {
                inputType: appMeta.inputFieldTypes.SELECT,
                placeHolder: "Select Product",
                name: "product",
                defaultValue:
                  finalProducts[0]?.product_details !== undefined &&
                  finalProducts[0]?.product_details !== null &&
                  finalProducts[0]?.product_details?.id,
                // handleChange: (value) =>
                //   console.log("------value------", value),
                input: getInputForSelectFromArray(
                  finalProducts,
                  "product_details.name",
                  "product_details.id"
                ),
              },

              // {
              //   inputType: appMeta.inputFieldTypes.SELECT,
              //   placeHolder: "Select Line ID",
              //   name: "lineID",
              //   input: lineIds?.map((line) => {
              //     return { menuItem: line, value: line };
              //   }),
              // },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Quantity",
                defaultValue: 1,
                name: "quantity",
                textFieldType: appMeta.textFieldTypes.NUMBER,
                isHintRequired: true,
                isRequired: true,
              },
            ],
          },
        ]}
      />
      {/* <Box sx={{ padding: "20px" }}>
          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Facility
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectFacility}
                label="Select Facility"
                onChange={(event) => setSelectFacility(event.target.value)}
              >
                {allFacilities?.map((facility) => {
                  return (
                    <MenuItem key={facility.id} value={facility.id}>
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {facility.org_name}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Products
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Products"
                value={selectedProductId}
                onChange={handleChangeProduct}
              >
                {finalProducts?.map((item) => {
                  return (
                    <MenuItem
                      key={item?.product_details?.id}
                      value={item?.product_details?.id}
                    >
                      <Box>
                        <Typography
                          style={{ fontWeight: "bold", fontSize: "20px" }}
                        >
                          {item?.product_details?.name}
                        </Typography>
                        <Typography>
                          Capacity: {item?.product_details?.capacity} /
                          Material: {item?.product_details?.material}
                        </Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Line ID
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Line ID"
                value={lineId}
                onChange={(e) => setLineId(e.target.value)}
              >
                {lineIds?.map((line) => {
                  return (
                    <MenuItem key={line} value={line}>
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {line}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ padding: "10px" }}>
            <CustomInputTextField
              placeholder="Quantity"
              value={quantity}
              setValue={setQuantity}
              isValid={isQuantityValid}
              setIsValid={setIsQuantityValid}
              type={appMeta.textFieldTypes.NUMBER}
            />
          </Box>
          <Box sx={{ padding: "10px" }}>
            <CustomButton
              label="Create Batch"
              handleClick={handleCreateBatchClick}
              variant="contained"
            />
          </Box>
        </Box> */}
    </CustomDrawer>
  );
};

import {
  Box,
  Button,
  Divider,
  Drawer,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
  Stack,
  DialogContentText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useContext } from "react";
import { sideBarData } from "./sideBarData";
import { useDispatch, useSelector } from "react-redux";
import actionTypes from "../../Redux/actionTypes";
import { toast } from "react-toastify";
import { Link, useHistory, Redirect } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTheme } from "@emotion/react";
import { ColorModeContext, tokens } from "../../AppMeta/theme";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import { styled } from "@mui/material/styles";
import {
  getDownloadProfileLogo,
  getDownloadProfilePic,
  GetProfile,
  PostResetQrcode,
} from "../../Redux/Actions/profile";
import appStyle from "../../AppMeta/appStyle";
import SunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import ApiDocumentsMain from "../ApiDocuments/ApiDocumentsMain";
import { Logout } from "@mui/icons-material";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { buttons } from "../../AppMeta/appColors";
import customConsole from "../../config/customConsole";

const Item = ({ title, to, icon, selected, setSelected, toggleDrawer }) => {
  const theme = useTheme();
  const history = useHistory();
  const colors = tokens(theme.palette.mode);
  const handleNavigator = () => {
    setSelected(title);
    history.push(to);
    toggleDrawer(false);
  };
  return (
    <ListItem
      disablePadding
      sx={{
        backgroundColor:
          selected === title &&
          appStyle.general.colors(colors).sideMenuBarActive,
      }}
      // onClick={() => {
      //   setSelected(title);
      // }}
    >
      <ListItemButton onClick={handleNavigator}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

const settings = [
  { title: "Profile", path: "/profile" },
  { title: "Account", path: "/account" },
  { title: "Dashboard", path: "/dashboard" },
  { title: "Logout", path: "/authentication" },
];

const topBarTitleName = {
  home: "Home",
  // products: "Stock Keeping Unit",
  usermanagement: "User Management",
  waterstation: "Water Station",
  facilityordermanagement: "Facility Order Management",
  organisationordermanagement: "Organisation Order Management",
  organization_orders_List: "All Orders",
  bookwaterordermanagement: "Bookwater Order Management",
  codesManagement: "QR/Barcode Management",
  // organization: "Rewards and Offer",
  reports: "Reports",
  settings: "Settings",
  recycleOrders: "Recycle Orders",
  api_documentation: "API Documentation",
  profile: "Profile",
  consumer_orders: "Orders",
  filling_station_orders: "orders",
  Distributor_BookNow: "Book Now",
  distributor: "Stock ",
  AdminAllOrders: "Orders",
  iot_device_data: "IoT Data",
  products: "Products",
  app_process: "Process Flow",
  delivery_process: "Delivery Process",
  filling_process: "Filling Process",
  users_process: "Users",
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5",
    }),
  },
}));

const pathnameToTitleModifier = (text = "") => {
  let word = "";
  if (text === "") {
    return;
  }
  for (let i = 0; i < text.length; i++) {
    if (text[i] !== "-") {
      word = word + text[i];
    }
    if (text[i] === "/") {
      word = "";
    }
  }
  return word;
};

function Topbar({ isVisible, setIsVisible }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  console.log("---profileData-----", profileData);
  const location = useLocation();
  const history = useHistory();

  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [selected, setSelected] = React.useState("DashboardMain");
  const [dashboard, setDashboard] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  // const isFacility = false;
  const [isLogout, setIsLogout] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [domain, setDomain] = React.useState("");
  const [isFacility, setIsFacility] = React.useState(false);
  const [openLogOutConfirmDialog, setOpenLogOutConfirmDialog] =
    React.useState(false);
  const [orgFacilityName, setOrgFacilityName] = React.useState("");
  const [profilePictPathLogo, setProfilePictPathLogo] = React.useState("");
  const [orgFacilityLogoName, setOrgFacilityLogoName] = React.useState("");
  const [themeChecked, setThemeChecked] = React.useState(true);
  console.log("-----profilePictPathLogo-------", profilePictPathLogo);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  React.useEffect(() => {
    dispatch(GetProfile());
  }, []);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  React.useEffect(() => {
    console.log("profileData", profileData);
    console.log(profileData);
    setIsFacility(profileData?.profile?.is_facility);
    setDomain(profileData?.profile?.domain);
    setOrgFacilityName(profileData?.profile?.org_name);
    setOrgFacilityLogoName(profileData?.profile?.org_details?.facility_icon);
  }, [profileData]);

  React.useEffect(() => {
    if (profilePictPathLogo !== profileData.profile_pic_path_logo) {
      customConsole(
        "profileDetails.profile_pic_path_logo: ",
        profileData.profile_pic_path_logo
      );
      setProfilePictPathLogo(profileData?.profile_pic_path_logo);
    }
  }, [profileData]);

  // React.useEffect(() => {
  //   console.log("isFacility", isFacility);
  //   console.log("domain", domain);
  //   console.log("orgFacilityName", orgFacilityName);
  //   if (domain === "BOOKWATER.COM") {
  //     setDashboard(sideBarData.bookWaterDashboard);
  //   } else if (isFacility === true) {
  //     setDashboard(sideBarData.facilityDashBoard);
  //   } else {
  //     setDashboard(sideBarData.organisationDashboard);
  //   }
  // }, [isFacility, domain, orgFacilityName]);
  React.useEffect(() => {
    // console.log("isFacility", isFacility);
    // console.log("domain", domain);
    // console.log("orgFacilityName", orgFacilityName);
    // if (domain === "BOOKWATER.COM") {
    // } else if (isFacility === true) {
    //   setDashboard(sideBarData.facilityDashBoard);
    // } else {
    //   setDashboard(sideBarData.organisationDashboard);
    // }

    setDashboard(sideBarData.bookWaterDashboard);
  }, [isFacility, domain, orgFacilityName]);

  React.useEffect(() => {
    if (orgFacilityLogoName && orgFacilityLogoName.length) {
      console.log("---orgFacilityLogoName-----", orgFacilityLogoName);
      dispatch(getDownloadProfileLogo(orgFacilityLogoName));
    }
  }, [orgFacilityLogoName]);

  const handleProfile = (title) => {
    if (title === "Logout") {
      setIsLogout(true);
      dispatch({
        type: actionTypes.SIGN_OUT,
      });
    } else {
      toast.error("Implementation in progress", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleLogOut = () => {
    setOpenLogOutConfirmDialog(true);
  };
  const handleLogOutConfirm = () => {
    setIsLogout(true);
    dispatch({
      type: actionTypes.SIGN_OUT,
    });
    // dispatch({
    //   type: actionType.RESET_PROFILE,
    // });
    // dispatch({
    //   type: actionType.RESET_LAB_CAR_DATA,
    // });
    // dispatch({
    //   type: actionType.RESET_PROJECT_CONFIG,
    // });
  };
  if (isLogout) {
    return <Redirect to="authentication" />;
  }

  const handleConfigureClick = () => {
    history.push("/dashboard/configuration");
  };
  const handleResetQrCode = () => {
    dispatch(PostResetQrcode({}));
    toast.success("QR code reset successfully");
  };
  return (
    <>
      {/* <Box
        sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}
      > */}
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar
        position="sticky"
        sx={{
          background: "linear-gradient(#02173a, #0b48a7)",
          // theme.palette.mode === "dark"
          //   ? "linear-gradient(#1f78ff, #0b48a7)"
          //   : "linear-gradient(#02173a, #0b48a7)",
          boxShadow: "none",

          width: "100%",
          padding: "10px",
          height: "60px",
          m: 0,
        }}
      >
        {/* <Toolbar> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between  ",
            width: "100%",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <MenuOutlinedIcon onClick={() => setIsVisible(!isVisible)} />
            </Box>
          ) : (
            <Box sx={{ ml: "50px", mt: "2px" }}>
              {profilePictPathLogo === "" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1px",
                  }}
                >
                  <img
                    alt=""
                    src={bwLogo}
                    style={{
                      cursor: "pointer",
                      height: "26px",
                      width: "24px",
                      marginTop: "-5px",
                    }}
                  />
                  <Typography
                    sx={{ color: "white", fontWeight: 800, fontSize: "18px" }}
                  >
                    OOK WATER
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // gap: "1px",
                      bgcolor: "white",
                      borderRadius: "15px",
                      width: "130px",
                    }}
                  >
                    <img
                      alt=""
                      src={profilePictPathLogo}
                      style={{
                        cursor: "pointer",
                        height: "50px",
                        width: "auto",
                        marginTop: "-5px",
                      }}
                    />
                  </Box>
                  {/* <Typography
                    // color="secondary"
                    sx={{
                      // color: "#0a3f93",
                      fontWeight: 600,
                      fontSize: "10px",
                      color: "white",
                    }}
                  >
                    POWERED BY BOOKWATER
                  </Typography> */}
                </>
              )}
            </Box>
          )}
          <Box>
            <Typography
              // variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "white",
                fontWeight: 800,
                fontSize: "16px",
              }}
            >
              {/* <DisplayTitle> */}
              {topBarTitleName[pathnameToTitleModifier(location.pathname)]}
              {/* </DisplayTitle> */}
            </Typography>
          </Box>
          <Box sx={{ right: 0, display: "flex" }}>
            {!isMobile && (
              <>
                <FormControlLabel
                  control={<MaterialUISwitch defaultChecked />}
                  // label="theme mode"
                  // onChange={(e)=>setThemeChecked(e.target.checked)}
                  onClick={colorMode.toggleColorMode}
                />

                {/* <IconButton
                  sx={{ width: "24px", mr: 2, color: "white" }}
                  onClick={colorMode.toggleColorMode}
                >
                  {theme.palette.mode === "dark" ? <DarkIcon /> : <SunnyIcon />}
                </IconButton> */}
              </>
            )}
            {/* <IconButton>
              <NotificationsIcon />
            </IconButton>*/}

            <Box>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ width: "24px", mr: 2, color: "white" }}
              >
                <SettingsIcon />
              </IconButton>
              {/* {isMobile && (
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  
                </Menu>
              )} */}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {
                  <>
                    {isMobile && (
                      <MenuItem onClick={colorMode.toggleColorMode}>
                        <IconButton color="inherit">
                          <Typography>Mode</Typography>
                          {theme.palette.mode === "dark" ? (
                            <DarkIcon sx={{ ml: 1 }} />
                          ) : (
                            <SunnyIcon sx={{ ml: 1 }} />
                          )}
                        </IconButton>
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleLogOut}>
                      <IconButton color="inherit" aria-label="logout">
                        <Typography>Logout</Typography>
                        <Logout sx={{ ml: 1 }} />
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={handleConfigureClick}>
                      <IconButton color="inherit">
                        <Typography>configure</Typography>
                        {/* <Logout sx={{ ml: 1 }} /> */}
                      </IconButton>
                    </MenuItem>
                    <MenuItem onClick={handleResetQrCode}>
                      <IconButton color="inherit">
                        <Typography>Rest QR</Typography>
                        {/* <Logout sx={{ ml: 1 }} /> */}
                      </IconButton>
                    </MenuItem>
                  </>
                }
              </Menu>
            </Box>
          </Box>
        </Box>

        <CustomConfirmationDialog
          open={openLogOutConfirmDialog}
          onClose={() => setOpenLogOutConfirmDialog(false)}
          dialogTitle={"Confirm Logout"}
          dialogContent={
            <DialogContentText>
              Are you sure you want to logout?
            </DialogContentText>
          }
          onConfirm={handleLogOutConfirm}
        />
        {/* </Toolbar> */}
      </AppBar>
      {/* </Box> */}

      {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {!isMobile && <Box></Box>}
          
        </Box> */}
      {/* </Box> */}

      <Divider />
    </>
  );
}

export default Topbar;

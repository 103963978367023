/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  jobs: [],
  error: false,
  msg: "",
  iotDevices: [],
};

const iotDeviceReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  console.log(action);
  switch (action.type) {
    case actionType.ADD_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        iotDevices: action.payload.data,
      };
    case actionType.UPDATE_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // wfsList: action.payload.wfsList,
      };
    case actionType.CREATE_IOT_JOB:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    case actionType.FETCH_IOT_JOBS_SUCCESS:
      console.log("Reducer Storing Jobs:", action.payload);
      return {
        ...state,
        jobs: action.payload, // ✅ Store jobs from API response
        error: null,
        msg: "IoT Jobs retrieved successfully",
      };

    case actionType.FETCH_IOT_JOBS_FAILURE:
      return {
        ...state,
        error: action.payload,
        msg: "Failed to fetch IoT jobs",
        jobs: [], // ❗ Ensure jobs is reset to avoid undefined issues
      };

    default:
      return state;
  }
};

export default iotDeviceReducer;

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { useDispatch } from "react-redux";
import {
  GetAllTotalWfsQuantity,
  GetWfsReports,
} from "../../Redux/Actions/ReportsActions";
import SelectWfs from "../AccessManagement/AccessConfiguration/SelectWfs";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import HomeDataCard from "../Home/HomeDataCard";
import { faBottleWater, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import HomeTwoLayerCards from "../Home/HomeTwoLayerCards";
import { useTheme } from "@emotion/react";
function ReportsWfs() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [listofwfsDetails, setListofwfsDetails] = React.useState([]);
  const [listofwfsDropdown, setListofwfsDropdown] = React.useState([]);
  const [selectedWfs, setSelectedWfs] = React.useState("");
  const [activeProductIndex, setActiveProductIndex] = React.useState(0);
  const [autoScrollActive, setAutoScrollActive] = React.useState(true);
  const [totalWfsStockCount, setTotalWfsStockCount] = React.useState([]);
  //functions

  let getWfsStocks = async (params) => {
    let response = await dispatch(GetWfsReports(params));

    if (response.status === 200) {
      console.log("---getWfsStocks---", response);
      setListofwfsDetails(response.data.data);
    }
  };
  const getAllWfstotal = async (params) => {
    const response = await dispatch(GetAllTotalWfsQuantity(params));
    if (response.status === 200) {
      console.log("---response-----", response);
      setTotalWfsStockCount(response.data.data);
    }
  };
  let getWfsStocksDropdown = async (params) => {
    let response = await dispatch(GetWfsReports(params));

    if (response.status === 200) {
      console.log("---getWfsStocks---", response);
      setListofwfsDropdown(response.data.data);
    }
  };
  let handleTableFilter = ({ value, searchText }) => {
    console.log("--handleTableFilter--", value, searchText);
    getWfsStocks({ [value]: searchText });
  };

  React.useEffect(() => {
    getWfsStocks();
    getAllWfstotal();
  }, []);
  const getWfsDetails = (_, wfsID, wfsObject) => {
    console.log("---getWfsDetails---", wfsID, wfsObject);
    getWfsStocks({
      wfs_name:
        wfsObject?.wfs_name !== undefined &&
        wfsObject?.wfs_name !== null &&
        wfsObject?.wfs_name,
    });
    // setSelectedWfs(wfsID);

    // setDeviceId("");
  };
  const products = [
    {
      containers_to_be_filled: 50,
      filled_containers: 230,
      product_name: "20 Liter",
    },
    {
      containers_to_be_filled: 100,
      filled_containers: 1000,
      product_name: "25 Liter",
    },
  ];
  // Auto scroll functionality
  React.useEffect(() => {
    let interval;
    if (autoScrollActive) {
      interval = setInterval(() => {
        if (activeProductIndex < totalWfsStockCount?.length - 1) {
          setActiveProductIndex((prevIndex) => prevIndex + 1);
        } else {
          setActiveProductIndex(0); // Reset to the first product when it reaches the end
        }
      }, 4000); // 5 seconds interval for auto-scroll
    }

    return () => clearInterval(interval); // Clean up interval when component unmounts
  }, [activeProductIndex, totalWfsStockCount?.length, autoScrollActive]);

  const handleNextProduct = () => {
    if (totalWfsStockCount && totalWfsStockCount?.length > 0) {
      console.log("--products---", totalWfsStockCount);
      const currentBatch = totalWfsStockCount[0]; // Assuming we're viewing the first batch
      console.log("----currentBatch----", currentBatch - 1, activeProductIndex);

      // if (activeProductIndex < currentBatch.length - 1) {
      setActiveProductIndex(activeProductIndex + 1);
      // }
    }
  };

  const handleBackProduct = () => {
    if (activeProductIndex > 0) {
      setActiveProductIndex(activeProductIndex - 1);
    }
  };
  return (
    <Box>
      <Paper
        sx={{
          padding: "10px",
          m: "10px",
          bgcolor: theme.palette.mode === "dark" ? "#333333" : "white",
          borderRadius: "20px",
        }}
        elevation={18}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { md: "flex-start", xs: "flex-end" },
            m: "10px 0px 20px 30px",
          }}
        >
          <SelectWfs
            // profileRole={profileRole}
            // defaultWfs={defaultWfsName}
            variant="standard"
            label="Search Filling Station"
            getWfsDetails={getWfsDetails}
          />
        </Box>
        {/* <Paper sx={{ padding: "10px", m: "10px" }}> */}
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "1rem",
            m: { md: "10px", xs: "20px" },
            padding: "20px 10px 0px 10px",
            borderRadius: "20px",
            bgcolor: theme.palette.mode === "dark" ? "#3c4559" : "white",
            position: "relative",
          }}
          component={Paper}
          elevation={18}
          onMouseEnter={() => setAutoScrollActive(false)} // Pause on hover
          onMouseLeave={() => setAutoScrollActive(true)} // Resume on leave
        >
          {totalWfsStockCount !== undefined &&
          totalWfsStockCount !== null &&
          totalWfsStockCount.length > 0 ? (
            <>
              <SwipeableViews
                index={activeProductIndex}
                onChangeIndex={setActiveProductIndex}
                enableMouseEvents
              >
                {totalWfsStockCount.map((product, productIndex) => (
                  <Box
                    key={productIndex}
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      ml: { md: "110px", xs: "35px" },
                      mr: { md: "0px", xs: "35px" },
                      justifyContent: "flex-start",
                      mb: "20px",
                    }}
                  >
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      icon={faBottleWater}
                      // value={product.filled_containers}
                      // subTitle="Filled"
                      title={
                        product.container_capacity +
                        "-" +
                        product.container_material
                      }
                      iconSize="40px"
                      titleSize="25px"
                    />
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #6190e8, #a7bfe8)"
                      // icon={faBottleWater}
                      value={product.total_filled_containers}
                      subTitle="Filled"
                      valueSize="40px"
                      subtitleSize="25px"
                      title={"Total"}
                    />
                    <HomeDataCard
                      bgColor="linear-gradient(to left, #7f7fd5, #86a8e7, #91eae4)"
                      // icon={faBottleWater}
                      value={product.total_empty_containers}
                      subTitle="Empty"
                      valueSize="40px"
                      subtitleSize="25px"
                      title={"Total"}
                    />
                    {/* <HomeTwoLayerCards
                    value={product.containers_to_be_filled}
                    title={"Total"}
                    subTitle="Need to fill"
                    valueSize="40px"
                    subtitleSize="22px"
                    icon={faStopwatch}
                    marginTop="10px"
                  /> */}
                  </Box>
                ))}
              </SwipeableViews>

              {/* Navigation Arrows for Products */}
              <Box
                sx={{
                  position: "absolute",
                  top: { md: "50%", xs: "50%" },
                  left: { md: "5%", xs: "4px" },
                  right: { md: "25%", xs: "4px" },
                  display: "flex",
                  justifyContent: "space-between",
                  transform: "translateY(-50%)",
                }}
              >
                <IconButton
                  onClick={handleBackProduct}
                  sx={{
                    position: "relative",
                    bgcolor: "#6f99e8",
                  }}
                  disabled={activeProductIndex === 0}
                >
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  onClick={handleNextProduct}
                  sx={{
                    position: "relative",
                    bgcolor: "#6f99e8",
                  }}
                  disabled={
                    activeProductIndex === totalWfsStockCount.length - 1
                  }
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            </>
          ) : (
            <Typography>No products found</Typography>
          )}
          <Box
            sx={{
              display: "flex",
              ml: "10px",
              mt: { md: "0px", xs: "20px" },
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ color: "red" }}>* Note </Typography>
            <Typography>
              : All filling stations total products filled and empty quantity .{" "}
            </Typography>
          </Box>
        </Box>
        <Paper
          component={Paper}
          elevation={18}
          sx={{
            borderRadius: "20px",
            bgcolor: theme.palette.mode === "dark" ? "#3c4559" : "white",
            padding: "10px 0px 10px 0px",
            m: "10px",
          }}
        >
          <Typography sx={{ textAlign: "center" }}>
            List of filling station & stocks
          </Typography>
          <CustomTable
            tableHead={[
              "Filling Station Names",
              "Products",
              "Empty Containers",
              "Filled Containers",
            ]}
            tableData={listofwfsDetails}
            isCollapsable={false}
            collapseTableHead={[
              "Products",
              "Empty Containers",
              "Filled Containers",
              // "Ongoing Containers",
            ]}
            collapseTableDataKey={[
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.container_capacity}-
                                {data.container_material}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.empty_containers}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.filled_containers}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
              // {
              //   component: ({ row }) => {
              //     console.log("---collapseTableDataKey---", row);

              //     return (
              //       <Table>
              //         <TableBody>
              //           {row.products.map((data) => {
              //             return (
              //               <TableRow>
              //                 <TableCell
              //                   sx={{
              //                     textAlign: "center",
              //                     border: "0px solid white",
              //                   }}
              //                 >
              //                   {data.ongoing_containers}
              //                 </TableCell>
              //               </TableRow>
              //             );
              //           })}
              //         </TableBody>
              //       </Table>
              //     );
              //   },
              // },
            ]}
            tableDataKey={[
              "wfs_name",
              // {
              //   type: "component",
              //   // rowClickEnable: true,
              //   component: (props) => {
              //     return (
              //       <Typography>
              //         {props?.row?.address?.door_no},{props?.row?.address?.street},
              //         {props?.row?.address?.village},{props?.row?.address?.district}
              //         ,{props?.row?.address?.state},{props?.row?.address?.country},
              //         {props?.row?.address?.pincode}
              //       </Typography>
              //     );
              //   },
              // },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.container_capacity}-
                                {data.container_material}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.empty_containers}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
              {
                component: ({ row }) => {
                  console.log("---collapseTableDataKey---", row);

                  return (
                    <Table>
                      <TableBody>
                        {row.products.map((data) => {
                          return (
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "0px solid white",
                                }}
                              >
                                {data.filled_containers}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  );
                },
              },
            ]}
            filter={false}
            // defaultView="card"
            filterSelectOption={[{ value: "wfs_name", label: "WFS name" }]}
            handleFilterClose={handleTableFilter}
            // sort={true}
            sortSelectOption={[{ value: "created_at", label: "Created at" }]}
            // handleSortClose={handleBatchDetailSort}
            // addButton={true}
            // handleOnClickAddButton={() => {
            //   setDrawOpenProp(true);
            // }}
            // tableRowClickFunction={(index, row) => {
            //   handleEditClick(0, row);
            // }}
            pagination={false}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
          />
        </Paper>
      </Paper>
      {/* </Paper> */}
    </Box>
  );
}

export default ReportsWfs;

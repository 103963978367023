import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../Components/CustomComp/CustomAccordion";
import QRConfiguration from "./QRConfiguration";
import WaterDetailConfiguration from "./WaterDetailConfiguration";
import RewardsConfiguration from "./RewardsConfiguration";
import ScanningLevelConfiguration from "./ScanningLevelConfiguration";
import SelectOrganization from "./SelectOrganization";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { masterConfigApi } from "../../../Redux/Reducers/masterConfigApi";
import userManagementApis from "../../../Redux/Actions/userManagement";
// import profileActions, { GetProfile } from "../../Redux/Actions/profile";
import profileActions from "../../../Redux/Actions/profile";

function MasterConfigurationForm() {
  //hookss
  const dispatch = useDispatch();

  //state
  const [masterConfigObject, setMasterConfigObject] = useState(null);
  const [stateDelivery, setStateDelivery] = React.useState(true);

  //function
  const handleMasterSettingClick = async () => {
    const res = await dispatch(
      HandleApiActions({
        ...masterConfigApi.update_master_setting,
        params: masterConfigObject,
        show_toast: true,
      })
    );

    if (res.data) {
      handleGetFacilitySetting();
      dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
    }
  };

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          org_name: "DELTA",
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const handleGetFacilitySetting = async (org_id) => {
    const res = await dispatch(
      HandleApiActions({
        ...masterConfigApi.get_master_setting,
        params: { org_id: 78 },
        show_toast: true,
      })
    );
    console.log("---handleGetFacilitySetting----", res.data);
    setMasterConfigObject(res.data);
  };

  useEffect(() => {
    searchAndUpdateFacility();
  }, []);

  const handleChangeDelivery = (event) => {
    setStateDelivery(event.target.checked);
    setMasterConfigObject((prev) => ({
      ...prev,
      delivery: event.target.checked,
    }));
  };

  useEffect(() => {
    console.log("---masterConfigObject----", masterConfigObject);
  }, [masterConfigObject]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "sticky",
          top: 5,
          zIndex: 1000,
        }}
      >
        {masterConfigObject !== null && (
          <CustomButton
            size="large"
            label="Save"
            startIcon={<SaveIcon />}
            handleClick={handleMasterSettingClick}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SelectOrganization
            getId={(org_id) => {
              handleGetFacilitySetting(78);
            }}
          />
        </Box>

        {/* {masterConfigObject !== null && (
          <CustomAccordion
            title={"QR"}
            body={
              <QRConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )}
        {masterConfigObject !== null && (
          <CustomAccordion
            title={"Scanning level"}
            body={
              <ScanningLevelConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )}
        {masterConfigObject !== null && (
          <CustomAccordion
            title={"Water quality details"}
            body={
              <WaterDetailConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )}
        {masterConfigObject !== null && (
          <CustomAccordion
            title={"Rewards"}
            body={
              <RewardsConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )} */}

        {masterConfigObject !== null && (
          <CustomAccordion
            title={"Delivery "}
            body={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend" sx={{ fontSize: "16px" }}>
                    Delivery Enable Or Disable
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ m: "10px" }}
                          checked={masterConfigObject.delivery}
                          onChange={handleChangeDelivery}
                          // name="gilad"
                        />
                      }
                      label={stateDelivery === true ? "Enabled" : "Disabled"}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            }
          />
        )}
      </Box>
    </Box>
  );
}

export default MasterConfigurationForm;

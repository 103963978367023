/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Actions for getting , creating , and updating the roles details
 * @date 01/08/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";

import actionType from "../actionTypes";

export const AddIotDevice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/devices`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_IOT_DEVICE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetIotDevices = (
  wfs_id,
  page_number,
  page_size,
  is_active,
  created_at_sort
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  let reqObj = {};
  if (
    page_size === undefined ||
    is_active === undefined ||
    created_at_sort === undefined
  ) {
    reqObj = { wfs_id: wfs_id };
  } else {
    reqObj = {
      wfs_id,
      page_number,
      page_size,
      is_active,
      created_at_sort,
    };
  }
  return async (dispatch) => {
    try {
      let response = await api.get(
        // `/PSCM/devices?` + new URLSearchParams(reqObj)
        `/PSCM/devices?wfs_id='${wfs_id}'`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_IOT_DEVICE,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const UpdateIotDevice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/devices`, reqObj);
      console.log("first", response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_IOT_DEVICE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const CreateIotJob = (device_id, commandType, isActive) => {
  console.log(device_id, commandType, isActive, "------params-----");
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let reqObj = {
        device_id: device_id,
        [commandType]: isActive, // Dynamically setting "start" or "stop"
      };

      let response = await api.post(
        "/PSCM/iotqualitydetails/createjob",
        reqObj
      );

      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CREATE_IOT_JOB,
          payload: response.data,
        });

        toast.success(response.data.msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response;
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.msg || "Failed to create job", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const FetchIotJobs = (device_id) => {
  console.log(device_id, "----deviceId in fetchIotJobs----");
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get("/PSCM/getLatestJobByDeviceId", {
        params: { device_id, page_number: 1, page_size: 5 },
      });
      console.log("API Response:", response.data);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.FETCH_IOT_JOBS_SUCCESS,
          payload: response.data.data,
        });
        // toast.success(response.data.msg, {
        //   position: "top-right",
        //   autoClose: 1000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // dispatch({
      //   type: actionType.FETCH_IOT_JOBS_FAILURE,
      //   payload: error.message,
      // });
    }
  };
};

import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

function CustomDrawer({ open, setOpen, children, title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Drawer
      open={open}
      anchor={isMobile ? "bottom" : "right"}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          minWidth: "350px",
          maxWidth: "600px",
          height: "95vh",
          m: "20px",
        },
      }}
      handleClose={() => {
        setOpen(false);
      }}
    >
      <Box p="10px" display="flex" boxShadow={3} justifyContent="center">
        <Typography
          fontWeight="bold"
          m="10px"
          sx={{ fontSize: "15px", fontWeight: 800 }}
        >
          {title}
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
        ></IconButton>
      </Box>

      {children}
    </Drawer>
  );
}

export default CustomDrawer;

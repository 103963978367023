/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Authentication component to login and signUp
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React, { useState } from "react";
import "./authentication.css";
// import { FaFacebook } from 'react-icons/fa';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../Redux/Actions/authenticationAction";
import { Box, Button, Paper } from "@mui/material";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BgImage from "../../Images/BgImage.jpg";
import { useTheme } from "@emotion/react";
import { height, styled, useMediaQuery, width } from "@mui/system";
import bookwatercover from "../../Images/bookwatercover.jpg";
import waterrackingcan from "../../Images/waterrackingcan.png";
import { Email, Login } from "@mui/icons-material";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.bookwater.com/">
        Bookwater
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const AuthenticationPage = () => {
  //hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = React.useState(false);
  let isDark = theme.palette.mode === "dark";

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [isLogin, setIsLogin] = React.useState(false);
  const [loginBtn, setLoginBtn] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = React.useState(false);

  const dispatch = useDispatch();
  const authenticationDetails = useSelector(
    (state) => state.authenticationData
  );

  //   const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    // <Redirect to="/dashboard" />;
    // Perform sign-up or login logic based on isSignUp state
    if (isSignUp) {
      // Perform sign-up
      console.log("Sign-up:", email, password);
      setRedirectToDashboard(true);
    } else {
      // Perform login
      console.log("Login:", email, password);
      setLoginBtn(true);
      const reqObj = {
        email_phone: email,
        password: password,
      };
      dispatch(logIn(reqObj));
    }
    // Reset form fields
    // setEmail("");
    // setPassword("");
    // setRedirectToDashboard(true);
  };

  React.useEffect(() => {
    console.log(authenticationDetails);
    if (authenticationDetails.verifyError === false) {
      setVerificationCodeSent(true);
    } else {
      setVerificationCodeSent(false);
    }
    if (authenticationDetails.changePasswordError === false) {
      setForgotPassword(false);
    }
    if (
      authenticationDetails.error === false &&
      loginBtn === true &&
      forgotPassword === false
    ) {
      setIsLogin(true);
      setLoginBtn(false);
      localStorage.setItem(
        "roleId",
        JSON.stringify(authenticationDetails.role_id)
      );
      // authenticationDetails.error === true;
    }
  }, [authenticationDetails]);

  if (isLogin) {
    return <Redirect to="/dashboard/profile" />;
  }
  const style = {
    background: !isDark
      ? "linear-gradient(135deg, #E0F2FE, #93C5FD)"
      : "linear-gradient(#02173a, #0b48a7)",
    height: "100vh",
    width: "100vw",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  // const CustomTextField = styled(TextField)();
  return (
    <div className="container" style={style}>
      <Box
        component={Paper}
        elevation={20}
        sx={{
          display: "flex",
          borderRadius: "50px",
          border: "1px solid white",
          bgcolor: "white",
        }}
      >
        {!isMobile && (
          <Box>
            <img
              loading="lazy"
              src={waterrackingcan}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        )}
        <Box>
          <div
            className="form-container"
            style={{
              border: "2px solid black",
              background: !isDark
                ? "#F8FAFC"
                : "linear-gradient(#02173a, #0b48a7)",
              padding: "30px",
              borderTopLeftRadius: isMobile ? "50px" : "0px",
              borderBottomLeftRadius: isMobile ? "50px" : "0px",
              borderBottomRightRadius: "50px",
              borderTopRightRadius: "50px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: "start",
                  fontSize: "40px",
                  fontWeight: "bold",
                  color: isDark ? "white" : "black",
                }}
              >
                Log in
              </Typography>

              <Typography sx={{ mb: "30px", fontSize: "18px" }}>
                Log in and continue to application
              </Typography>
            </Box>

            <form className="form" onSubmit={handleSubmit}>
              <TextField
                className="input"
                type="text"
                placeholder="Email"
                label="Enter Email"
                value={email} // Ensure email is a state variable
                onChange={(e) => {
                  console.log("----email---", e.target.value);
                  setEmail(e.target.value); // Properly update the state
                }}
                sx={{
                  marginBottom: "10px",
                  borderRadius: "50px",
                  // ...(isMobile && { width: "30ch" }),
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "50px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />

              <div className="password-input">
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      borderRadius: "50px",
                    },
                    // ...isMobile && {width:'30ch'}
                  }}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="switch-button"
                  style={{ color: isDark ? "white" : "black" }}
                  onClick={() => setForgotPassword(true)}
                >
                  Forgot Password?
                </button>
              </Box>

              <button className="button" type="submit">
                {isSignUp ? "Sign Up" : "Log In"}
              </button>
            </form>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default AuthenticationPage;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import { GetWfsLineIds } from "../../Redux/Actions/wfsDetailsAction";

function AddIotDeviceDialog(props) {
  console.log("props", props);
  const dispatch = useDispatch();
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  //  const [allWfs, setAllWfs] = React.useState(wfsDetails.wfsLineId);
  const [allWfs, setAllWfs] = React.useState([]);
  const [selectedWfs, setSelectedWfs] = React.useState("");
  const [searchWfs, setSearchWfs] = React.useState("");
  const [searchLine, setsearchLine] = React.useState();
  const [lineIds, setLineIds] = React.useState([]);

  React.useEffect(() => {
    searchAndUpdateWfs();
  }, []);
  React.useEffect(() => {
    if (selectedWfs !== "") {
      searchAndUpdateLines();
    }
  }, [selectedWfs]);

  const searchAndUpdateLines = async () => {
    await dispatch(GetWfsLineIds({ wfs_id: selectedWfs }));
    //  dispatch(
    //   HandleApiActions({
    //     ...wfsApis.get_lines,
    //     params: {
    //       call: 1,
    //       wfs_id: selectedWfs,
    //       // ...(searchLine !== "" && { org_name: searchLine }),
    //       ignore_app_loading_spinner: true,
    //     },
    //     show_toast: false,
    //   })
    // );
  };

  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    setAllWfs(wfsList);
  }, [wfsList]);

  React.useEffect(() => {
    if (wfsDetails.error === false) {
      console.log("---wfsDetails.LinesList---", wfsDetails.LinesList);
      setLineIds(wfsDetails.LinesList);
    }
  }, [wfsDetails]);

  const handleAddIot = (data) => {
    console.log("---form data----", data);
    props.handleAddIot(data);
  };
  const handleCloseAddIot = () => {
    props.handleCloseAddIot();
  };

  return (
    <Drawer
      open={props.openAddIotDialog}
      anchor="right"
      PaperProps={{
        sx: {
          width: 250, // Set the width here
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
        },
      }}
    >
      <Typography align="center" sx={{ fontWeight: 800, fontSize: "25px" }}>
        ADD IOT DEVICE
      </Typography>
      <CustomForm
        data={[
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Device Id",
            defaultValue: props.selectedIotDetails?.device_id,
            name: "device_id",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: props.selectedIotDetails?.device_id ? true : false,
            disabled: props.selectedIotDetails?.device_id ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
            optionKey: "wfs_name",
            outputKey: "wfs_id",
            onChange: (name, value) => {
              console.log("---Value--", value);
              setSelectedWfs(value);
            },
            searchAndUpdateAPI: (searchText) => {
              setSearchWfs(searchText);
            },
            input: allWfs || [],
            name: "wfs",
            label: "Enter WFS Name",
          },
          {
            inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
            optionKey: "line_id",
            outputKey: "line_id",
            defaultValue: props.selectedIotDetails?.line_id,
            // onChange: (name, value) => {
            //   console.log("---Value--", value);
            //   setSelectedLine(value);
            // },
            searchAndUpdateAPI: (searchText) => {
              setsearchLine(searchText);
            },
            input: lineIds,
            name: "line_id",
            label: "Select Line ID",
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Batch Number",
            defaultValue: props.selectedIotDetails?.batch_number,
            name: "batch_number",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
            disabled: props.selectedIotDetails?.batch_number ? true : false,
          },
          // {
          //   inputType: appMeta.inputFieldTypes.INPUT,
          //   placeHolder: "Line Id",
          //   defaultValue: props.selectedIotDetails?.line_id,
          //   name: "line_id",
          //   // textFieldType: appMeta.textFieldTypes.EMAIL,
          //   isHintRequired: true,
          //   isRequired: true,
          //   disabled: props.selectedIotDetails?.line_id ? true : false,
          // },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Sim Card Number",
            defaultValue: props.selectedIotDetails?.sim_number,
            name: "sim_number",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
            disabled: props.selectedIotDetails?.sim_number ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Mft Date",
            defaultValue:
              props.selectedIotDetails?.mft_date || moment().format(),
            name: "mft_date",
            // type: "date",
            textFieldType: "date",
            isHintRequired: true,
            isRequired: true,
            disabled: props.selectedIotDetails?.mft_date ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.SWITCH,
            placeHolder: { on: "Active", off: "Deactive" },
            name: "is_active",
            defaultValue: props.selectedIotDetails?.is_active || false,
            disabled: props.selectedIotDetails?.is_active ? true : false,
          },
        ]}
        handleSubmit={handleAddIot}
        handleClose={handleCloseAddIot}
      />
    </Drawer>
  );
}

export default AddIotDeviceDialog;

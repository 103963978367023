import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Chip,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ScannerRoundedIcon from "@mui/icons-material/ScannerRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { toast } from "react-toastify";
// import { Html5Qrcode } from "html5-qrcode";
import Scanner from "../QrCodeScanner/Scanner";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { QRApis } from "../../Redux/Reducers/QRApis";
import CustomButton from "./CustomButton";
import "../../Pages/CodesManagement/qrBarcode.css";
// import Html5QrcodePlugin1 from "../../Pages/wfsHome/HtmlQrcodePlugin1";
import beepSound from "../Scanning/beepSound.mp3";
import { useTheme } from "@emotion/react";
import { tokens } from "../../AppMeta/theme";
import { useRef } from "react";
import QrCodeScanner from "@mui/icons-material/QrCodeScanner";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Required Props for this custom scanner dialog box
// {
//   setOpenScanningDialog;
//   openScanningDialog;
//   scannedQrBarcode;
//   setScannedQrBarcode;
//   currentScanned;
//   setCurrentScanned;
//   scanQtyMax;
//   scanOrderId;
//   scannedCount;
//   setScannedCount;
//   setRequestObject;
//   setOpenLinkFromStartDialog;
// }

export default function ScanningDialog(props) {
  const scanLimit = 5;
  const theme = useTheme();
  const colors = tokens(theme?.palette?.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const dispatch = useDispatch();
  const scanTextField = useRef();

  console.log("------props.scanQtyMax------", props.scanQtyMax);
  console.log("------props.scannedCount-------", props.scannedCount);

  console.log("------props.scanOrderId---------", props.scanOrderId);
  console.log("------props.currentScanned------", props.currentScanned);
  const [scanOption, setScanOption] = React.useState("camera");
  const [isScanTextFocused, setIsScanTextFocused] = React.useState(false);
  const audioRef = React.useRef(null);
  console.log("-----scanOption-------", scanOption);
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const handleClose = () => {
    setScanOption("scanner");
    props.setOpenScanningDialog(false);
  };

  //QR extractor
  const extractQrCode = (input) => {
    try {
      let url = new URL(input);
      let qrCode = url.searchParams.get("qr_code");
      return qrCode ? qrCode : input;
    } catch (error) {
      return input;
    }
  };

  // Scanning option change function
  const handleSelectScanOption = (event, newValue) => {
    console.log("-- handleSelectScanOption --", newValue);
    console.log(event.target.value);
    setScanOption(newValue);
  };

  //click scan button
  const focusTextField = () => {
    if (scanTextField.current) {
      console.log("---focusTextField---", scanTextField);
      scanTextField.current.focus();
      setIsScanTextFocused(true);
    }
  };

  //   Handling QR/Barcode from scanner
  const handleQrBarcodeIdFromScanner = (event) => {
    let qrId = extractQrCode(event.target.value);
    console.log(
      "----handleQrBarcodeIdFromScannerssss----",
      event.target.value,
      qrId
    );
    setQrBarcodeId(qrId);

    setTimeout(() => {
      if (event.target.value !== props.scannedQrBarcode) {
        console.log("set code for bulk");
        event.target.value !== null &&
          event.target.value !== "" &&
          event.target.value !== undefined &&
          props.setScannedQrBarcode(event.target.value);
      } else {
        console.log("---code not set for bulk ---");
      }
    }, 500);
    // if (scanTextField.current) {
    //   scanTextField.current.focus();
    //   setIsScanTextFocused(true);
    // }
  };

  // Handling camera scanned codes
  const handleCameraScannedCode = (decodedText) => {
    let qrID = extractQrCode(decodedText);
    console.log(
      "decodedText",
      decodedText,
      qrID,
      "-----props---",
      props.scannedQrBarcode
    );
    if (decodedText !== "" && props.scannedQrBarcode !== qrID) {
      props.setScannedQrBarcode(qrID);
    }
  };
  // If any QR/barcode scanned it will come to this useEffect function
  // If any
  React.useEffect(() => {
    console.log("Scanned QR/Barcode code");
    console.log(props.scannedQrBarcode);
    console.log("scan qty max", props.scanQtyMax);
    const newOrderId = props.scanOrderId;
    console.log("scan order Id", newOrderId);

    // Check if scanned QR/barcode is in the expired list
    console.log("props.validateExpired");
    console.log(props.validateExpired);
    console.log(
      props.validateExpired?.some(
        (code) =>
          code.qr_code === props.scannedQrBarcode ||
          code.bar_code === props.scannedQrBarcode
      )
    );
    const isExpired = props.validateExpired?.some(
      (code) =>
        code.qr_code === props.scannedQrBarcode ||
        code.bar_code === props.scannedQrBarcode
    );
    if (isExpired) {
      toast.error("Can expired", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      if (props.isBatch) {
        setTimeout(() => {
          props.setScannedQrBarcode("");
          setQrBarcodeId("");
        }, 200);

        return;
      }
    }
    const condition =
      props.scannedQrBarcode && props.scannedQrBarcode.length > 0 && newOrderId
        ? true
        : false;
    console.log("condition");
    console.log(condition);
    if (condition) {
      let qrCodeList =
        props.currentScanned &&
        Object.keys(props.currentScanned).length &&
        props.currentScanned[newOrderId]
          ? props.currentScanned[newOrderId]
          : [];
      console.log(qrCodeList);
      if (qrCodeList.length && qrCodeList.includes(props.scannedQrBarcode)) {
        toast.error(`Can is scanned already!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console.log("qrCodeList.length <= props.scanQtyMax: ");
        console.log("--qrCodeList--", qrCodeList.length <= props.scanQtyMax);
        if (qrCodeList.length < props.scanQtyMax) {
          qrCodeList = [...qrCodeList, props.scannedQrBarcode];
          props.setScannedCount(qrCodeList.length);
          let localStorageData = {};
          localStorageData[newOrderId] = qrCodeList;
          console.log("localStorageData: ");
          console.log(localStorageData);
          props.setCurrentScanned(() => localStorageData);
          audioRef?.current?.play();
          toast.success("Successfully Scanned!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          console.log("WFS Order limit reached!");
          toast.error(`Scan limit reached, please click submit button`, {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
    setTimeout(() => {
      props.setScannedQrBarcode("");
      setQrBarcodeId("");
    }, 2000);
  }, [props.scannedQrBarcode]);

  const loadData = () => {
    console.log("Change in order id");
    // Assume `props.orderId` is the current order ID we need to check against
    const storedData = localStorage.getItem("current_scanned_codes");
    const data = storedData ? JSON.parse(storedData) : {};
    console.log("storedData");
    console.log(storedData);
    console.log(props.scanOrderId);
    console.log(data);
    console.log(data[props.scanOrderId]);

    // Check if the current order ID from props matches the one stored in local storage
    if (data && data[props.scanOrderId]) {
      console.log("matching");
      props.setCurrentScanned(data);
      props.setScannedCount(data[props.scanOrderId].length);
    } else {
      // If not matching or not existing, reset the scanned codes
      console.log("not matching");
      props.setCurrentScanned({});
      props.setScannedCount(0);
      // localStorage.setItem("current_scanned_codes", {});
      if (localStorage.getItem("current_scanned_codes")) {
        localStorage.removeItem("current_scanned_codes");
      }
    }
  };
  React.useEffect(() => {
    if (props.scanOrderId) {
      loadData();
    }
  }, [props.scanOrderId]);
  React.useEffect(() => {
    console.log("currentScanned");
    console.log(props.currentScanned);
    console.log("currentScanned && Object.keys(currentScanned).length: ");
    console.log(
      props.currentScanned && Object.keys(props.currentScanned).length
    );
    if (props.currentScanned && Object.keys(props.currentScanned).length) {
      console.log("Setting the data in local storage!");
      console.log(props.currentScanned);
      props.setScannedCount(props.currentScanned[props?.scanOrderId]?.length);
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(props.currentScanned)
      );
    }
  }, [props.currentScanned]);

  const handleClickSubmit = () => {
    const reqObj = {
      order_id: props?.scanOrderId,
      codes: props?.currentScanned[props?.scanOrderId],
    };
    console.log("batch scan object", reqObj);
    // dispatch(
    //   HandleApiActions({
    //     ...QRApis.scan_batch_qr,
    //     params: {
    //       batch: props.scanOrderId,
    //       codes: props.currentScanned[props.scanOrderId],
    //     },
    //     show_toast: true,
    //   })
    // );
    // props.handleClickSubmit();
    props.setRequestObject(reqObj);
    setScanOption("camera");
  };

  React.useEffect(() => {
    console.log("scannedCount");
    console.log("---scanLimit------", props.scannedCount, props.scanQtyMax);
    if (props.scannedCount === props.scanQtyMax) {
      console.log("scan limit reached");
      audioRef?.current?.play();
      setTimeout(() => {
        handleClickSubmit();
      }, 1000);
    }
  }, [props.scannedCount, props.scanQtyMax]);

  return (
    <React.Fragment>
      {/* <Dialog
        disableBackdropClick={true}
        open={props.openScanningDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "30px",
              backgroundColor: "#121b47",
            },
          },
          // backgroundColor: "#121b47", // Set the background color to transparent
          // "& .MuiDialogPaper-root": {
          //   backgroundColor: "transparent", // Set the paper (dialog container) background to transparent
          // },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              marginTop: "10px",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box>
              <ToggleButtonGroup
                color="primary"
                value={scanOption}
                exclusive
                onChange={handleSelectScanOption}
                // aria-label="Platform"
              >
                <ToggleButton value="camera">
                  Camera <CameraAltRoundedIcon />
                </ToggleButton>
                <ToggleButton value="scanner">
                  Scanner <ScannerRoundedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box>
              {scanOption === "scanner" && (
                <TextField
                  onChange={handleQrBarcodeIdFromScanner}
                  label="QR/Barcode:"
                  value={qrBarcodeId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {scanOption === "camera" && (
                <div className="scanner-container">
                  <Scanner
                    fps={10}
                    qrbox={180}
                    disableFlip={false}
                    qrCodeSuccessCallback={handleCameraScannedCode}
                  />
                  <div className="red-line"></div>
                  <audio ref={audioRef} src={beepSound} />
                </div>
              )}
            </Box>
            <Box>
              <Chip
                label={`Scanned Cans Count: ${props.scannedCount} / ${props.scanQtyMax}`}
                color="success"
                style={{ fontSize: "1.2rem", padding: "1px" }}
              />
            </Box>
            <Box>
              <CustomButton handleClick={handleClickSubmit} label="submit" />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <CustomButton
                handleClick={() => props.setOpenConfirmationDialog(true)}
                label="Reset Scan"
              />
            </Grid>
            <Grid item>
              <CustomButton
                color="error"
                handleClick={handleClose}
                label="close"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog> */}
      <Dialog
        fullScreen={isMobile}
        disableBackdropClick={true}
        open={props.openScanningDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: isMobile ? 0 : "30px",
              // backgroundColor: "#121b47",
              position: "relative", // Ensures content stays above the background
              overflow: "hidden",
              maxWidth: "100%", // Ensures full width on mobile
              // height: "100%",
            },
          },
          "@media (max-width: 768px)": {
            // Apply to mobile screens only
            "& .MuiDialog-container": {
              position: "fixed", // Make sure the dialog takes the full viewport
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: 0, // Remove any margin
              overflow: "hidden", // Prevent overflow
              borderRadius: 0,
            },
            "& .MuiPaper-root": {
              // backdropFilter: "blur(3px)", // Apply the blur effect to the dialog
              // backgroundColor: "rgba(18, 27, 71, 0.7)", // Semi-transparent background
              backgroundColor: "#000000",
              borderRadius: 0, // Remove border radius for fullscreen effect
              height: "100%", // Full height for mobile screens
              width: "100%", // Full width for mobile screens
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent", // Keep the backdrop transparent for the blur effect
            },
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              // marginTop: "10px",
              // padding: "10px",
              // marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              // gap: "20px",
              // mt: "200px",
            }}
          >
            <Box>
              <ToggleButtonGroup
                color="primary"
                value={scanOption}
                exclusive
                onChange={handleSelectScanOption}
              >
                <ToggleButton value="camera">
                  Camera <CameraAltRoundedIcon />
                </ToggleButton>
                <ToggleButton value="scanner">
                  Scanner <ScannerRoundedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              sx={{
                ...(isMobile && { position: "relative" }),
              }}
            >
              {scanOption === "scanner" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    // padding: "1rem",
                  }}
                >
                  <TextField
                    onChange={handleQrBarcodeIdFromScanner}
                    label="QR/Barcode:"
                    value={qrBarcodeId}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ m: "30px" }}
                  />
                  {/* <TextField
                    inputRef={scanTextField}
                    onChange={handleQrBarcodeIdFromScanner}
                    sx={{
                      position: "absolute",
                      opacity: 0,
                      pointerEvents: "none",
                    }}
                    label="Scan Input"
                    variant="outlined"
                  /> */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      margin: "1rem",
                      padding: "1rem",
                    }}
                  >
                    {isScanTextFocused && (
                      <Box>
                        <Typography
                          sx={{ textAlign: "center", fontSize: "20px" }}
                        >
                          Scan QR with scanner
                        </Typography>
                        <Typography>
                          <QrCodeScanner
                            className="pulse"
                            sx={{ fontSize: "200px" }}
                          />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Button variant="contained" onClick={focusTextField}>
                    Start Scan
                  </Button> */}
                </Box>
              )}
              {scanOption === "camera" && (
                <div
                  className="scanner-container"
                  style={{
                    ...(isMobile && {
                      position: "relative",
                    }),
                  }}
                >
                  <Scanner
                    fps={10}
                    qrbox={180}
                    disableFlip={false}
                    qrCodeSuccessCallback={handleCameraScannedCode}
                  />
                  <div></div>
                  <audio ref={audioRef} src={beepSound} />
                </div>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: isMobile ? "flex-end" : "center",
                flexDirection: "column",
                ...(isMobile && {
                  position: "absolute",
                  height: "55vh",
                  bottom: "0px",
                  top: "200px",
                  zIndex: 100,
                  overflow: "hidden",
                }),
              }}
            >
              <Box>
                <Chip
                  label={`Scanned Cans Count: ${props.scannedCount} / ${props.scanQtyMax}`}
                  color="success"
                  style={{ fontSize: "1.2rem", padding: "1px" }}
                />
              </Box>
              <Box textAlign="center">
                <CustomButton handleClick={handleClickSubmit} label="submit" />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <CustomButton
                handleClick={() => props.setOpenConfirmationDialog(true)}
                label="Reset Scan"
              />
            </Grid>
            <Grid item>
              <CustomButton
                color="error"
                handleClick={handleClose}
                label="close"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

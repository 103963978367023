import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { useTheme } from "@emotion/react";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import appFonts from "../../AppMeta/appFonts";
import CustomCard from "./CustomCard";
import moment from "moment";
import CustomFileUploadButton from "../CustomComp/CustomFileUploadButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { QRApis } from "../../Redux/Reducers/QRApis";
import useAxios from "../../Hooks/useAxios";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import DownloadIcon from "@mui/icons-material/Download";

function UnAuthScanPage() {
  // const { id } = useParams();
  const location = useLocation();
  // const api = useAxios();
  const id = new URLSearchParams(location.search).get("qr_code");

  console.log("---water quality report---", id);

  const dispatch = useDispatch();
  const [qualityReport, setQualityReport] = useState();
  const history = useHistory();

  const getProductDetails = async () => {
    const res = await axios.get(
      `https://paasstage.bookwater.com/PSCM/facility/quality_report?qr_id=${id}`
    );
    console.log("----getProductDetails----", res.data);
    setQualityReport(res?.data?.data);
  };
  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getProductDetails();
  }, [id]);

  useEffect(() => {
    console.log("---Quality Report---", qualityReport);
  }, [qualityReport]);

  // if (!id) {
  //   return <></>;
  // }
  return (
    <Box
      sx={{
        padding: "2rem",
        margin: 0,
        boxSizing: 0,
        background: "linear-gradient(135deg, #1a73e8, #4285f4 50%, #0d47a1)",
        // backgroundSize: "200% 200%",
        // background: "#3e5ca0",
        // background: "#516ca9",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        paddingTop: "6rem",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <Card sx={{ position: "fixed", top: 0, width: "100vw" }}>
        <CardContent
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#0d47a1",
            boxShadow: "none",
            width: "100%",
            height: "60px",
          }}
        >
          <Button
            // variant=""
            onClick={handleBack}
            sx={{
              color: "white",
              marginTop: "5px",
            }}
          >
            <ArrowBackIcon />
          </Button>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1px",
                marginTop: "10px",
              }}
            >
              <img
                alt=""
                src={bwLogo}
                style={{
                  cursor: "pointer",
                  height: "26px",
                  width: "24px",
                  marginTop: "-5px",
                }}
              />
              <Typography
                sx={{ color: "white", fontWeight: 800, fontSize: "18px" }}
              >
                OOK WATER
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              background: "white",
              padding: "0.1rem",
              borderRadius: "5px",
              marginTop: "5px",
            }}
          >
            <Button
              onClick={() => {
                window.location.href = "https://stagpcwa.bookwater.com/";
              }}
              startIcon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
            >
              <Typography sx={{ color: "black" }}>Sign Up</Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CustomCard
          titleKey="Product Name"
          title="20 L Water can"
          bgColor="#0d47a1"
          titleSize="18px"
          input={[
            {
              data: [
                {
                  label: "Manufacture date",
                  value: moment(qualityReport?.mft_date).format("DD-MM-YYYY"),
                },
              ],
            },
            {
              data: [
                {
                  label: "Expiry date",
                  value: moment(qualityReport?.exp_date).format("DD-MM-YYYY"),
                },
              ],
            },
            {
              data: [
                {
                  label: "pH Range",
                  value: qualityReport?.ph,
                },
              ],
            },
            {
              data: [
                {
                  label: "TDS",
                  value: qualityReport?.tds,
                },
              ],
            },
          ]}
        />
        <Card sx={{ width: "350px" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Number of times used
              </Typography>
              {/* <Typography sx={{ marginTop: "-4px", fontSize: "16px" }}>
                :
              </Typography> */}
              <Box
                sx={{
                  fontSize: "22px",
                  height: "50px",
                  width: "50px",
                  borderRadius: "50px",
                  background: "#0d47a1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: 800,
                }}
              >
                {qualityReport?.no_of_times_used}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ width: "350px" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  marginTop: "5px",
                }}
              >
                Download water quality report
              </Typography>
              <Box
                sx={{
                  fontSize: "22px",
                  height: "50px",
                  width: "50px",
                  borderRadius: "50px",
                  background: "#0d47a1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: 800,
                }}
              >
                {/* <CustomFileUploadButton
                  field={{ name: "downloadPdf" }}
                  formData={{
                    downloadPdf: qualityReport?.quality_report,
                  }}
                  hideUploadButton={true}
                  iconColor="white"
                /> */}
                <Typography
                  sx={{ color: "white", marginTop: "3px", fontSize: "14px" }}
                >
                  <DownloadIcon />
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          width: "calc(100vw - 3rem)",
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <CustomCard
          title="Quality Detail"
          input={[
            {
              data: [
                {
                  label: "pH Range",
                  value: data.qualityDetails[0].quality_details.ph,
                },
              ],
            },
            {
              data: [
                {
                  label: "TDS",
                  value: data.qualityDetails[0].quality_details.tds,
                },
              ],
            },
            {
              data: [
                {
                  label: "Manufacture Date",
                  value: moment(
                    data.qualityDetails[0].quality_details.mft_date
                  ).format("DD-MM-YYYY"),
                },
              ],
            },
            {
              data: [
                {
                  label: "Expiry Date",
                  value: moment(
                    data.qualityDetails[0].quality_details.exp_date
                  ).format("DD-MM-YYYY"),
                },
              ],
            },
          ]}
        />

        <CustomCard
          title="Contact Details"
          input={[
            {
              data: [
                {
                  label: "Owner Name",
                  value: data.qualityDetails[0].filled_wfs_details.owner_name,
                },
              ],
            },
            {
              data: [
                {
                  label: "Company Name",
                  value: data.qualityDetails[0].filled_wfs_details.company_name,
                },
              ],
            },
            {
              data: [
                {
                  label: "Phone",
                  value: data.qualityDetails[0].filled_wfs_details.owner_phone,
                },
              ],
            },
          ]}
        /> */}
      </Box>
    </Box>
  );
}

export default UnAuthScanPage;

let processData = [
  {
    process: "IoT Onboard",
    data: [
      { name: "Create Line for WFS", description: "" },
      { name: "Onboard IoT Device", description: "" },
      { name: "Next step product onboarding" },
    ],
  },
  {
    process: "Products",
    data: [
      {
        name: "Onboard Products if a new product needs to be added.",
        description: "",
      },
      {
        name: "CLick on generate Qr code for that product if needed",
        description: "",
      },
      {
        name: "CLick on Get Qr code for that product if needed",
        description: "",
      },
    ],
  },
  // {
  //   process: "QR/barcode",
  //   data: [
  //     { name: "Generate QR/Barcode for new products", description: "" },
  //     { name: "Paste QR code on the new products", description: "" },
  //     { name: "Scan QR code to update stock in the database", description: "" },
  //   ],
  // },
  {
    process: "Filling",
    data: [
      { name: "Create batch for filling empty cans", description: "" },
      {
        name: "Scan empty product to batch by scanning QR code",
        description: "",
      },
      {
        name: "Click Start button once batch filling starts",
        description: "",
      },
      { name: "Click Stop button once batch filling stops", description: "" },
      {
        name: "Click on filling Dashboard tab check the product status",
        description: "",
      },
      { name: "Wait for consumer order", description: "" },
    ],
  },
  {
    process: "Delivery",
    data: [
      // { name: "Verify consumer order with OTP", description: "" },
      {
        name: "Scan filled can by clicking the OUTSCAN button",
        description: "",
      },
      {
        name: "Scan returned empty can by clicking the INSCAN button",
        description: "",
      },
      { name: "Click Complete Order to close the order", description: "" },
    ],
  },
  {
    process: "Stocks",
    data: [
      // { name: "Verify consumer order with OTP", description: "" },
      {
        name: "Filling Station Stocks",
        description: "",
      },
      {
        name: "Check all filling station total filled and empty stock and individual filling station stocks in below table",
        description: "",
      },
      { name: "Consumers Stocks", description: "" },
      {
        name: "Check all Consumers total quantity stock and individual Consumers stocks in below table",
        description: "",
      },
      { name: "Lost Can Stocks", description: "" },
      {
        name: "Check all Consumers and Filling Station more than 45 days sitting with users  total quantity stock and individual Consumers and Filling stocks in below table",
        description: "",
      },
    ],
  },
];

export default processData;

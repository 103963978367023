import { Box, Typography } from "@mui/material";
import React from "react";
import ProcessFlowQr from "../../Images/ProcessFlowQR.png";
import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

//Gk9fdA0It.png
function AppProcessSetpsDemo() {
  const theme = useTheme();
  const history = useHistory();

  //Handle
  const handleFlowProcessClick = () => {
    console.log("---You clicked flow process---");
    history.push("/process-flow");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          borderRadius: "12px",
          width: "500px",
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: theme.palette.mode === "dark" ? "#1f2940" : "#f4f6fc",
          border: `1px solid ${
            theme.palette.mode === "dark" ? "#2c3b5a" : "#e0e6ed"
          }`,
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 10px rgba(0, 0, 0, 0.4)"
              : "0px 4px 10px rgba(0, 0, 0, 0.1)",
          "&:hover": {
            cursor: "pointer",
            background: theme.palette.mode === "dark" ? "#172033" : "#e8edf8",
          },
        }}
        onClick={handleFlowProcessClick}
      >
        <Typography
          sx={{ fontSize: "20px", marginBottom: "2rem", fontWeight: 600 }}
        >
          Flow Process Guide
        </Typography>
        <img style={{ height: "300px", width: "300px" }} src={ProcessFlowQr} />
        <Typography sx={{ fontSize: "14px", marginTop: "10px" }}>
          Scan QR or Click to see Flow Process
        </Typography>
      </Box>
    </Box>
  );
}

export default AppProcessSetpsDemo;

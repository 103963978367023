import { Box } from "@mui/material";
import React from "react";
import CustomAutoComplete from "../../../Components/CustomComp/CustomAutoComplete";
import appMeta from "../../../AppMeta/appMeta";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { useDispatch, useSelector } from "react-redux";
import { wfsApis } from "../../../Redux/Actions/wfsAction";
function SelectWfs({
  selectFacility = "",
  profileRole,
  getWfsDetails,
  defaultWfs,
  variant,
  label,
  firstValueDefault,
}) {
  //hoooks

  console.log("---SelectWfs---", defaultWfs);
  let dispatch = useDispatch();
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  //states
  const [searchWfs, setSearchWfs] = React.useState("");
  const [allWfs, setAllWfs] = React.useState("");

  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    searchAndUpdateWfs();
  }, [searchWfs]);

  React.useEffect(() => {
    console.log("----wfsDetails.wfsLineId----", wfsList);
    if (profileRole === "1000") {
      selectFacility !== "" && setAllWfs(wfsList);
    } else {
      setAllWfs(wfsList);
    }
    // if (firstValueDefault && wfsList !== undefined && wfsList !== null) {
    //   console.log("----wfsList------", wfsList[0]?.wfs_id);
    //   getWfsDetails(wfsList[0]?.wfs_id);
    // }
  }, [wfsList]);

  return (
    <Box>
      <CustomAutoComplete
        field={{
          inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
          optionKey: "wfs_name",
          // optionKey2: "department",
          outputKey: "wfs_id",
          defaultValue: defaultWfs,

          searchAndUpdateAPI: (searchText) => {
            setSearchWfs(searchText);
          },
          input: allWfs || [],
          name: "role_1",
          label: label || "Search Wfs",
        }}
        variant={variant}
        handleInputChange={getWfsDetails}

        // handleInputChange={((name, selectedOption), {})}
      />
    </Box>
  );
}

export default SelectWfs;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";

function HomeDataCard({
  bgColor,
  icon,
  value,
  subTitle,
  title,
  iconSize,
  titleSize,
  subtitleSize,
  valueSize,
}) {
  return (
    <Box
      sx={{
        background: bgColor,
        height: "147px",
        width: "250px",
        borderRadius: "2rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          color: "#0a4299",
          marginTop: "1rem",
          marginLeft: "1rem",
        }}
      >
        <Typography sx={{ fontSize: iconSize || "18px" }}>
          <FontAwesomeIcon icon={icon} />
        </Typography>
        <Typography sx={{ fontSize: titleSize || "18px", fontWeight: 600 }}>
          {title}
        </Typography>
        <Box>
          <Typography sx={{ fontSize: valueSize || "35px", fontWeight: 600 }}>
            {value}
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: subtitleSize || "16px" }}
          >
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HomeDataCard;

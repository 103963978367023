import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AllProductsTable from "../Orders/PurchaseProduct/AllProductsTable";
import { useDispatch, useSelector } from "react-redux";
import ManufacturerTable from "../Products/ManufacturerTable";
import ProductPurchase from "./ProductPurchase";
import PurchaseProductStepper from "./PurchaseProductStepper";
import { GetManuFacturer } from "../../Redux/Actions/ManufacturersAction";
import SKUManagementApp from "./SkuDemo";
import {
  GetComponents,
  GetProductSku,
  GetRawMaterials,
} from "../../Redux/Actions/ProductComponentsAction";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import PurchaseSKU from "./PurchaseSKU";
import { GetSkuStocks } from "../../Redux/Actions/purchaseProductsAction";
import { tokens } from "../../AppMeta/theme";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import appStyle from "../../AppMeta/appStyle";
import App from "../../App";
import appFonts from "../../AppMeta/appFonts";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import appData, { tabValues } from "../../AppMeta/appData";
import AddIcon from "@mui/icons-material/Add";
import FactoryIcon from "@mui/icons-material/Factory";
import appMeta from "../../AppMeta/appMeta";
import appConstants from "../../AppMeta/appConstants";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomButton from "../../Components/CustomComp/CustomButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

function Sku() {
  const productDetails = useSelector((state) => state.productComponentsData);
  const productData = useSelector((state) => state.productData);
  const pincodesAndAreasData = useSelector(
    (state) => state.pincodesAndAreasData
  );
  const purchaseProductDetails = useSelector(
    (state) => state.purchaseProductData
  );
  const manufacturerDetails = useSelector((state) => state.manufacturerData);
  const cratesData = useSelector((state) => state.cratesData);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(tabValues.sku);
  const [subTabValue, setSubTabValue] = useState(tabValues.finalProducts);
  const [productTypeValue, setProductTypeValue] = useState(
    tabValues.finalProducts
  );

  const [pageNumber, setPageNumber] = React.useState(1);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(25);
  const [createdAtSort, setCreatedAtSort] = React.useState("ASC");
  const [available, setAvailable] = React.useState(true);
  const [allProducts, setAllProducts] = React.useState([]);

  //   add products variables
  const [productType, setProductType] = React.useState("");
  const [productName, setProductName] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [productMaterial, setProductMaterial] = React.useState("");
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState("");

  // manufacturer data
  const [manufacturersList, setManufacturersList] = React.useState([]);
  const [allRawMaterials, setAllRawMaterials] = React.useState([]);
  const [allFinishedGoods, setAllFinishedGoods] = React.useState();

  const [rawMaterialsStock, setRawMaterialsStock] = React.useState([]);
  const [finishedGoodStock, setFinishedGoodStock] = React.useState([]);
  const [finalProductStock, setFinalProductStock] = React.useState([]);

  const [allCrates, setAllCrates] = React.useState([]);
  const [allPincodesAndAreas, setAllPincodesAndAreas] = React.useState([]);

  const [drawOpenProp, setDrawOpenProps] = useState(false);
  const [addCartClickTracker, setAddCartClickTracker] = useState(false);

  const [filterObject, setFilterObject] = useState({});
  const [sortValue, setSortValue] = useState();

  //table view

  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [view, setView] = useState(isMobile ? "card" : "table");
  const colors = tokens(theme.palette.mode);
  React.useEffect(() => {
    dispatch(GetSkuStocks());
  }, []);

  React.useEffect(() => {
    console.log("pincodesAndAreasData");
    console.log(pincodesAndAreasData);
    setAllPincodesAndAreas(pincodesAndAreasData.pincodesAndAreas);
  }, [pincodesAndAreasData]);

  React.useEffect(() => {
    console.log("cratesData");
    console.log(cratesData);
    setAllCrates(cratesData.crates);
  }, [cratesData]);

  React.useEffect(() => {
    console.log("productData");
    console.log(productData);
    setAllProducts(productData?.finalProduct);
  }, [productData]);

  React.useEffect(() => {
    console.log("Product details");
    console.log(productDetails);
    if (productDetails.error === false) {
      // setAllProducts(productDetails?.products);
      setAllRawMaterials(productDetails?.rawMaterial);
      setAllFinishedGoods(productDetails?.components);
    }
  }, [productDetails]);

  React.useEffect(() => {
    console.log("purchaseProductDetails");
    console.log(purchaseProductDetails);
    purchaseProductDetails?.skuStock?.map((stocks) => {
      if (stocks.product_type === "100") {
        setRawMaterialsStock((prev) => [...prev, stocks]);
      } else if (stocks.productType === "200") {
        setFinishedGoodStock((prev) => [...prev, stocks]);
      } else if (stocks.product_type === "300") {
        setFinalProductStock((prev) => [...prev, stocks]);
      }
    });
  }, [purchaseProductDetails]);

  React.useEffect(() => {
    console.log("rawMaterialsStock");
    console.log(rawMaterialsStock);
    console.log("finishedGoodStock");
    console.log(finishedGoodStock);
    console.log("finalProductStock");
    console.log(finalProductStock);
  }, [rawMaterialsStock, finalProductStock, finishedGoodStock]);

  React.useEffect(() => {
    console.log("manufacturerDetails");
    console.log(manufacturerDetails);
    if (manufacturerDetails.error === false) {
      setManufacturersList(manufacturerDetails?.manufacturers?.data);
    }
  }, [manufacturerDetails]);

  React.useEffect(() => {
    dispatch(GetRawMaterials(pageNumber, pageSize, available, createdAtSort));
    dispatch(
      GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort)
    );
    // dispatch(GetProductSku(pageNumber, pageSize, available, createdAtSort));
  }, [pageNumber, pageSize, available, createdAtSort]);
  React.useEffect(() => {
    if (value == tabValues.manufacturersSuppliers) {
      dispatch(
        GetManuFacturer({
          page_number: pageNumber,
          page_size: pageSize,
          created_at_sort: createdAtSort,
        })
      );
    }
  }, [pageNumber, pageSize, subTabValue]);

  const handleMainSelectorChange = (event) => {
    console.log(event);
    setValue(event.target.value);
    setDrawOpenProps(false);
  };

  const handleSubSelectorChange = (event) => {
    setSubTabValue(event.target.value);
  };

  const handleDrawerOpen = () => {
    setDrawOpenProps(!drawOpenProp);
  };

  const handleProductTypeChange = (event) => {
    setProductTypeValue(event.target.value);
  };

  const handleProductFilterClose = (data) => {
    console.log("---handleProductFilterClose---", data);
    setFilterObject(data);
  };
  const handleProductSortClose = (data) => {
    console.log("---handleProductSortClose---", data);
  };
  const handleAddProductClick = () => {
    setDrawOpenProps(true);
  };

  // useEffect(() => {}, [value]);
  React.useEffect(() => {
    setSubTabValue(
      value === tabValues.sku
        ? tabValues.finalProducts
        : value === tabValues.manufacturersSuppliers
        ? tabValues.manufacturersSuppliers
        : tabValues.purchaseProducts
    );
  }, [value]);

  return (
    <Box>
      {/* <Header /> */}

      <Box sx={appStyle.box}>
        {/* <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Box>
            <CustomSelect
              name={"Stock keeping unit"}
              input={appData.skuMainSelectData}
              handleChange={handleMainSelectorChange}
              defaultValue={value}
            />
          </Box>
          <Box>
            <CustomSelect
              name={
                value === tabValues.sku
                  ? "SKU"
                  : value === tabValues.manufacturersSuppliers
                  ? "Manufacturers/Suppliers"
                  : "Order products"
              }
              input={
                value === tabValues.sku
                  ? appData.skuSubSelectData
                  : value === tabValues.manufacturersSuppliers
                  ? appData.supplierManufacturer
                  : appData.skuOrderSubSelect
              }
              handleChange={handleSubSelectorChange}
              defaultValue={subTabValue}
            />
          </Box>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <CustomSelect
              name={"Select View"}
              input={[
                { value: "table", menuItem: "Table" },

                { value: "card", menuItem: "Cards" },

                // {
                //   value: "form",
                //   menuItem: "Form",
                // },
              ]}
              defaultValue={view}
              handleChange={(e) => {
                setView(e.target.value);
              }}
              sx={{ width: "100px" }}
            />
          </Box>
          <Box sx={{ mt: "10px", display: "flex" }}>
            {value !== tabValues.orderProducts && (
              <CustomFilter
                name={"Filter"}
                selectOption={[
                  { value: "Roll Name", label: "Name" },
                  { value: "Full Name", label: "Order type" },
                ]}
                type={"filter"}
                startIcon={<FilterAltIcon />}
                handleFilterClose={handleProductFilterClose}
              />
            )}

            {value !== tabValues.orderProducts && (
              <CustomFilter
                name={"Sort"}
                selectOption={[
                  { value: "created_at_sort", label: "Created at" },
                ]}
                type={"sort"}
                startIcon={<SwapVertIcon />}
                handleFilterClose={handleProductSortClose}
              />
            )}

            {value === tabValues.orderProducts ? (
              <Button
                sx={appStyle.general.button.blueButton}
                startIcon={<ShoppingCartIcon />}
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => {
                  setAddCartClickTracker(!addCartClickTracker);
                }}
              >
                Add to Cart
              </Button>
            ) : (
              <CustomButton
                startIcon={<AddIcon />}
                label={"Add Product"}
                handleClick={handleAddProductClick}
              />
            )}
          </Box>
        </Box>

        {/* {value === tabValues.sku ? (
            <Button
              onClick={handleDrawerOpen}
              variant="contained"
              color="secondary"
              sx={appStyle.general.button.addButton}
              endIcon={<AddIcon />}
              size="small"
            >
              {subTabValue === tabValues.rawMaterials
                ? "Raw Materials"
                : subTabValue === tabValues.finishedGoods
                ? "Finished Good"
                : subTabValue === tabValues.finalProducts
                ? "Final Product"
                : subTabValue === tabValues.crates
                ? "Crates"
                : " pincodes and areas"}
            </Button>
          ) : (
            value === tabValues.manufacturersSuppliers && (
              <Button
                onClick={handleDrawerOpen}
                variant="contained"
                color="secondary"
                sx={appStyle.general.button.addButton}
                startIcon={<FactoryIcon />}
                size="small"
              >
                Add Manufacturer
              </Button>
            )
          )} */}
        {/* {value === tabValues.orderProducts &&
          subTabValue === tabValues.purchaseProducts && (
            <Box sx={appStyle.general.button.addButtonBox}>
              <CustomSelect
                name={"product type"}
                input={appData.skuOrderPurchaseProductSubSelect}
                defaultValue={productTypeValue}
                handleChange={handleProductTypeChange}
              />
            </Box>
          )} */}
      </Box>
      {value === tabValues.sku && (
        <>
          <SKUManagementApp
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
            setAvailable={setAvailable}
            setCreatedAtSort={setCreatedAtSort}
            allRawMaterials={allRawMaterials}
            allFinishedGoods={allFinishedGoods}
            allProducts={allProducts}
            productType={productType}
            productName={productName}
            productPrice={productPrice}
            lifeSpan={lifeSpan}
            productMaterial={productMaterial}
            setProductType={setProductType}
            setProductName={setProductName}
            setProductPrice={setProductPrice}
            setLifeSpan={setLifeSpan}
            setProductMaterial={setProductMaterial}
            setIsConfirmed={setIsConfirmed}
            isConfirmed={isConfirmed}
            setSelectedProduct={setSelectedProduct}
            allCrates={allCrates}
            allPincodesAndAreas={allPincodesAndAreas}
            tabProps={subTabValue}
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProps}
            handleDrawerOpen={handleDrawerOpen}
            view={view}
            setView={setView}
          />
        </>
      )}

      {value === tabValues.manufacturersSuppliers && (
        <>
          <ManufacturerTable
            manufacturersList={manufacturersList}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
            setAvailable={setAvailable}
            setCreatedAtSort={setCreatedAtSort}
            tabProps={subTabValue}
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProps}
            view={view}
            setView={setView}
          />
        </>
      )}

      {value === tabValues.orderProducts && (
        <>
          <PurchaseProductStepper
            allProducts={allProducts}
            manufacturersList={manufacturersList}
            allRawMaterials={allRawMaterials}
            allFinishedGoods={allFinishedGoods}
            rawMaterialsStock={rawMaterialsStock}
            subTabValue={subTabValue}
            setSubTabValue={setSubTabValue}
            productTypeValue={productTypeValue}
            setProductTypeValue={setProductTypeValue}
            addCartClickTracker={addCartClickTracker}
          />
          {/* <Box
            sx={{ 
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PurchaseSKU
              manufacturersList={manufacturersList}
              allProducts={allProducts}
              allRawMaterials={allRawMaterials}
              allFinishedGoods={allFinishedGoods}
            />
          </Box> */}
        </>
      )}
    </Box>
  );
}

export default Sku;

import {
  Call,
  FirstPage,
  LastPage,
  Place,
  QrCodeScanner,
  Scanner,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  DialogContentText,
  Divider,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import appMeta from "../../AppMeta/appMeta";
import DeliveriesEnterOtpDialog from "./DeliveriesEnterOtpDialog";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ScanningDialog from "../../Components/CustomComp/ScanningDialog";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import CustomTable from "../../Components/CustomComp/CustomTable";

function DistributorOrdersMain() {
  const dispatch = useDispatch();
  const history = useHistory();
  const listOfOrders = useSelector(
    (state) => state.userManagementReducer.distributor_consumer_orders
  );

  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);

  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [scanOrderId, setScanOrderId] = React.useState(null);
  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [scannedCount, setScannedCount] = React.useState(0);
  const [productData, setProductData] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [orderStatus, setOrderStatus] = React.useState(201);
  console.log("------productData-------", productData);
  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes"))
  );
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [view, setView] = React.useState("card");
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [orderData, setOrderData] = React.useState({});
  console.log("----orderData-----", orderData);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");

  React.useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, orderStatus]);

  const handleOpenCompleteOrder = (props) => {
    console.log("----orderData-props----", props);
    setOrderData(props);
    setOpenEnterOtpDialog(true);
  };
  const handleSubmitOtp = () => {
    console.log("----orderData-----", orderData);
    dispatch(
      HandleApiActions({
        ...userManagementApis.put_verify_otp_order,
        params: {
          otp: enterOtpTextfied,
          order_id: orderData?.order_id,
        },
        show_toast: true,
      })
    );
    setTimeout(() => {
      fetchData();
      setOpenEnterOtpDialog(false);
    }, 1500);
  };
  const handleCloseDialog = () => {
    setOpenEnterOtpDialog(false);
  };

  const handlePhone = (phone_number) => {
    console.log("--phone_number----", phone_number);
    const telUrl = `tel:${phone_number}`;
    window.location.href = telUrl;
  };
  const handleLocation = (props) => {
    console.log("----props-------", props);
    if (
      props.data?.latitude !== undefined &&
      props.data?.latitude !== null &&
      props.data?.longitude !== undefined &&
      props.data?.longitude !== null &&
      parseFloat(props.data?.latitude) > 0 &&
      parseFloat(props.data?.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props?.data?.latitude},${props?.data?.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const handleopenSingleOrder = (data) => {
    const url = `/dashboard/single_order?order_id=${data?.order_id}`;
    history.push(url);
  };

  const handleinscanopen = (data, order_id) => {
    console.log("---order_id------", order_id, data);
    data.order_id = order_id;
    // setOrderData(data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.return_quantity - data?.inscaned_quantity);
    setProductData(data);
  };
  const handleoutscanopen = (data, order_id, is_all_scan_completed) => {
    data.order_id = order_id;
    data.is_all_scan_completed = is_all_scan_completed;
    console.log("---order_id------", order_id, data);
    // setOrderData(data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.quantity - data?.outscaned_quantity);
    setProductData(data);
  };

  const fetchData = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_consumer_orders,
        params: {
          page_number: page,
          page_size: rowsPerPage,
          order_status: orderStatus !== "" ? orderStatus : undefined,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    if (Object.keys(requestObject).length) {
      //@Removed/console.log("request Object in parent");
      //@Removed/console.log(requestObject);
      console.log("setRequestObject", requestObject);
      console.log(requestObject?.codes?.length);
      if (requestObject?.codes?.length) {
        console.log("----productData-----", productData);
        if (
          productData.is_inscan_completed === false &&
          productData.is_outscan_completed === false
        ) {
          dispatch(
            HandleApiActions({
              ...userManagementApis.put_in_scan,
              params: {
                order_id: requestObject.order_id,
                qr_codes: requestObject.codes,
              },
              show_toast: true,
            })
          );
        } else if (
          productData.is_inscan_completed === true &&
          productData.is_outscan_completed === false
        ) {
          dispatch(
            HandleApiActions({
              ...userManagementApis.put_out_scan,
              params: {
                order_id: requestObject.order_id,
                qr_codes: requestObject.codes,
              },
              show_toast: true,
            })
          );
        }
        if (requestObject?.is_all_scan_completed === true) {
          console.log("----is_all_scan_completed--true----");
          handleCompleteOrder();
        }
        // setTimeout(() => {
        //   fetchBatch();
        // }, 1000);
        // setOpenProductCapColorDialog(true);
      }
      setTimeout(() => {
        // dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
        // dispatch(GetCreateBatch({ sort_created_at: "DESC" }));

        localStorage.removeItem("current_scanned_codes");
        setCurrentScanned({});
        setScannedCount(0);
        fetchData();
        setOpenScanningDialog(false);
        setRequestObject({});
      }, 1500);
    }
  }, [requestObject]);

  const handleCompleteOrder = (data) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.post_complete_consumer_order,
        params: {
          order_id: data?.order_id,
        },
        show_toast: true,
      })
    );
    setTimeout(() => {
      fetchData();
    }, 1500);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mr: "20px",
          mt: "10px",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ mr: "10px" }}>
          <CustomSelect
            name={"Select View"}
            input={[
              { value: "table", menuItem: "Table" },
              {
                value: "card",
                menuItem: "Cards",
              },
              // {
              //   value: "form",
              //   menuItem: "Form",
              // },
            ]}
            defaultValue={view}
            handleChange={(e) => {
              setView(e.target.value);
            }}
            sx={{ width: "100px" }}
          />
        </Box>
        <Box>
          <CustomSelect
            defaultValue={orderStatus}
            name={"Order Status"}
            sx={{ width: "17ch", mr: "5px" }}
            handleChange={(event) => {
              console.log(event.target.value);
              setOrderStatus(event.target.value);
            }}
            input={[
              { menuItem: "Ongoing", value: 201 },
              { menuItem: "Completed", value: 500 },
              { menuItem: "All", value: "" },
            ]}
          />
        </Box>
        <Box>
          <CustomSelect
            defaultValue={rowsPerPage}
            name={"Page size"}
            sx={{ width: "10ch" }}
            handleChange={(event) => {
              console.log(event.target.value);
              setRowsPerPage(event.target.value);
            }}
            input={[
              { menuItem: 25, value: 25 },
              { menuItem: 100, value: 100 },
              { menuItem: 200, value: 200 },
            ]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: "0px 10px 0px 10px",
            mt: { xs: "10px", md: "0px" },
            // ml: "10px",
            // justifyContent: "center",
            // background: "red",
          }}
        >
          <InputLabel sx={{ fontSize: "10px", fontWeight: "bold", mb: "10px" }}>
            Page number:
          </InputLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstPage
              sx={{
                color: page - 1 > 0 ? "blue" : "gray",
                fontSize: "24px",
              }}
              onClick={() => {
                if (page - 1 >= 0) {
                  setPage(() => page - 1);
                }
              }}
            />
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              {page + 1}
            </Typography>
            <LastPage
              sx={{
                color:
                  listOfOrders?.batchData?.length === rowsPerPage
                    ? "blue"
                    : "gray",
                fontSize: "24px",
              }}
              onClick={() => {
                if (listOfOrders?.batchData?.length === rowsPerPage) {
                  setPage(() => page + 1);
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      {view === "card" ? (
        <>
          {listOfOrders !== undefined &&
          listOfOrders !== null &&
          listOfOrders.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {listOfOrders?.map((data) => {
                return (
                  <Paper
                    sx={{
                      width: { xs: 300, md: 300 },
                      m: "10px",
                      borderRadius: "20px",
                      "&:hover": {
                        transform: "scale(1.04)", // Enlarge the card on hover
                        // boxShadow: theme.shadows[10], // Change shadow on hover
                        boxShadow: "5px 3px #074276",
                      },
                    }}
                    // onClick={() => handleopenSingleOrder(data)}
                    elevation={10}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        padding: "7px",
                        bgcolor: "#074276;",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                      // divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Chip
                        label={`Order ID : ${data.order_id}`}
                        // onClick={handleViewDetails}
                        // sx={{
                        //   bgcolor:
                        //     props.data.payment_type === 300
                        //       ? "#6d147d"
                        //       : props.data.packaged_drinking_water.order_type === 700
                        //       ? "#EE4190"
                        //       : props.data.packaged_drinking_water.is_subscribed_order ===
                        //         true
                        //       ? "#3BC2C0"
                        //       : "#924EEF",
                        //   color: "white",
                        // }}
                        sx={{
                          bgcolor: "white",
                          color: "black",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        <Call
                          onClick={() => handlePhone(data?.contact_phone)}
                          sx={{
                            fontSize: "25px",
                            bgcolor: "white",
                            borderRadius: "5px",
                            color: "green",
                          }}
                        />
                        <Place
                          onClick={() => handleLocation(data)}
                          color="primary"
                          sx={{
                            fontSize: "25px",
                            marginLeft: "15px",
                            color: "red",
                            bgcolor: "white",
                            borderRadius: "5px",
                            "&:hover": { cursor: "pointer" },
                          }}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ p: "0px 10px 0px 10px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          // padding: "5px 5px 0px 5px",
                          borderBottom: "2px dashed gray",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          Contact Name :
                        </Typography>
                        <Typography>{data.contact_name}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          // padding: "5px 5px 0px 5px",
                          borderBottom: "2px dashed gray",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          Contact Number :
                        </Typography>
                        <Typography>{data.contact_phone}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          // padding: "5px 5px 0px 5px",
                          borderBottom: "2px dashed gray",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          status :
                        </Typography>
                        <Typography>
                          {parseInt(data.order_status) === 200
                            ? "NOT STARTED"
                            : parseInt(data.order_status) === 201
                            ? "STARTED"
                            : parseInt(data.order_status) === 400
                            ? "CANCELLED"
                            : "COMPLETED"}
                        </Typography>
                      </Box>
                      <Box sx={{ borderBottom: "2px dashed gray" }}>
                        <Typography sx={{ fontSize: "14px" }}>
                          Contact Address :
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <Typography
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {data.address_line1}
                          </Typography>
                          ,
                          <Typography
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {data.address_line2}
                          </Typography>
                          ,
                          <Typography
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {data.address_line3}
                          </Typography>
                          ,
                          <Typography
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {data.city}
                          </Typography>
                          ,
                          <Typography
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {data.country}
                          </Typography>{" "}
                          -
                          <Typography
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {data.pincode}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: 800, fontSize: "15px" }}
                          >
                            Product
                          </Typography>
                          {/* <Typography sx={{ fontWeight: 800, fontSize: "15px" }}>
                    Quantity
                  </Typography> */}
                          <Typography
                            sx={{
                              fontWeight: 800,
                              fontSize: "15px",
                              mr: "22px",
                            }}
                          >
                            Scan
                          </Typography>
                        </Box>
                        {data?.products?.map((product) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {" "}
                              <Typography sx={{ mt: "9px" }}>
                                {product.capacity + "-" + product.material}
                              </Typography>
                              {/* <Typography sx={{ mr: "20px", mt: "9px" }}>
                        {data.quantity}
                      </Typography> */}
                              <Box>
                                {product.is_inscan_completed === false &&
                                product.is_outscan_completed === false ? (
                                  <CustomButton
                                    startIcon={
                                      <QrCodeScanner
                                        style={{ fontSize: "18px" }}
                                      />
                                    }
                                    label="in-scan"
                                    handleClick={() =>
                                      handleinscanopen(product, data.order_id)
                                    }
                                  />
                                ) : product.is_inscan_completed === true &&
                                  product.is_outscan_completed === false ? (
                                  <CustomButton
                                    startIcon={
                                      <QrCodeScanner
                                        style={{ fontSize: "18px" }}
                                      />
                                    }
                                    label="out-scan"
                                    handleClick={() =>
                                      handleoutscanopen(
                                        product,
                                        data.order_id,
                                        data.is_all_scan_completed
                                      )
                                    }
                                  />
                                ) : (
                                  // <CustomButton
                                  //   label="Scanning Completed"
                                  //   disabled={true}
                                  // />
                                  <Chip label="Scanning Completed" />
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {parseInt(data?.order_status) === 201 &&
                          data?.is_all_scan_completed === true && (
                            <CustomButton
                              label="Complete order"
                              handleClick={() => handleCompleteOrder(data)}
                            />
                          )}
                      </Box>
                    </Box>
                    {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                label="Enter Otp"
                handleClick={() => handleOpenCompleteOrder(data)}
              />
            </Box> */}
                  </Paper>
                );
              })}
            </Box>
          ) : (
            <Typography
              sx={{
                textAlign: "center",
                mt: "250px",
              }}
            >
              No Data Found
            </Typography>
          )}
        </>
      ) : view === "table" ? (
        <Box>
          <CustomTable
            tableHead={[
              "Order Id",
              "Contact Name",
              "Contact Phone",
              "Status",
              "Contact Address",
              "Products",
            ]}
            tableDataKey={[
              "order_id",
              "contact_name",
              "contact_phone",
              {
                type: "component",
                // rowClickEnable: true,
                component: (props) => {
                  return (
                    <Typography>
                      {parseInt(props.row.order_status) === 200
                        ? "Accepted"
                        : parseInt(props.row.order_status) === 201
                        ? "Order Started"
                        : parseInt(props.row.order_status) === 300
                        ? "Cancelled"
                        : parseInt(props.row.order_status) === 500
                        ? "Completed"
                        : ""}
                    </Typography>
                  );
                },
              },
              {
                type: "component",
                // rowClickEnable: true,
                component: (props) => {
                  return (
                    <Typography>
                      {props.row.address_line1},{props.row.address_line2},
                      {props.row.address_line3},{props.row.city},
                      {props.row.country}-{props.row.pincode}
                    </Typography>
                  );
                },
              },
              {
                type: "component",
                component: (row) => {
                  console.log("------row------", row);
                  return (
                    <>
                      {row?.row?.products.map((data) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ mt: "10px" }}>
                              {data.capacity}-{data.material}
                            </Typography>
                            {data.is_inscan_completed === false &&
                            data.is_outscan_completed === false ? (
                              <CustomButton
                                startIcon={
                                  <QrCodeScanner style={{ fontSize: "18px" }} />
                                }
                                label="in-scan"
                                handleClick={() =>
                                  handleinscanopen(data, row?.row?.order_id)
                                }
                              />
                            ) : data.is_inscan_completed === true &&
                              data.is_outscan_completed === false ? (
                              <CustomButton
                                startIcon={
                                  <QrCodeScanner style={{ fontSize: "18px" }} />
                                }
                                label="out-scan"
                                handleClick={() =>
                                  handleoutscanopen(data, row?.row?.order_id)
                                }
                              />
                            ) : (
                              // <CustomButton
                              //   label="Scanning Completed"
                              //   disabled={true}
                              // />
                              <Chip label="Scanning Completed" />
                            )}
                          </Box>
                        );
                      })}
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {parseInt(row?.row?.order_status) === 201 &&
                          row?.row?.is_all_scan_completed === true && (
                            <CustomButton
                              label="Complete order"
                              handleClick={() => handleCompleteOrder(row?.row)}
                            />
                          )}
                      </Box>
                    </>
                  );
                },
              },
              // {
              //   type: "component",
              //   component: () => {
              //     return (
              //       <CustomButton
              //         label="get Qr/Barcode"
              //         // handleClick={hnadleGetQrandBarcode}
              //       />
              //     );
              //   },
              // },
            ]}
            tableData={listOfOrders}
            isCollapsable={false}
            // isCollapseViewVertical={true}

            pagination={false}
            // filter={true}
            filterSelectOption={[
              { value: "Roll Name", label: "role_name" },
              { value: "Full Name", label: "fullname" },
            ]}
            // handleFilterClose={handleFinalProductFilterClose}
            // sort={true}
            sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
            // handleSortClose={handleFinalProductSortClose}
            // addButton={true}

            // setPageNumber={setPageNumber}
            // setPageSize={setPageSize}
          />
        </Box>
      ) : (
        ""
      )}
      <DeliveriesEnterOtpDialog
        openEnterOtpDialog={openEnterOtpDialog}
        enterOtpTextfied={enterOtpTextfied}
        otpValidationError={otpValidationError}
        setOtpValidationError={setOtpValidationError}
        setEnterOtpTextfied={setEnterOtpTextfied}
        handleSubmitOTP={handleSubmitOtp}
        handleClose={handleCloseDialog}
      />
      {openScanningDialog && (
        <ScanningDialog
          setOpenScanningDialog={setOpenScanningDialog}
          openScanningDialog={openScanningDialog}
          scannedQrBarcode={scannedQrBarcode}
          setScannedQrBarcode={setScannedQrBarcode}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          scanQtyMax={scanQtyMax}
          scanOrderId={productData?.order_id}
          scannedCount={scannedCount}
          setScannedCount={setScannedCount}
          setRequestObject={setRequestObject}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          isBatch={true}
          validateExpired={validateExpired}
          setSubmitBtnFlg={setSubmitBtnFlg}
        />
      )}
    </Box>
  );
}

export default DistributorOrdersMain;
